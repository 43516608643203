define("m08-2020/lib/ThreeModule", ["exports", "lodash", "three", "three/examples/jsm/controls/OrbitControls", "m08-2020/lib/CSGMesh", "polybooljs", "@tweenjs/tween.js", "m08-2020/lib/Utils", "m08-2020/lib/Utils/BooleanOperations", "m08-2020/lib/Utils/DimensionalChainIntersectionDetection", "m08-2020/lib/WireframeGenerator", "m08-2020/lib/ClippingUtils/SectionalPlane", "m08-2020/lib/ClippingUtils/DynamicSectionalPlane", "m08-2020/lib/FigureLoaders/DrawingObjectLoader", "m08-2020/lib/FigureLoaders/ArrowLoader", "m08-2020/lib/FigureLoaders/DimensionalChainLoader", "m08-2020/lib/FigureLoaders/FastenerLoader", "m08-2020/lib/FigureLoaders/DrawingPlaneLoader", "m08-2020/lib/DXFGenerator", "m08-2020/lib/ZoomController"], function (_exports, lodash, THREE, _OrbitControls, _CSGMesh, _polybooljs, _tween, _Utils, BooleanOperations, _DimensionalChainIntersectionDetection, _WireframeGenerator, _SectionalPlane, _DynamicSectionalPlane, _DrawingObjectLoader, _ArrowLoader, _DimensionalChainLoader, _FastenerLoader, _DrawingPlaneLoader, _DXFGenerator, _ZoomController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GraphicsThree3D = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class GraphicsThree3D {
    //when clicked wireframe button here value changes
    // Points for camera centering
    // TODO: move intermediate points into specifice scope and leave only mid coords
    constructor() {
      _defineProperty(this, "isWireFrameOnly", false);

      _defineProperty(this, "fontUrlArray", {
        Regular: {
          Arial: "assets/fonts/Arial_Regular.json",
          Calibri: "assets/fonts/Calibri_Regular.json",
          Courier: "assets/fonts/Courier New_Regular.json",
          TimesNewRoman: "assets/fonts/Times New Roman_Regular.json"
        },
        Italic: {
          Arial: "assets/fonts/Arial_Italic.json",
          Calibri: "assets/fonts/Calibri_Italic.json",
          Courier: "assets/fonts/Courier New_Italic.json",
          TimesNewRoman: "assets/fonts/Times New Roman_Italic.json"
        },
        Bold: {
          Arial: "assets/fonts/Arial_Bold.json",
          Calibri: "assets/fonts/Calibri_Bold.json",
          Courier: "assets/fonts/Courier New_Bold.json",
          TimesNewRoman: "assets/fonts/Times New Roman_Bold.json"
        },
        BoldItalic: {
          Arial: "assets/fonts/Arial_Bold Italic.json",
          Calibri: "assets/fonts/Calibri_Bold Italic.json",
          Courier: "assets/fonts/Courier New_Bold Italic.json",
          TimesNewRoman: "assets/fonts/Times New Roman_Bold Italic.json"
        }
      });

      _defineProperty(this, "loadedFonts", {});

      _defineProperty(this, "materialTransparentOptions", null);

      _defineProperty(this, "interactiveZ", false);

      _defineProperty(this, "extrusionVectorInteractiveZ", null);

      _defineProperty(this, "initialPosition", {
        x: -50,
        y: 50,
        z: 100
      });

      _defineProperty(this, "previousCameraPosition", null);

      _defineProperty(this, "cameraTweenActive", false);

      _defineProperty(this, "objectMaxX", -Infinity);

      _defineProperty(this, "objectMaxY", -Infinity);

      _defineProperty(this, "objectMaxZ", -Infinity);

      _defineProperty(this, "objectMinX", Infinity);

      _defineProperty(this, "objectMinY", Infinity);

      _defineProperty(this, "objectMinZ", Infinity);

      _defineProperty(this, "SceneCenterComputed", false);

      this.THREE = THREE;
      this.OrbitControls = _OrbitControls.OrbitControls;
      this.CSG = (0, _CSGMesh.CsgFactory)(THREE);
      this.PolyBool = _polybooljs.default;
      this.TWEEN = _tween.default;
      this.canvasSize = {
        width: 960,
        height: 500
      };
      this.renderer = this.initRenderer(this.canvasSize);
      this.camera = this.initCamera(this.canvasSize);
      this.scene = new this.THREE.Scene();
      this.scene.background = new this.THREE.Color(0xffffff);
      this.stencilScenes = [];
      this.controls = new this.OrbitControls(this.camera, this.renderer.domElement);
      this.globalJSONData = {};
      this.jsonData = {};
      this.WireframeGenerator = new _WireframeGenerator.WireframeGenerator(this);
      this.SectionalPlane = new _SectionalPlane.SectionalPlane(this);
      this.DynamicSectionalPlane = new _DynamicSectionalPlane.DynamicSectionalPlane(this);
      this.ThreeObjects = [];
      this.objectMidVector = new this.THREE.Vector3(); /// Drawing Plane

      this.transparencyControlObjects = {
        area: [],
        line: [],
        point: [],
        text: [],
        volume: []
      };
      this.visualisationControlObjects = {
        arrow: [],
        dimensionalChain: [],
        drawingObject: [],
        fastener: []
      }; ///

      this.DimensionalChainIntersectionDetection = new _DimensionalChainIntersectionDetection.DimensionalChainIntersectionDetection();
      this.dxfGenerator = new _DXFGenerator.DXFGenerator(this);
      this.utils = new _Utils.Utils(THREE);
      this.DrawingObjectLoader = new _DrawingObjectLoader.DrawingObjectLoader(this);
      this.ArrowLoader = new _ArrowLoader.ArrowLoader(THREE, this.fontUrlArray, this);
      this.DimensionalChainLoader = new _DimensionalChainLoader.DimensionalChainLoader(this);
      this.FastenerLoader = new _FastenerLoader.FastenerLoader(THREE, this);
      this.DrawingPlaneLoader = new _DrawingPlaneLoader.DrawingPlaneLoader(this);
      this.ZoomController = new _ZoomController.ZoomController(this);
      this.animate(this); ///
      // this.renderer.domElement.style = "position: absolute; width: 60%; height: 50%;";
      // this.scene.add( new this.THREE.AxesHelper( 10 ) );
      ///
    }

    getGraphicsCanvas() {
      return this.renderer.domElement;
    }

    initRenderer(canvasSize) {
      let {
        width,
        height
      } = canvasSize;
      let renderer = new this.THREE.WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true
      });
      renderer.setSize(width, height);
      renderer.domElement.id = 'threeCanvas';
      renderer.localClippingEnabled = true;
      renderer.autoClear = false;
      return renderer;
    }

    initCamera(canvasSize) {
      const CAMERA_VIEW_SIZE = 5000;
      const CAMERA_DEPTH = 5000;
      const MULTIPLIER = 64;
      let {
        width,
        height
      } = canvasSize;
      let cameraAspectRatio = width / height;
      return new this.THREE.OrthographicCamera(CAMERA_VIEW_SIZE * cameraAspectRatio / -MULTIPLIER, CAMERA_VIEW_SIZE * cameraAspectRatio / MULTIPLIER, CAMERA_VIEW_SIZE / MULTIPLIER, CAMERA_VIEW_SIZE / -MULTIPLIER, -CAMERA_DEPTH, CAMERA_DEPTH);
    }

    makeTransparentClone(mesh) {
      let transparentClone = new this.THREE.Mesh(); // transparentClone.applyMatrix4(mesh.matrix);

      return transparentClone;
    }

    initWireframeObject(mesh, transparentMesh, jsonData, scene) {
      let objectData = {
        id: "none",
        //TODO: Apply ID
        data: jsonData,
        sceneObject: mesh,
        targetScene: scene
      };

      if (transparentMesh) {
        mesh.IsCSGObject = true;
        objectData.IsCSGObject = true;
        objectData.sceneObjectTransparentCSG = transparentMesh;
        objectData.type = "polygon"; // "polygon" is for wireframe to work // TODO: change it

        mesh.customTypeName = "polygon";
        transparentMesh.customTypeName = "polygon";
      }

      this.ThreeObjects.push(objectData);
    }

    makeWireframeFromPoints(points, extrusionVector, lineProps) {
      const RENDER_ORDER = -99;
      let wireframes = [];
      let vectors = points.map(point => new this.THREE.Vector3(point.x, point.y, point.z));
      let wireframe = this.utils.drawConnectedLines(vectors, lineProps, true);
      wireframe.renderOrder = RENDER_ORDER;
      wireframes.push(wireframe);
      let wireframeClone = wireframe.clone();
      wireframeClone.position.add(extrusionVector);
      wireframes.push(wireframeClone);
      wireframes.push(...this.drawWireframeSideLines(vectors, extrusionVector, lineProps));
      return wireframes;
    }

    drawWireframeSideLines(points, extrusionVector, lineProps, renderOrder = -99) {
      let sideLines = [];

      for (let i = 0; i < points.length; i++) {
        let indexThree = i + 2;
        if (indexThree >= points.length) indexThree -= points.length;
        let indexTwo = indexThree - 1;
        if (indexTwo < 0) indexTwo += points.length;
        let indexOne = indexTwo - 1;
        if (indexOne < 0) indexOne += points.length;
        let pointOne = points[indexOne].clone().normalize();
        let pointTwo = points[indexTwo].clone().normalize();
        let pointThree = points[indexThree].clone().normalize();
        let vectorOne = pointTwo.clone().sub(pointOne);
        let vectorTwo = pointThree.clone().sub(pointTwo);
        const DELTA = 0.001;
        let angleBetween = vectorOne.angleTo(vectorTwo);
        if (angleBetween > Math.PI) angleBetween -= Math.PI;

        if (angleBetween > Math.PI / 10 && angleBetween < Math.PI - DELTA) {
          let pointOne = points[indexTwo];
          let pointTwo = points[indexTwo].clone().add(extrusionVector);

          if (pointOne.distanceTo(pointTwo) > 0) {
            let sideLine = this.utils.drawConnectedLines([pointOne, pointTwo], lineProps);
            sideLine.renderOrder = renderOrder;
            sideLines.push(sideLine);
          }
        }
      }

      return sideLines;
    }

    makeWireframes(regions, extrusionVector, lineProps) {
      let wireframes = [];
      regions.forEach(region => {
        let finalPolygons = region.positiveRegions.length > 0 || region.positiveRegions.length > 0 && region.negativeRegions.length > 0 ? BooleanOperations.makePolygonsFromPosAndNegRegions(region) : {
          regions: []
        };
        finalPolygons.regions.forEach(polygonRegion => {
          let vectors = polygonRegion.reduce((vectorArr, coords) => {
            let newPoint = new this.THREE.Vector3(coords[0], coords[1], 0);
            newPoint.applyEuler(region.eulerTwo);
            newPoint.add(region.offsetVector);
            vectorArr.push(newPoint);
            return vectorArr;
          }, []);
          wireframes.push(...this.makeWireframeFromPoints(vectors, extrusionVector, lineProps));
        });
      });
      return wireframes;
    }

    makePositionedExtrusion(geoData) {
      let geometry = this.makeExtrudeGeoRaw(geoData.points2D, geoData.depth);
      geometry.translate(...geoData.translation);
      this.utils.skewExtrudedGeometry(geometry, geoData.skew);
      return geometry;
    }

    makeExtrudeGeoRaw(points2D, depth) {
      let shape = new this.THREE.Shape(points2D);
      let extrudeSettings = {
        depth,
        bevelEnabled: false
      };
      let geometry = new this.THREE.ExtrudeGeometry(shape, extrudeSettings);
      return geometry;
    }

    objectRotatesPlanar(objectData, isEllipse = false) {
      let rotationAxis = this.utils.initVector3(objectData.objectGeneration.rotationAxis).normalize();
      let crossVector;

      if (isEllipse) {
        let directionVectorOne = this.utils.initVector3(objectData.directionVector1);
        let directionVectorTwo = this.utils.initVector3(objectData.directionVector2);
        crossVector = this.utils.getCrossVector(directionVectorOne, directionVectorTwo);
      } else {
        let points = objectData.points;
        crossVector = this.utils.getCrossVectorFromPoints(points);
      }

      return Math.abs(crossVector.dot(rotationAxis)) == 1;
    }

    objectExtrudesPlanar(objectData, isEllipse = false) {
      let extrusionVector = this.utils.initVector3(objectData.objectGeneration.extrusionVector).normalize();
      let crossVector;

      if (isEllipse) {
        let directionVectorOne = this.utils.initVector3(objectData.directionVector1);
        let directionVectorTwo = this.utils.initVector3(objectData.directionVector2);
        crossVector = this.utils.getCrossVector(directionVectorOne, directionVectorTwo);
      } else {
        let points = objectData.points;
        crossVector = this.utils.getCrossVectorFromPoints(points);
      }

      return crossVector.dot(extrusionVector) == 0;
    }

    drawObject(jsonData) {
      let drawingObjects = jsonData.drawingObjects;
      drawingObjects.forEach(drawingObject => {
        let components = drawingObject.components;
        let properties = drawingObject.properties;
        if (components.texts) this.loadTexts(properties.text, components.texts, jsonData, drawingObject.insertionPoints);
        let isComplexVolume = this.utils.isComplexVolume(properties);
        if (isComplexVolume) this.DrawingObjectLoader.drawComplexVolumes(drawingObject, jsonData);else this.DrawingObjectLoader.drawRegularObjects(drawingObject, jsonData);

        if (components.sectionalPlanes) {
          this.centerScene();
          this.SceneCenterComputed = true;
        }

        if (drawingObject.dynamicSectionalPlanes) {
          this.centerScene();
          this.SceneCenterComputed = true;
        }
      });
    }

    addObjToTranspControl(object, type) {
      switch (type) {
        case "area":
          this.transparencyControlObjects.area.push(object);
          break;

        case "line":
          this.transparencyControlObjects.line.push(object);
          break;

        case "point":
          this.transparencyControlObjects.point.push(object);
          break;

        case "text":
          this.transparencyControlObjects.text.push(object);
          break;

        case "volume":
          this.transparencyControlObjects.volume.push(object);
          break;

        default:
          break;
      }
    }

    addObjectToVisControl(object, type) {
      switch (type) {
        case "arrow":
          this.visualisationControlObjects.arrow.push(object);
          break;

        case "dimensionalChain":
          this.visualisationControlObjects.dimensionalChain.push(object);
          break;

        case "drawingObject":
          this.visualisationControlObjects.drawingObject.push(object);
          break;

        case "fastener":
          this.visualisationControlObjects.fastener.push(object);
          break;

        default:
          break;
      }
    }

    addObjectToScene(object, objectTransparent, insertionPoints, jsonData, transpType, visType, objectData = null) {
      if (insertionPoints) insertionPoints.forEach(insertionPoint => {
        let insertionVector = this.utils.initVector3(insertionPoint.insertionVector);
        let newMaterial = object.material.length ? object.material.map(mat => mat.clone()) : object.material.clone();
        let newObject = object.clone();
        newObject.material = newMaterial;
        newObject.onBeforeRender = object.onBeforeRender;
        newObject.stencilNeeded = object.stencilNeeded ? object.stencilNeeded : false;
        newObject.addToDXF = object.addToDXF ? object.addToDXF : false;
        newObject.dxfLayer = object.dxfLayer ? object.dxfLayer : "No Layer";
        let newObjectTransparent = objectTransparent ? objectTransparent.clone() : null;
        copyObjectWireframes(newObject, object);
        if (newObjectTransparent) copyObjectWireframes(newObjectTransparent, objectTransparent);
        let rotationAngle = insertionPoint.rotationAngle;
        let pivotPoint = new this.THREE.Object3D();

        if (rotationAngle) {
          pivotPoint.position.set(insertionPoint.rotationPoint.x, insertionPoint.rotationPoint.y, insertionPoint.rotationPoint.z);
          pivotPoint.attach(newObject);
          if (newObjectTransparent) pivotPoint.attach(newObjectTransparent);
          rotationAngle = rotationAngle * Math.PI / 180;
          let rotationAxis = this.utils.initVector3(insertionPoint.rotationAxis);
          pivotPoint.rotateOnAxis(rotationAxis, rotationAngle);
        } else {
          pivotPoint.attach(newObject);
          if (newObjectTransparent) pivotPoint.attach(newObjectTransparent);
        }

        pivotPoint.position.add(insertionVector);
        pivotPoint.updateMatrixWorld();
        let regularObject = pivotPoint.children[0];
        let transparentObject = newObjectTransparent ? pivotPoint.children[1] : null;
        pivotPoint.children.forEach(child => {
          child.applyMatrix4(pivotPoint.matrixWorld);
        });
        regularObject.isDrawingObject = true; // Used for drawingPlanes

        this.initWireframeObject(regularObject, transparentObject, jsonData, this.scene);
        this.scene.add(regularObject); ///

        this.ZoomController.getDrawingObjectWireframes(regularObject);
        if (transparentObject) this.ZoomController.getDrawingObjectWireframes(transparentObject); ///

        if (objectData && objectData.components.sectionalPlanes) this.SectionalPlane.addToSectionalPlanes(insertionPoint, regularObject, objectData);

        if (objectData && objectData.dynamicSectionalPlanes) {
          objectData.dynamicSectionalPlanes.forEach(planeID => {
            this.DynamicSectionalPlane.sectionalPlanes.forEach(sectionalPlane => {
              if (planeID === sectionalPlane.id) {
                sectionalPlane.targets.drawingObjects.push({
                  mesh: regularObject,
                  clippingPlanes: objectData.dynamicSectionalPlanes
                });
              }
            });
          });
        }

        if (object.dxfPoints) object.dxfPoints.forEach(ptsData => {
          ptsData.pointPairs.forEach(pair => pair.map(pt => pt.applyMatrix4(regularObject.matrix)));
          this.dxfGenerator.wireframePoints.push(ptsData);
        });
        this.addObjToTranspControl(regularObject, transpType);
        this.addObjectToVisControl(regularObject, visType);
      });

      function copyObjectWireframes(ObjectNew, objectOld) {
        ObjectNew.children = objectOld.children.map(child => {
          let childClone = child.clone();
          if (child.needsZoomControl) childClone.needsZoomControl = true;
          if (child.isLine) childClone.isLine = true;
          if (child.pointsStartEnd) childClone.pointsStartEnd = child.pointsStartEnd;
          return childClone;
        });
      }
    }

    getThreeSideFaceObjects(object) {
      object.updateMatrixWorld();
      let [doubleMaterial, frontMaterial, backMaterial] = this.getThreeSideFaceMaterials(object);
      let geometry = object.geometry.clone();
      let frontSideObject = new this.THREE.Mesh(geometry);
      frontSideObject.applyMatrix4(object.matrix);
      let backSideObject = new this.THREE.Mesh(geometry);
      backSideObject.applyMatrix4(object.matrix);
      let doubleSideObject = new this.THREE.Mesh(geometry);
      doubleSideObject.applyMatrix4(object.matrix);
      doubleSideObject = this.getPositionedObject(doubleSideObject, doubleMaterial);
      frontSideObject = this.getPositionedObject(frontSideObject, frontMaterial);
      backSideObject = this.getPositionedObject(backSideObject, backMaterial);
      return [doubleSideObject, frontSideObject, backSideObject];
    }

    getPositionedObject(object, material) {
      let bsp = this.CSG.fromMesh(object);
      let result = this.CSG.toMesh(bsp, new this.THREE.Matrix4());
      result.material = material;
      return result;
    }

    getThreeSideFaceMaterials(object) {
      let objectMaterial = object.material.length > 0 ? object.material.map(mat => {
        let newMat = mat.clone();
        return newMat;
      }) : object.material.clone();
      let frontMaterial;

      if (object.material.length > 0) {
        frontMaterial = objectMaterial.map(mat => {
          let newMat = mat.clone();
          newMat.side = this.THREE.FrontSide;
          return newMat;
        });
      } else {
        frontMaterial = objectMaterial.clone();
        frontMaterial.side = this.THREE.FrontSide;
      }

      let backMaterial;

      if (object.material.length > 0) {
        backMaterial = objectMaterial.map(mat => {
          let newMat = mat.clone();
          newMat.side = this.THREE.BackSide;
          return newMat;
        });
      } else {
        backMaterial = objectMaterial.clone();
        backMaterial.side = this.THREE.BackSide;
      }

      let doubleMaterial;

      if (object.material.length > 0) {
        doubleMaterial = objectMaterial.map(mat => {
          let newMat = mat.clone();
          newMat.side = this.THREE.DoubleSide;
          return newMat;
        });
      } else {
        doubleMaterial = objectMaterial.clone();
        doubleMaterial.side = this.THREE.DoubleSide;
      }

      return [doubleMaterial, frontMaterial, backMaterial];
    }

    getTransformedObjects(objects, insertionPoint) {
      let rotationAngle = insertionPoint.rotationAngle;
      let insertionVector = this.utils.initVector3(insertionPoint.insertionVector);
      let pivotPoint = new this.THREE.Object3D();
      let newObjects = [];
      objects.forEach(object => {
        let newObject = object.clone();
        newObject.onBeforeRender = object.onBeforeRender;
        newObjects.push(newObject);
      });

      if (rotationAngle) {
        pivotPoint.position.set(insertionPoint.rotationPoint.x, insertionPoint.rotationPoint.y, insertionPoint.rotationPoint.z);
        newObjects.forEach(obj => pivotPoint.attach(obj));
        rotationAngle = rotationAngle * Math.PI / 180;
        let rotationAxis = this.utils.initVector3(insertionPoint.rotationAxis);
        pivotPoint.rotateOnAxis(rotationAxis, rotationAngle);
      } else newObjects.forEach(obj => pivotPoint.attach(obj));

      pivotPoint.position.add(insertionVector);
      pivotPoint.updateMatrixWorld();
      newObjects.forEach(obj => obj.applyMatrix4(pivotPoint.matrixWorld));
      return newObjects;
    }

    getlineMeshMaterial(properties) {
      return properties && properties.line ? this.getMaterialFromColor(properties.line.color) : null;
    }

    getPointMaterial(properties) {
      return properties && properties.point ? this.getMaterialFromColor(properties.point.color) : null;
    }

    getAreaMaterial(mesh, properties) {
      let objectMaterial;

      if (properties && properties.area) {
        let colorProps = properties.area.color; ///

        if (properties.area.hatching.type == 2) {
          let orientationVector = this.utils.normalizeVector(properties.area.hatching.orientationVector);
          let hatchSpacing = properties.area.hatching.factor;
          let hatchColor = properties.area.hatching.color;
          let hatchThickness = properties.area.hatching.thickness;
          objectMaterial = this.makeCanvasMaterial(colorProps, hatchColor, orientationVector, hatchSpacing, hatchThickness); // TODO: might need to optimize this

          let localClone = new this.THREE.Mesh(mesh.geometry.clone(), mesh.material.clone());
          this.scene.add(localClone);
          this.centerScene();
          let objectWidth = this.objectMaxX - this.objectMinX;
          let objectHeight = this.objectMaxY - this.objectMinY;
          this.scene.remove(localClone); ///
          // let maxDistance = Math.max(objectWidth, objectHeight);

          objectMaterial.map.wrapS = objectMaterial.map.wrapT = this.THREE.RepeatWrapping;
          objectMaterial.map.repeat.set(1 / objectWidth, 1 / objectHeight);
          objectMaterial.map.offset.set(0.5, 0.5);
        } else if (properties.area.hatching.type == 1) {
          objectMaterial = this.getMaterialFromColor(colorProps);
        } else {
          objectMaterial = null;
        } ///

      } else {
        objectMaterial = null;
      }

      return objectMaterial;
    }

    getVolumeMaterialsIndexFaces(mesh, properties) {
      let objectMaterial;

      if (properties && properties.volume && mesh.geometry.faces) {
        let faceSideColor = properties.volume.color.faceSide;
        let edgeSideColor = properties.volume.color.edgeSide;
        let endSideColor = properties.volume.color.endSide;
        let cutSideColor = properties.volume.color.cutSide;
        let faceSideMaterial = this.getMaterialFromColor(faceSideColor);
        let edgeSideMaterial = this.getMaterialFromColor(edgeSideColor);
        let endSideMaterial = this.getMaterialFromColor(endSideColor);
        let cutSideMaterial = this.getMaterialFromColor(cutSideColor);
        objectMaterial = [faceSideMaterial, edgeSideMaterial, endSideMaterial, cutSideMaterial];
        this.indexMeshFaces(mesh, properties);
      } else {
        objectMaterial = null;
      }

      return objectMaterial;
    }

    indexMeshFaces(mesh, properties) {
      let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
      let grainDirection = this.utils.initVector3(properties.volume.layers[0].grainDirectionVector).normalize();
      mesh.geometry.faces.forEach(face => {
        if (Math.abs(face.normal.dot(faceSideNormal)) > 0.9999) {
          face.materialIndex = 0;
        } else if (Math.abs(face.normal.dot(grainDirection)) < 0.0001) {
          face.materialIndex = 1;
        } else if (Math.abs(face.normal.dot(grainDirection)) > 0.9999) {
          face.materialIndex = 2;
        } else {
          face.materialIndex = 3;
        }
      });
    }

    centerScene() {
      let box = new this.THREE.BoxHelper(this.scene, 0xffff00);
      let boxVertices = this.utils.getVerticesFromBufferGeometry(box.geometry);
      let bbox = new this.THREE.Box3().setFromPoints(boxVertices);
      this.objectMaxX = bbox.max.x;
      this.objectMaxY = bbox.max.y;
      this.objectMaxZ = bbox.max.z;
      this.objectMinX = bbox.min.x;
      this.objectMinY = bbox.min.y;
      this.objectMinZ = bbox.min.z;
      let objectWidth = this.objectMaxX - this.objectMinX;
      let objectHeight = this.objectMaxY - this.objectMinY;
      let objectDepth = this.objectMaxZ - this.objectMinZ;
      let objectMidX = this.objectMinX + objectWidth / 2;
      let objectMidY = this.objectMinY + objectHeight / 2;
      let objectMidZ = this.objectMinZ + objectDepth / 2;
      this.objectMidVector.set(objectMidX, objectMidY, objectMidZ);
      this.controls.target.copy(this.objectMidVector);
      this.controls.update();
      this.camera.position.set(this.initialPosition.x, this.initialPosition.y, this.initialPosition.z).add(this.objectMidVector);
      this.camera.lookAt(this.objectMidVector);
      this.camera.updateProjectionMatrix();
    }

    clearScene() {
      this.clearGraphicsState();
      this.DrawingObjectLoader.WireframeGenerator.clearWireframeState();
      this.DynamicSectionalPlane.clearSectionalPlanes();
      this.ZoomController.drawingObjectWireframes();

      while (this.scene.children.length > 0) {
        this.scene.remove(this.scene.children[this.scene.children.length - 1]);
      }
    }

    clearGraphicsState() {
      this.previousCameraPosition = null;
      this.cameraTweenActive = false;
      this.ThreeObjects = [];
      this.objectMaxX = -Infinity;
      this.objectMaxY = -Infinity;
      this.objectMaxZ = -Infinity;
      this.objectMinX = Infinity;
      this.objectMinY = Infinity;
      this.objectMinZ = Infinity;
      this.SceneCenterComputed = false;
      this.stencilScenes = [];
      this.transparencyControlObjects = {
        area: [],
        line: [],
        point: [],
        text: [],
        volume: []
      };
      this.visualisationControlObjects = {
        arrow: [],
        dimensionalChain: [],
        drawingObject: [],
        fastener: []
      };
      this.DimensionalChainIntersectionDetection.meshArr = [];
    }

    getRotationParameters(pts, extrusionNormalized) {
      let midVector = this.getMidVector(pts);
      let crossVector = this.getCrossVector(pts, extrusionNormalized);
      let [eulerOne, eulerTwo] = this.utils.getEulerOneAndTwo(crossVector);
      return [midVector, eulerOne, eulerTwo, crossVector];
    }

    getMidVector(pts) {
      let maxX = -Infinity;
      let maxY = -Infinity;
      let maxZ = -Infinity;
      let minX = Infinity;
      let minY = Infinity;
      let minZ = Infinity;
      pts.forEach(point => {
        if (point.x > maxX) maxX = point.x;
        if (point.y > maxY) maxY = point.y;
        if (point.z > maxZ) maxZ = point.z;
        if (point.x < minX) minX = point.x;
        if (point.y < minY) minY = point.y;
        if (point.z < minZ) minZ = point.z;
      });
      let midX = minX + (maxX - minX) / 2;
      let midY = minY + (maxY - minY) / 2;
      let midZ = minZ + (maxZ - minZ) / 2;
      let midVector = new this.THREE.Vector3(midX, midY, midZ);
      return midVector;
    }

    getCrossVector(points, extrusionNormalized) {
      let crossVector = this.utils.getCrossVectorFromPoints(points);
      if (extrusionNormalized.dot(crossVector) < 0) crossVector.negate();
      return crossVector;
    }

    getMaterialFromColor(colorProps) {
      let {
        color,
        opacity
      } = this.utils.getColorAndOpacity(colorProps);
      let materialOptions = {
        transparent: true,
        color,
        opacity,
        side: this.THREE.DoubleSide
      };
      let material = new this.THREE.MeshBasicMaterial(materialOptions);
      return material;
    }

    getVolumeMaterials(volumeColors) {
      let faceSideColor = volumeColors.faceSide;
      let edgeSideColor = volumeColors.edgeSide;
      let endSideColor = volumeColors.endSide;
      let cutSideColor = volumeColors.cutSide;
      let faceSideMaterial = this.getMaterialFromColor(faceSideColor);
      let edgeSideMaterial = this.getMaterialFromColor(edgeSideColor);
      let endSideMaterial = this.getMaterialFromColor(endSideColor);
      let cutSideMaterial = this.getMaterialFromColor(cutSideColor);
      let materials = [faceSideMaterial, edgeSideMaterial, endSideMaterial, cutSideMaterial];
      return materials;
    }

    indexVolumeFaces(geometry, faceSideNormal, grainDirection) {
      geometry.faces.forEach(face => {
        if (Math.abs(face.normal.dot(faceSideNormal)) == 1) {
          face.materialIndex = 0;
        } else if (face.normal.dot(grainDirection) == 0) {
          face.materialIndex = 1;
        } else if (Math.abs(face.normal.dot(grainDirection)) == 1) {
          face.materialIndex = 2;
        } else {
          face.materialIndex = 3;
        }
      });
    }

    makeCircleWithEdges(radius, areaMaterial, borderMaterial, customParameters = null) {
      let circleGeo;

      if (customParameters) {
        let thetaStart = customParameters.thetaStart;
        let thetaEnd = customParameters.thetaEnd;
        circleGeo = new this.THREE.CircleBufferGeometry(radius, 32, thetaStart, thetaEnd);
        let radiusTwo = customParameters.radiusTwo;
        circleGeo.applyMatrix4(new this.THREE.Matrix4().makeScale(1, radiusTwo / radius, 1));
      } else {
        circleGeo = new this.THREE.CircleBufferGeometry(radius, 32);
      }

      let areaMesh = new this.THREE.Mesh(circleGeo, areaMaterial);
      let borderEdges = new this.THREE.EdgesGeometry(circleGeo);
      let borderLine = new this.THREE.LineSegments(borderEdges, borderMaterial);
      let circle = new this.THREE.Object3D();
      circle.add(areaMesh);
      circle.add(borderLine);
      return circle;
    }

    makeArrow(radius, height, areaMaterial, borderMaterial) {
      let coneGeo = new this.THREE.ConeBufferGeometry(radius, height, 32);
      coneGeo.translate(0, -height / 2, 0);
      let coneMesh = new this.THREE.Mesh(coneGeo, areaMaterial);
      coneMesh.position.y = -height * 0.025;
      let borderMesh = new this.THREE.Mesh(coneGeo, borderMaterial);
      borderMesh.scale.multiplyScalar(1.05);
      borderMesh.material.side = this.THREE.BackSide;
      let cone = new this.THREE.Object3D();
      cone.add(coneMesh);
      cone.add(borderMesh);
      return cone;
    }

    getLineMaterial(lineProps) {
      let lineType = lineProps.type;
      let lineColor = lineProps.color;
      let lineMaterial;

      if (lineType) {
        if (lineType == 1) {
          let materialOptions = {
            color: "rgb(" + lineColor.red + ", " + lineColor.green + ", " + lineColor.blue + ")",
            transparent: true,
            opacity: lineColor.alpha / 255
          };
          lineMaterial = new this.THREE.LineBasicMaterial(materialOptions);
        } else {
          lineMaterial = new this.THREE.LineDashedMaterial({
            color: "rgb(" + lineColor.red + ", " + lineColor.green + ", " + lineColor.blue + ")",
            transparent: true,
            opacity: lineColor.alpha / 255,
            dashSize: lineType == 2 ? lineProps.factor : 0.2,
            // TODO: might need to change this
            gapSize: lineProps.factor ? lineProps.factor : 0
          });
        }
      }

      if (lineMaterial) lineMaterial.depthTest = false;else lineMaterial = new this.THREE.LineBasicMaterial({
        transparent: true,
        opacity: 0
      });
      return lineMaterial;
    }

    drawLine(pointOne, pointTwo, lineProps, forObjectGeneration = false) {
      ///
      // temporary solution. May be Fixed.
      if (lineProps.type == 0 || lineProps.thickness == 0 || lineProps.color.alpha == 0) return new this.THREE.Mesh(); ///

      let lineMaterial = this.getMaterialFromColor(lineProps.color);
      let lineRadius = lineProps.thickness / 2;
      let lineSize = pointOne.distanceTo(pointTwo);
      let lineGeo = new this.THREE.CylinderGeometry(lineRadius, lineRadius, lineSize, 8);
      lineGeo.rotateX(Math.PI / 2);
      lineGeo.translate(0, 0, lineSize / 2); // if (!forObjectGeneration) lineGeo.merge(new this.THREE.SphereGeometry(lineRadius, 8, 8));

      let lineMesh = new this.THREE.Mesh(lineGeo, lineMaterial);
      lineMesh.position.copy(pointOne);
      lineMesh.lookAt(pointTwo);
      if (!forObjectGeneration) lineMesh.renderOrder = -101;
      return lineMesh;
    }

    getEllipsePlanarPoints(ellipseData) {
      const CIRCLE_DETAIL = 64;
      let radiusOne = ellipseData.radius1;
      let radiusTwo = ellipseData.radius2;
      let segmentHeight = ellipseData.segmentHeight;
      let directionVectorOne = this.utils.initVector3(ellipseData.directionVector1).normalize();
      let directionVectorTwo = this.utils.initVector3(ellipseData.directionVector2).normalize();
      let circleVertices = [];

      if (!directionVectorOne.dot(directionVectorTwo)) {
        let crossVector = new this.THREE.Vector3().crossVectors(directionVectorOne, directionVectorTwo).normalize();
        let startAngle = 0;
        let endAngle = 2 * Math.PI;

        if (ellipseData.segmentVector1 && ellipseData.segmentVector2) {
          let segmentVectorOne = this.utils.initVector3(ellipseData.segmentVector1).normalize();
          let segmentVectorTwo = this.utils.initVector3(ellipseData.segmentVector2).normalize(); ///

          let segmentCross = new this.THREE.Vector3().crossVectors(segmentVectorOne, segmentVectorTwo);
          if (segmentCross.length() === 0) segmentCross = new this.THREE.Vector3().crossVectors(directionVectorOne, directionVectorTwo).normalize();
          let segOneToSegTwo = Math.acos(this.utils.clampNum(segmentVectorOne.dot(segmentVectorTwo), -1, 1));

          if (crossVector.dot(segmentCross) < 0) {
            segOneToSegTwo = -segOneToSegTwo;
          }

          let dirOneToSegOne = Math.acos(directionVectorOne.dot(segmentVectorOne));
          let dirSegCross = new this.THREE.Vector3().crossVectors(directionVectorOne, segmentVectorOne);

          if (crossVector.dot(dirSegCross) < 0) {
            dirOneToSegOne = 2 * Math.PI - dirOneToSegOne;
          }

          startAngle = dirOneToSegOne;
          endAngle = dirOneToSegOne + segOneToSegTwo;
        }

        let transformMatrix = new this.THREE.Matrix4().set(directionVectorOne.x, directionVectorTwo.x, crossVector.x, 0, directionVectorOne.y, directionVectorTwo.y, crossVector.y, 0, directionVectorOne.z, directionVectorTwo.z, crossVector.z, 0, 0, 0, 0, 1);
        let angleDelta = 2 * Math.PI / CIRCLE_DETAIL;
        let epsilon = 0.01;
        let partOne = [];
        let partTwo = [];

        for (let i = 0; i < 2 * Math.PI; i += angleDelta) {
          let localStartAngle = startAngle;
          let localEndAngle = endAngle;

          if (localStartAngle < localEndAngle) {
            if (localEndAngle > 2 * Math.PI) {
              localEndAngle -= 2 * Math.PI; // if ((i >= -epsilon && i <= localEndAngle + epsilon) || (i >= localStartAngle - epsilon && i <= 2 * Math.PI + epsilon)) {
              //   let newVector = new this.THREE.Vector3(radiusOne * Math.cos(i), radiusTwo * Math.sin(i), 0);
              //   newVector.applyMatrix4(transformMatrix);
              //   partOne.push(newVector);
              // }

              if (i >= -epsilon && i <= localEndAngle + epsilon) {
                let newVector = new this.THREE.Vector3(radiusOne * Math.cos(i), radiusTwo * Math.sin(i), 0);
                newVector.applyMatrix4(transformMatrix);
                partTwo.push(newVector);
              }

              if (i >= localStartAngle - epsilon && i <= 2 * Math.PI + epsilon) {
                let newVector = new this.THREE.Vector3(radiusOne * Math.cos(i), radiusTwo * Math.sin(i), 0);
                newVector.applyMatrix4(transformMatrix);
                partOne.push(newVector);
              }
            } else {
              if (i >= localStartAngle - epsilon && i <= localEndAngle + epsilon) {
                let newVector = new this.THREE.Vector3(radiusOne * Math.cos(i), radiusTwo * Math.sin(i), 0);
                newVector.applyMatrix4(transformMatrix);
                partOne.push(newVector);
              }
            }
          } else {
            if (localEndAngle < 0) {
              localEndAngle = 2 * Math.PI + localEndAngle;

              if (i >= localStartAngle - epsilon && i <= localEndAngle + epsilon) {
                let newVector = new this.THREE.Vector3(radiusOne * Math.cos(i), radiusTwo * Math.sin(i), 0);
                newVector.applyMatrix4(transformMatrix);
                partOne.push(newVector);
              }
            } else {
              if (i >= -epsilon && i <= localEndAngle + epsilon) {
                let newVector = new this.THREE.Vector3(radiusOne * Math.cos(i), radiusTwo * Math.sin(i), 0);
                newVector.applyMatrix4(transformMatrix);
                partTwo.push(newVector);
              }

              if (i >= localStartAngle - epsilon && i <= 2 * Math.PI + epsilon) {
                let newVector = new this.THREE.Vector3(radiusOne * Math.cos(i), radiusTwo * Math.sin(i), 0);
                newVector.applyMatrix4(transformMatrix);
                partOne.push(newVector);
              }
            }
          }
        }

        circleVertices = partOne;
        circleVertices.push(...partTwo);
        let zeroVector = new this.THREE.Vector3(0, 0, 0);

        if (ellipseData.segmentVector1 && ellipseData.segmentVector2) {
          if (segmentHeight) {
            let vectorOne = circleVertices[0].clone();
            let vectorTwo = circleVertices[circleVertices.length - 1].clone();
            let oneRadius = zeroVector.distanceTo(vectorOne);
            let twoRadius = zeroVector.distanceTo(vectorTwo);
            let midVector = circleVertices[Math.floor(circleVertices.length / 2)].clone();
            let midRadius = zeroVector.distanceTo(midVector);
            let planeNormal = midVector.clone().normalize();
            let plane = new this.THREE.Plane(planeNormal); ///

            let needToPushZero = false;
            let distanceOne = plane.distanceToPoint(vectorOne);
            let distanceTwo = plane.distanceToPoint(vectorTwo);

            if (distanceOne < 0 && distanceTwo < 0) {
              if (segmentHeight > midRadius) {
                let heightDelta = segmentHeight - midRadius;
                circleVertices.forEach(vertex => {
                  if (-plane.distanceToPoint(vertex) > heightDelta) {
                    vertex.markForDelete = true;
                  }
                });
                circleVertices = circleVertices.filter(vertex => !vertex.markForDelete); ///

                segmentHeight -= heightDelta;
                let lengthOne = vectorOne.length();
                let lengthTwo = vectorTwo.length();
                let minRadius = Math.min(oneRadius, twoRadius);
                oneRadius = minRadius;
                twoRadius = minRadius;
                vectorOne = vectorOne.normalize().multiplyScalar(lengthTwo);
                vectorTwo = vectorTwo.normalize().multiplyScalar(lengthOne);
                needToPushZero = true;
              } else {
                let heightDelta = segmentHeight - midRadius;
                let vecOne = vectorTwo.clone().normalize().multiplyScalar(oneRadius);
                let vecTwo = vectorOne.clone().normalize().multiplyScalar(twoRadius);
                let localNormal = new this.THREE.Vector3().lerpVectors(vecOne, vecTwo, 0.5).normalize();
                let localPlane = new this.THREE.Plane(localNormal);
                circleVertices.forEach(vertex => {
                  if (localPlane.distanceToPoint(vertex) > heightDelta) {
                    vertex.markForDelete = true;
                  }
                });
                circleVertices = circleVertices.filter(vertex => !vertex.markForDelete);
                return circleVertices;
              }
            } ///


            let differenceOne = Math.abs(plane.distanceToPoint(midVector)) - Math.abs(plane.distanceToPoint(vectorOne));
            let differenceTwo = Math.abs(plane.distanceToPoint(midVector)) - Math.abs(plane.distanceToPoint(vectorTwo));
            let segmentOne = Math.abs(segmentHeight - differenceOne);
            let segmentTwo = Math.abs(segmentHeight - differenceTwo);
            let proportionOne = 1 - segmentOne / midRadius;
            let proportionTwo = 1 - segmentTwo / midRadius;
            let proportion = Math.max(proportionOne, proportionTwo);
            let firstPoint = vectorOne.clone().normalize().multiplyScalar(oneRadius * proportion);
            let secondPoint = vectorTwo.clone().normalize().multiplyScalar(twoRadius * proportion);
            circleVertices.unshift(firstPoint);
            circleVertices.push(secondPoint); ///

            if (needToPushZero) circleVertices.push(zeroVector);
          } else {
            circleVertices.push(zeroVector);
          }
        }
      }

      return circleVertices;
    }

    getRotationIndices(points, rotationVector, forEllipse = false) {
      let addAngle = forEllipse ? 0 : Math.PI / 2;
      let xAxis = new this.THREE.Vector3(1, 0, 0);
      let pointsNum = points.length;
      let startIndex = (addAngle + rotationVector.clone().normalize().angleTo(xAxis)) / (2 * Math.PI) * pointsNum;
      let endIndex = (addAngle + Math.PI + rotationVector.clone().normalize().angleTo(xAxis)) / (2 * Math.PI) * pointsNum;

      if (endIndex >= pointsNum) {
        endIndex -= pointsNum;
      }

      startIndex = Math.floor(startIndex);
      endIndex = Math.floor(endIndex);
      return [startIndex, endIndex];
    }

    interpolateWithDistance(pointOne, pointTwo, distance) {
      let difference = pointOne.distanceTo(pointTwo);
      let points = [];

      if (difference > distance) {
        let proportion = difference / distance;

        for (let i = 1; i < proportion; i++) {
          let newPoint = new this.THREE.Vector3().lerpVectors(pointOne, pointTwo, i / proportion);
          points.push(newPoint);
        }
      }

      return points;
    }

    makeRotatedObjectWireframe(curvePoints, lineProps, rotationData, rotationObjectWireframes, forPolygon = false) {
      let centerPoint = rotationData.positionPoint;
      let rotationPitch = rotationData.rotationPitch;
      let rotationAngle = rotationData.rotationAngle;
      let rotationAxis = rotationData.rotationAxis; ///

      let rotationPoints = curvePoints.map(point => point.clone()); ///

      let renderOrders = [-99, -101];

      for (let renderOrder of renderOrders) {
        let startEdge = this.utils.drawConnectedLines(curvePoints, lineProps, true);
        startEdge.renderOrder = renderOrder;
        let endGeo = startEdge.geometry.clone();
        rotationObjectWireframes.push(startEdge);
        let endEdge = new this.THREE.Mesh(endGeo, startEdge.material);
        endEdge.renderOrder = renderOrder;
        rotationObjectWireframes.push(endEdge);
        let yAxis = new this.THREE.Vector3(0, 1, 0);

        if (!forPolygon) {
          let edgeDetail = 64;
          let numOfSegments = edgeDetail * Math.abs(rotationAngle / Math.PI / 2);
          startEdge.rotateOnAxis(yAxis, Math.PI);
          endEdge.rotateOnAxis(yAxis, Math.PI);
          startEdge.geometry.translate(-centerPoint, 0, 0);
          endEdge.rotateOnAxis(yAxis, -rotationAngle);
          endEdge.geometry.translate(-centerPoint, -rotationPitch, 0);
          let rotationPoints = curvePoints.map(point => point.clone().applyAxisAngle(yAxis, Math.PI));

          for (let i = 0; i < rotationPoints.length; i++) {
            let indexThree = i + 2;
            if (indexThree >= rotationPoints.length) indexThree -= rotationPoints.length;
            let indexTwo = indexThree - 1;
            if (indexTwo < 0) indexTwo += rotationPoints.length;
            let indexOne = indexTwo - 1;
            if (indexOne < 0) indexOne += rotationPoints.length;
            let pointOne = rotationPoints[indexOne].clone().normalize();
            let pointTwo = rotationPoints[indexTwo].clone().normalize();
            let pointThree = rotationPoints[indexThree].clone().normalize();
            let vectorOne = pointTwo.clone().sub(pointOne);
            let vectorTwo = pointThree.clone().sub(pointTwo);
            let angleBetween = vectorOne.angleTo(vectorTwo);
            if (angleBetween > Math.PI) angleBetween -= Math.PI;

            if (angleBetween > Math.PI / 10) {
              let localPoint = rotationPoints[indexTwo].clone();
              let edgePoints = [];
              localPoint.add(new this.THREE.Vector3(centerPoint, rotationPitch, 0));

              for (let i = 0; i < numOfSegments + 1; i++) {
                let newPoint = localPoint.clone().applyAxisAngle(yAxis, -i * rotationAngle / numOfSegments);
                newPoint.sub(new this.THREE.Vector3(0, i * rotationPitch / numOfSegments, 0));
                edgePoints.push(newPoint);
              }

              let edgeLine = this.utils.drawConnectedLines(edgePoints, lineProps);
              edgeLine.renderOrder = renderOrder;
              rotationObjectWireframes.push(edgeLine);
            }
          }
        } else {
          let edgeDetail = 32;
          let numOfSegments = edgeDetail * Math.abs(rotationAngle / Math.PI / 2);
          startEdge.geometry.translate(centerPoint, rotationPitch, 0);
          endEdge.geometry.translate(centerPoint, 0, 0);
          endEdge.rotateOnAxis(yAxis, -rotationAngle);
          rotationPoints.forEach(point => {
            let localPoint = point.clone();
            let edgePoints = [];
            localPoint.add(new this.THREE.Vector3(centerPoint, rotationPitch, 0));

            for (let i = 0; i < numOfSegments + 1; i++) {
              let newPoint = localPoint.clone().applyAxisAngle(yAxis, -i * rotationAngle / numOfSegments);
              newPoint.sub(new this.THREE.Vector3(0, i * rotationPitch / numOfSegments, 0));
              edgePoints.push(newPoint);
            }

            let edgeLine = this.utils.drawConnectedLines(edgePoints, lineProps);
            edgeLine.renderOrder = renderOrder;
            rotationObjectWireframes.push(edgeLine);
          });
        }
      }
    }

    makeCanvasMaterial(areaColor, hatchColor, orientationVector, hatchSpacing, hatchThickness) {
      const POSITION_PIXEL_RATIO = 10;
      let canvasMaterial;

      if (hatchSpacing && hatchThickness) {
        let tangent = orientationVector.x / orientationVector.y;
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        ctx.fillStyle = "rgb(" + areaColor.red + ", " + areaColor.green + ", " + areaColor.blue + ")";
        ctx.strokeStyle = "rgb(" + hatchColor.red + ", " + hatchColor.green + ", " + hatchColor.blue + ")";
        ctx.lineWidth = hatchThickness * POSITION_PIXEL_RATIO;
        /**
         * canvas defaults are
         * width:  300px,
         * height: 150px
         */

        let textureWidth = 300;
        let textureHeight = 150;
        ctx.fillRect(0, 0, textureWidth, textureHeight);
        hatchSpacing += 2 * hatchThickness;
        let correctedHatchSpacing = hatchSpacing * POSITION_PIXEL_RATIO;

        for (let i = 0; i < (textureWidth + textureHeight) / correctedHatchSpacing; i++) {
          // TODO: Might need to change logic
          if (orientationVector.y == 0) {
            ctx.beginPath();
            ctx.moveTo(0, i * correctedHatchSpacing);
            ctx.lineTo(300, i * correctedHatchSpacing);
            ctx.closePath();
            ctx.stroke();
          } else if (orientationVector.x == 0) {
            ctx.beginPath();
            ctx.moveTo(i * correctedHatchSpacing, 0);
            ctx.lineTo(i * correctedHatchSpacing, 150);
            ctx.closePath();
            ctx.stroke();
          } else {
            ctx.beginPath();
            ctx.moveTo(i * correctedHatchSpacing * tangent, -5);
            ctx.lineTo(-5, i * correctedHatchSpacing);
            ctx.closePath();
            ctx.stroke();
          }
        }

        let canvasTexture = new this.THREE.CanvasTexture(canvas);
        canvasMaterial = new this.THREE.MeshBasicMaterial({
          map: canvasTexture,
          side: this.THREE.DoubleSide
        });
      } else {
        canvasMaterial = new this.THREE.MeshBasicMaterial({
          color: new this.THREE.Color(`rgb(${areaColor.red}, ${areaColor.green}, ${areaColor.blue})`),
          side: this.THREE.DoubleSide
        });
      }

      return canvasMaterial;
    }

    loadTexts(textProps, texts, jsonData, insertionPoints) {
      let font = this.loadFont(textProps);

      if (font) {
        let textArr = [];
        texts.forEach(text => {
          let newText = this.drawText(text, textProps, font);
          newText.jsonID = text.id;
          textArr.push(newText);
        });
        let positionedTexts = this.applyPositionAndRotation(textArr, insertionPoints);
        positionedTexts.forEach(text => {
          let objectInfo = {
            id: text.jsonID,
            data: jsonData,
            sceneObject: text
          };
          this.ThreeObjects.push(objectInfo);
          this.scene.add(text);
        });
      }
    }

    applyPositionAndRotation(meshArr, insertionPoints) {
      let positionedMeshArr = [];
      if (insertionPoints) insertionPoints.forEach(insertionPoint => {
        let insertionVector = this.utils.initVector3(insertionPoint.insertionVector);
        let rotationAngle = insertionPoint.rotationAngle;
        let pivotPoint = new this.THREE.Object3D();

        if (rotationAngle) {
          pivotPoint.position.set(insertionPoint.rotationPoint.x, insertionPoint.rotationPoint.y, insertionPoint.rotationPoint.z);
          meshArr.forEach(mesh => pivotPoint.attach(mesh.clone()));
          rotationAngle = rotationAngle * Math.PI / 180;
          let rotationAxis = this.utils.initVector3(insertionPoint.rotationAxis);
          pivotPoint.rotateOnAxis(rotationAxis, rotationAngle);
        } else {
          meshArr.forEach(mesh => pivotPoint.attach(mesh.clone()));
        }

        pivotPoint.position.add(insertionVector);
        pivotPoint.updateMatrixWorld();
        pivotPoint.children.forEach(child => {
          child.applyMatrix4(pivotPoint.matrixWorld);
          positionedMeshArr.push(child);
        });
      });
      return positionedMeshArr;
    }

    getFontUrl(textProps) {
      let fontType = textProps.type;
      let fontStyle = textProps.style;
      let fontUrl = "";
      let fontName = "";

      if (fontStyle == 1) {
        if (fontType == 1) {
          fontUrl = this.fontUrlArray.Regular["Arial"];
          fontName = "FONT_REGULAR_ARIAL";
        } else if (fontType == 2) {
          fontUrl = this.fontUrlArray.Regular["Calibri"];
          fontName = "FONT_REGULAR_CALIBRI";
        } else if (fontType == 3) {
          fontUrl = this.fontUrlArray.Regular["Courier"];
          fontName = "FONT_REGULAR_COURIER";
        } else if (fontType == 4) {
          fontUrl = this.fontUrlArray.Regular["TimesNewRoman"];
          fontName = "FONT_REGULAR_TIMESNEWROMAN";
        }
      } else if (fontStyle == 2) {
        if (fontType == 1) {
          fontUrl = this.fontUrlArray.Italic["Arial"];
          fontName = "FONT_ITALIC_ARIAL";
        } else if (fontType == 2) {
          fontUrl = this.fontUrlArray.Italic["Calibri"];
          fontName = "FONT_ITALIC_CALIBRI";
        } else if (fontType == 3) {
          fontUrl = this.fontUrlArray.Italic["Courier"];
          fontName = "FONT_ITALIC_COURIER";
        } else if (fontType == 4) {
          fontUrl = this.fontUrlArray.Italic["TimesNewRoman"];
          fontName = "FONT_ITALIC_TIMESNEWROMAN";
        }
      } else if (fontStyle == 3) {
        if (fontType == 1) {
          fontUrl = this.fontUrlArray.Bold["Arial"];
          fontName = "FONT_BOLD_ARIAL";
        } else if (fontType == 2) {
          fontUrl = this.fontUrlArray.Bold["Calibri"];
          fontName = "FONT_BOLD_CALIBRI";
        } else if (fontType == 3) {
          fontUrl = this.fontUrlArray.Bold["Courier"];
          fontName = "FONT_BOLD_COURIER";
        } else if (fontType == 4) {
          fontUrl = this.fontUrlArray.Bold["TimesNewRoman"];
          fontName = "FONT_BOLD_TIMESNEWROMAN";
        }
      } else if (fontStyle == 4) {
        if (fontType == 1) {
          fontUrl = this.fontUrlArray.BoldItalic["Arial"];
          fontName = "FONT_BOLD_ITALIC_ARIAL";
        } else if (fontType == 2) {
          fontUrl = this.fontUrlArray.BoldItalic["Calibri"];
          fontName = "FONT_BOLD_ITALIC_CALIBRI";
        } else if (fontType == 3) {
          fontUrl = this.fontUrlArray.BoldItalic["Courier"];
          fontName = "FONT_BOLD_ITALIC_COURIER";
        } else if (fontType == 4) {
          fontUrl = this.fontUrlArray.BoldItalic["TimesNewRoman"];
          fontName = "FONT_BOLD_ITALIC_TIMESNEWROMAN";
        }
      }

      return [fontUrl, fontName];
    }

    loadFont(textProps) {
      let loadedFonts = this.loadedFonts;
      let [fontUrl, fontName] = this.getFontUrl(textProps);
      let storedFont = loadedFonts[fontName];
      let loader = new this.THREE.FontLoader();
      let font;

      if (fontUrl) {
        if (storedFont) {
          font = storedFont;
        } else {
          $.ajax({
            'async': false,
            type: "GET",
            url: fontUrl
          }).then(function (dataFont) {
            font = loader.parse(dataFont);
            loadedFonts[fontName] = font;
          });
        }
      }

      return font;
    }

    getTextGeo(text, textProps, font) {
      let textContent = text.content;
      let fontSize = textProps.size;
      let heightWidthRatio = textProps.heightWidthRatio;
      let textGeo = new this.THREE.TextBufferGeometry(textContent, {
        font: font,
        size: fontSize,
        height: 0.01,
        curveSegments: 8
      });
      textGeo.scale(heightWidthRatio, 1, 1);
      textGeo.computeBoundingBox();

      switch (text.relativePosition) {
        case 1:
          textGeo.center();
          break;

        case 2:
          textGeo.translate(0, -textGeo.boundingBox.min.y, 0);
          break;

        case 3:
          textGeo.translate(0, -textGeo.boundingBox.max.y, 0);
          break;

        case 4:
          textGeo.translate(-textGeo.boundingBox.max.x, -textGeo.boundingBox.max.y, 0);
          break;

        case 5:
          textGeo.translate(-textGeo.boundingBox.max.x, -textGeo.boundingBox.min.y, 0);
          break;

        default:
          textGeo.center();
          break;
      }

      return textGeo;
    }

    drawText(text, textProps, font) {
      let normalVector = this.utils.initVector3(text.normalVector);
      let directionVector = this.utils.initVector3(text.directionVector);
      let textMesh;

      if (!normalVector.dot(directionVector) && normalVector.lengthSq() && directionVector.lengthSq()) {
        let textInsertionPoint = text.insertionPoint;
        let textGeo = this.getTextGeo(text, textProps, font);
        let textMaterial = this.getMaterialFromColor(textProps.color);
        textMesh = new this.THREE.Mesh(textGeo, textMaterial);
        this.alignObjectWithVectors(textMesh, textInsertionPoint, normalVector, directionVector);
      } else {
        textMesh = new this.THREE.Mesh();
      }

      return textMesh;
    }

    alignObjectWithVectors(textMesh, textInsertionPoint, normalVector, directionVector) {
      let newUpVector = new this.THREE.Vector3().crossVectors(normalVector, directionVector);
      let dotProduct = textMesh.up.dot(newUpVector);
      let rotationAngle = -Math.acos(dotProduct);
      textMesh.rotateOnAxis(normalVector, rotationAngle);
      textMesh.position.set(textInsertionPoint.x, textInsertionPoint.y, textInsertionPoint.z);
      textMesh.up = newUpVector;
      textMesh.lookAt(textMesh.position.clone().add(normalVector));
    }

    drawCustom(jsonDataGlobal) {
      let jsonData = lodash.cloneDeep(jsonDataGlobal);
      console.log(jsonData); // DynamicSectionalPlanesData is located in public directory

      if (DynamicSectionalPlanesData) {
        this.assignDynamicSectionalPlanes(jsonData, DynamicSectionalPlanesData);
        this.DynamicSectionalPlane.initSectionalPlanes(DynamicSectionalPlanesData);
      }

      this.settingsModifyJSON(jsonData);
      this.jsonData = jsonData;
      if (jsonData.fasteners) this.FastenerLoader.drawFasteners();
      if (jsonData.drawingObjects) this.drawObject(jsonData);
      if (jsonData.arrows) jsonData.arrows.forEach(arrowData => {
        let meshArray = this.ArrowLoader.drawArrows(arrowData);
        meshArray.forEach(mesh => {
          let drawnObject = new Object();
          drawnObject.id = arrowData.id;
          drawnObject.type = "arrow";
          drawnObject.data = arrowData;
          drawnObject.sceneObject = mesh;
          drawnObject.isOnScene = mesh.IsHighLight ? false : true;
          this.ThreeObjects.push(drawnObject);
          if (!mesh.IsHighLight) this.scene.add(drawnObject.sceneObject);
        });
      });
      if (jsonData.dimensionalChains) this.DimensionalChainLoader.drawDimensionalChains(jsonData.dimensionalChains);
      this.SectionalPlane.applySectionalPlanes();
      this.DynamicSectionalPlane.applySectionalPlanes();
      if (!this.SceneCenterComputed) this.centerScene();
      if (!this.stencilScenes.length) this.stencilScenes.push(this.scene);

      if (this.isWireFrameOnly) {
        this.isWireFrameOnly = false;
        this.ToggleWireframe();
      }
    }

    assignDynamicSectionalPlanes(jsonData, dynamicSectionalPlane) {
      if (dynamicSectionalPlane.targets.drawingObjects && jsonData.drawingObjects) {
        dynamicSectionalPlane.targets.drawingObjects.forEach(targetID => {
          jsonData.drawingObjects.forEach(object => {
            if (object.id === targetID) {
              object.dynamicSectionalPlanes = object.dynamicSectionalPlanes ? object.dynamicSectionalPlanes : [];
              object.dynamicSectionalPlanes.push(dynamicSectionalPlane.planeData.id);
            }
          });
        });
      }
    }

    settingsModifyJSON(jsonData) {
      let settings = jsonData.settings;
      this.fillPropsWithSettingProps(jsonData, settings, "arrows");
      this.fillPropsWithSettingProps(jsonData, settings, "dimensionalChains");
      this.fillPropsWithSettingProps(jsonData, settings, "drawingObjects");
      this.fillPropsWithSettingProps(jsonData, settings, "fasteners");
    }

    fillPropsWithSettingProps(jsonData, settings, objType) {
      let settingsType = objType.slice(0, objType.length - 1);
      let objects = jsonData[objType];

      if (settings[settingsType] && settings[settingsType].visualisation) {
        let objectSettingProps = settings[settingsType].properties;
        objects.forEach(obj => {
          if (!obj.properties && objectSettingProps) obj.properties = objectSettingProps;else if (objectSettingProps) {
            if (!obj.properties.area && objectSettingProps.area) obj.properties.area = objectSettingProps.area;
            if (!obj.properties.line && objectSettingProps.line) obj.properties.line = objectSettingProps.line;
            if (!obj.properties.text && objectSettingProps.text) obj.properties.text = objectSettingProps.text;
            if (!obj.properties.volume && objectSettingProps.volume) obj.properties.volume = objectSettingProps.volume;
            if (!obj.properties.point && objectSettingProps.point) obj.properties.point = objectSettingProps.point;
            if (!obj.properties.intersectionArea && objectSettingProps.intersectionArea) obj.properties.intersectionArea = objectSettingProps.intersectionArea;
            if (!obj.properties.intersectionLine && objectSettingProps.intersectionLine) obj.properties.intersectionLine = objectSettingProps.intersectionLine;
            if (!obj.properties.fastener && objectSettingProps.fastener) obj.properties.fastener = objectSettingProps.fastener;
            if (!obj.properties.axis && objectSettingProps.axis) obj.properties.axis = objectSettingProps.axis;
            if (!obj.properties.addOn && objectSettingProps.addOn) obj.properties.addOn = objectSettingProps.addOn;
          }
        });
      } else {
        jsonData[objType] = null;
      }
    }

    getMeshUpVector(mesh, direction) {
      let upObject = new this.THREE.Object3D();
      upObject.position.copy(mesh.up);
      mesh.add(upObject);
      mesh.lookAt(direction);
      mesh.updateMatrixWorld();
      let upVector = upObject.getWorldPosition(new this.THREE.Vector3());
      return upVector;
    }

    removeObject(type) {
      this.stencilScenes.forEach(scene => {
        this.ThreeObjects.forEach(object => {
          scene.children.forEach(child => {
            if (object.type == type && object.sceneObject.uuid == child.uuid) {
              object.isOnScene = false;
              scene.remove(child);
            }
          });
        });

        for (let index = 0; index < 50; index++) {
          //there may be more than one in that type and to remove all off them loop is needed
          for (let i = 0; i < scene.children.length; i++) {
            if (scene.children[i].customTypeName == type) {
              scene.remove(scene.children[i]);

              for (let to = 0; to < this.ThreeObjects.length; to++) {
                if (this.ThreeObjects[to].type == type) {
                  this.ThreeObjects[to].isOnScene = false;
                }
              }
            }
          }
        }
      });
    }

    removeObjectMaskette(XorY) {
      // for (let index = 0; index < this.ThreeObjects.length; index++) {
      //  if(this.ThreeObjects[index].type=="maskette"){
      //     console.log(this.ThreeObjects[index]);
      //  }
      // }
      for (let index = 0; index < 50; index++) {
        //there may be more than one in that type and to remove all off them loop is needed
        for (let i = 0; i < this.scene.children.length; i++) {
          if (this.scene.children[i].customTypeName == "maskette" && this.scene.children[i].XorY == XorY) {
            this.scene.remove(this.scene.children[i]);

            for (let to = 0; to < this.ThreeObjects.length; to++) {
              if (this.ThreeObjects[to].type == "maskette" && this.ThreeObjects[to].sceneObject.XorY == XorY) {
                this.ThreeObjects[to].isOnScene = false;
              }
            }
          }
        }
      }
    }

    redrawObject(type) {
      var self = this;
      console.log(type);
      this.ThreeObjects.forEach(function (element) {
        if (type == "maskette" && element.type == "maskette" && !element.isOnScene) {
          // self.drawMaskette(element.data);
          self.scene.add(element.sceneObject);
          element.isOnScene = true;
        }
      });
    }

    redrawObjectMaskette(XorY) {
      var self = this;
      this.ThreeObjects.forEach(function (element) {
        if (element.type == "maskette" && !element.isOnScene && element.sceneObject.XorY == XorY) {
          if (self.interactiveZ && element.sceneObject.IsHighLight) {
            self.scene.add(element.sceneObject);
            element.isOnScene = true;
          } else if (!self.interactiveZ && !element.sceneObject.IsHighLight) {
            self.scene.add(element.sceneObject);
            element.isOnScene = true;
          }
        }
      });
    }

    redrawCSGObject(element) {
      let targetScene = element.targetScene;

      if (element.IsCSGObject && !element.isOnScene) {
        if (!this.isWireFrameOnly) targetScene.add(element.sceneObject);else targetScene.add(element.sceneObjectTransparentCSG);
        element.isOnScene = true;
      }
    }

    highlightX(value) {
      this.interactiveZ = true;
      this.removeObjectMaskette("x");
      this.redrawObjectMaskette("x");
    }

    highlightY(value) {
      this.interactiveZ = true;
      this.removeObjectMaskette("y");
      this.redrawObjectMaskette("y");
    } // TODO: Add highlighting for dimensional chains


    highlightZ(extrusionVectorInteractiveZ) {
      this.interactiveZ = true;
      this.extrusionVectorInteractiveZ = extrusionVectorInteractiveZ; //this.removeObject("maskette");
      //this.redrawObject("maskette");
    }

    clearXHighlight() {
      this.interactiveZ = false;
      this.removeObjectMaskette("x");
      this.redrawObjectMaskette("x");
    }

    clearYHighlight() {
      this.interactiveZ = false;
      this.removeObjectMaskette("y");
      this.redrawObjectMaskette("y");
    }

    clearZHighlight() {
      this.interactiveZ = false;
    }

    degreeToRadians(degree) {
      return degree * Math.PI / 180;
    }

    radiansToDegree(radian) {
      return radian * 180 / Math.PI;
    }

    animate(that) {
      this.updateRendererAndCamera(that.renderer, that.camera);
      this.renderAllScenes(that);
      window.requestAnimationFrame(function () {
        that.animate(that);
        that.TWEEN.update();
      }); // setTimeout(() => {
      //   that.animate(that)
      //   that.TWEEN.update();
      // }, 100);
    }

    renderAllScenes(GraphicsThree3D) {
      GraphicsThree3D.renderer.clear();
      GraphicsThree3D.stencilScenes.forEach(scene => {
        GraphicsThree3D.renderer.render(scene, GraphicsThree3D.camera);
        GraphicsThree3D.renderer.clearDepth();
      });
    }

    updateRendererAndCamera(renderer, camera) {
      let canvas = renderer.domElement;
      let width = canvas.clientWidth;
      let height = canvas.clientHeight;
      let needResize = canvas.width !== width || canvas.height !== height || canvas.style.width !== width || canvas.style.height !== height;

      if (needResize) {
        renderer.setSize(width, height, false);
        let multiplier = 64;
        let cameraAspectRatio = width / height;
        let cameraViewSize = 5000;
        camera.left = -cameraViewSize * cameraAspectRatio / multiplier;
        camera.right = cameraViewSize * cameraAspectRatio / multiplier;
        camera.top = cameraViewSize / multiplier;
        camera.bottom = -cameraViewSize / multiplier;
        camera.updateProjectionMatrix();
      }
    }

    makeCameraTween(direction, distance) {
      let controls = this.controls;
      let camera = this.camera;
      let midVector = this.objectMidVector;
      let currentPosition = {
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
        zoom: camera.zoom
      };
      let currentTarget = {
        x: controls.target.x,
        y: controls.target.y,
        z: controls.target.z
      };
      let finalTarget = {
        x: midVector.x,
        y: midVector.y,
        z: midVector.z
      };
      let targetsNotEqual = currentPosition.x != finalTarget.x && currentPosition.y != finalTarget.y && currentPosition.z != finalTarget.z;

      if (direction == "x" && this.previousCameraPosition == "y") {
        currentPosition.z += 5;
      }

      let changePosition;
      this.cameraTweenActive = true;

      if (direction == "initial") {
        changePosition = {
          x: midVector.x + this.initialPosition.x,
          y: midVector.y + this.initialPosition.y,
          z: midVector.z + this.initialPosition.z
        };
      } else if (direction == "x") {
        changePosition = {
          x: midVector.x - distance,
          y: midVector.y,
          z: midVector.z
        };
      } else if (direction == "y") {
        changePosition = {
          x: midVector.x,
          y: midVector.y + distance,
          z: midVector.z + 5
        };
      } else if (direction == "z") {
        changePosition = {
          x: midVector.x,
          y: midVector.y,
          z: midVector.z + distance
        };
      } else {
        throw new Error("No direction is defined for tweening");
      }

      changePosition.zoom = 1;
      let speed = 350;
      let type = this.TWEEN.Easing.Cubic.InOut;
      let global = this;
      let tween = new this.TWEEN.Tween(currentPosition).to(changePosition, speed).easing(type).onUpdate(function () {
        camera.position.set(currentPosition.x, currentPosition.y, currentPosition.z);
        camera.zoom = currentPosition.zoom;
        camera.lookAt(midVector);
        camera.updateProjectionMatrix();
        controls.update();
      }).onComplete(function () {
        if (direction == "x") global.previousCameraPosition = "x";

        if (direction == "y") {
          camera.position.z -= 5;
          camera.lookAt(midVector);
          camera.updateProjectionMatrix();
          controls.update();
          global.previousCameraPosition = "y";
        }

        if (direction == "z") global.previousCameraPosition = "z";
        if (direction == "initial") global.previousCameraPosition = "inital";
        global.cameraTweenActive = false;
      });

      if (targetsNotEqual) {
        camera.zoom = 1;
        let controlsTween = new this.TWEEN.Tween(currentTarget).to(finalTarget, speed).easing(type).onUpdate(function () {
          controls.target.set(currentTarget.x, currentTarget.y, currentTarget.z - 0.01);
          camera.up = new global.THREE.Vector3(0, 1, 0);
          camera.lookAt(controls.target);
          controls.update();
        }).chain(tween);
        controlsTween.start();
      } else {
        tween.start();
      }
    }

    ChangeCameraPosition0() {
      if (!this.cameraTweenActive) this.makeCameraTween("initial");
      this.interactiveZ = false;
    }

    ChangeCameraPosition1() {
      this.interactiveZ = false;
      if (!this.cameraTweenActive) this.makeCameraTween("x", 150);
    }

    ChangeCameraPosition2() {
      this.interactiveZ = false;
      if (!this.cameraTweenActive) this.makeCameraTween("y", 150);
    }

    ChangeCameraPosition3() {
      this.interactiveZ = false;
      if (!this.cameraTweenActive) this.makeCameraTween("z", 150);
    }

    ToggleWireframe() {
      this.isWireFrameOnly = !this.isWireFrameOnly;
      this.removeObject("polygon");
      this.removeObject("ellipse");
      this.ThreeObjects.forEach(element => this.redrawCSGObject(element));
    }

    SendBase64PNGToServer() {
      let posVector = this.objectMidVector;
      let posVectorOne = posVector.clone().add(new this.THREE.Vector3(this.initialPosition.x, this.initialPosition.y, this.initialPosition.z));
      let posVectorTwo = posVector.clone().sub(new this.THREE.Vector3(150, 0, 0));
      let posVectorThree = posVector.clone().add(new this.THREE.Vector3(0, 150, 0));
      let posVectorFour = posVector.clone().add(new this.THREE.Vector3(0, 0, 150));
      this.changeCameraPositionAndDownloadPNG(posVectorOne, "grafik1.png");
      this.removeObject("maskette");
      this.changeCameraPositionAndDownloadPNG(posVectorTwo, "grafik2.png");
      this.changeCameraPositionAndDownloadPNG(posVectorThree, "grafik3.png");
      this.redrawObject("maskette");
      this.changeCameraPositionAndDownloadPNG(posVectorFour, "grafik4.png");
    }

    changeCameraPositionAndDownloadPNG(posVector, fileName) {
      this.camera.position.copy(posVector);
      this.camera.lookAt(this.objectMidVector);
      this.camera.updateProjectionMatrix();
      this.controls.update();
      this.renderAllScenes(this);
      this.downloadAsPNGImage(fileName);
    }

    downloadAsPNGImage(fileName) {
      var strMime = "image/png";
      var imgData = this.renderer.domElement.toDataURL(strMime); //download image

      var link = document.createElement('a');

      if (typeof link.download === 'string') {
        document.body.appendChild(link); //Firefox requires the link to be in the body

        link.download = fileName;
        link.href = imgData;
        link.click();
        document.body.removeChild(link); //remove the link when done
      } else {
        location.replace(uri);
      }
    }

  }

  _exports.GraphicsThree3D = GraphicsThree3D;
});