define("m08-2020/lib/Utils/DimensionalChainIntersectionDetection", ["exports", "three", "m08-2020/lib/Utils"], function (_exports, THREE, _Utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DimensionalChainIntersectionDetection = void 0;
  let utils = new _Utils.Utils(THREE);

  class DimensionalChainIntersectionDetection {
    constructor() {
      this.meshArr = []; // [ DoubleFace, FrontFace, BackFace ]

      this.chainArr = []; // [ DoubleFace, FrontFace, BackFace ]
    }

    checkCollision(linePtsArr, objArr) {
      let isIntersection = false;
      let raycaster = new THREE.Raycaster();
      linePtsArr.forEach(([pointOne, pointTwo]) => {
        let pointsDistance = pointOne.distanceTo(pointTwo);
        let intersectionsOne = this.getIntersections(raycaster, [pointOne, pointTwo], objArr);
        let intersectionsTwo = this.getIntersections(raycaster, [pointTwo, pointOne], objArr);
        if (this.intersectionDetected(pointsDistance, intersectionsOne, intersectionsTwo)) isIntersection = true;
      });
      return isIntersection;
    }

    intersectionDetected(pointsDistance, intersectionsOne, intersectionsTwo) {
      let [intersectionsOneDouble, intersectionsOneFront, intersectionsOneBack] = intersectionsOne;
      let [intersectionsTwoDouble, intersectionsTwoFront, intersectionsTwoBack] = intersectionsTwo;
      let mutualIntersections = this.getMutualIntersections(intersectionsOneDouble, intersectionsTwoDouble);
      return mutualIntersections.length !== 1 && this.bothPointsOutsideMesh(mutualIntersections, pointsDistance) || this.onePointInsideMeshOneOut(mutualIntersections, intersectionsOneDouble, intersectionsTwoDouble) || this.bothPointsInMesh(intersectionsOneBack, intersectionsTwoBack, intersectionsOneFront, intersectionsTwoFront);
    }

    bothPointsOutsideMesh(mutualIntersections, pointsDistance) {
      let intersectionInMiddle = false;
      mutualIntersections.forEach(intersection => {
        if (intersection.distance < pointsDistance) intersectionInMiddle = true;
      });
      return intersectionInMiddle;
    }

    onePointInsideMeshOneOut(mutualIntersections, intersectionsOneDouble, intersectionsTwoDouble) {
      let pointOneInside = this.pointInsideMesh(intersectionsOneDouble);
      let pointTwoInside = this.pointInsideMesh(intersectionsTwoDouble);
      return mutualIntersections.length > 0 && (pointOneInside && !pointTwoInside || !pointOneInside && pointTwoInside);
    }

    bothPointsInMesh(intersectionsOneBack, intersectionsTwoBack, intersectionsOneFront, intersectionsTwoFront) {
      return intersectionsOneBack.length > 0 && intersectionsTwoBack.length > 0 && intersectionsOneFront.length === 0 && intersectionsTwoFront.length === 0;
    }

    getIntersections(raycaster, ptsPair, meshArr) {
      let [pointOne, pointTwo] = ptsPair;
      let direction = pointTwo.clone().sub(pointOne).normalize();
      raycaster.set(pointOne, direction);
      let intersectionsDouble = [];
      let intersectionsFront = [];
      let intersectionsBack = [];
      const DELTA = 1e-4;
      meshArr.forEach(([meshDoubleFace, meshFrontFace, meshBackFace]) => {
        intersectionsDouble.push(...raycaster.intersectObject(meshDoubleFace).filter(intersection => intersection.distance > DELTA));
        intersectionsFront.push(...raycaster.intersectObject(meshFrontFace).filter(intersection => intersection.distance > DELTA));
        intersectionsBack.push(...raycaster.intersectObject(meshBackFace).filter(intersection => intersection.distance > DELTA));
      });
      intersectionsDouble = this.removeDuplicateIntersections(intersectionsDouble);
      intersectionsFront = this.removeDuplicateIntersections(intersectionsFront);
      intersectionsBack = this.removeDuplicateIntersections(intersectionsBack);
      return [intersectionsDouble, intersectionsFront, intersectionsBack];
    }

    getMutualIntersections(intersectionsOne, intersectionsTwo) {
      let intersections = intersectionsOne.filter(intersectionOne => {
        let isMutual = false;
        let testPoint = intersectionOne.point;
        intersectionsTwo.forEach(intersectionTwo => {
          if (utils.checkVectorsEquality(intersectionTwo.point, testPoint)) isMutual = true;
        });
        return isMutual;
      });
      return intersections;
    }

    removeDuplicateIntersections(intersections) {
      let filterArr = [];
      intersections.forEach(intersection => {
        let isUnique = true;
        filterArr.forEach(([key, _]) => {
          if (utils.vectorsAreEqual(intersection.point, key)) isUnique = false;
        });
        if (isUnique) filterArr.push([intersection.point, intersection]);
      });
      intersections = filterArr.map(([_, value]) => value);
      return intersections;
    }

    intersectionsSharePoints(intersectionsOne, intersectionsTwo) {
      let intersectionsSharePoints = false;

      for (let intersectionOne of intersectionsOne) {
        for (let intersectionTwo of intersectionsTwo) {
          if (utils.vectorsAreEqual(intersectionOne.point, intersectionTwo.point)) intersectionsSharePoints = true;
        }
      }

      return intersectionsSharePoints;
    }

    pointInsideMesh(intersections) {
      return intersections.length % 2;
    }

  }

  _exports.DimensionalChainIntersectionDetection = DimensionalChainIntersectionDetection;
});