define("m08-2020/lib/FigureLoaders/FastenerLoader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FastenerLoader = void 0;

  class FastenerLoader {
    constructor(THREE, GraphicsThree3D) {
      this.THREE = THREE;
      this.CSG = GraphicsThree3D.CSG;
      this.fontUrlArray = GraphicsThree3D.fontUrlArray;
      this.GraphicsThree3D = GraphicsThree3D;
      this.utils = GraphicsThree3D.utils;
      this.WireframeGenerator = GraphicsThree3D.WireframeGenerator; ///

      this.drawFasteners = this.drawFasteners.bind(this);
    }

    drawFasteners() {
      let jsonData = this.GraphicsThree3D.jsonData;
      let dxfFastenerPoints = this.GraphicsThree3D.dxfGenerator.fastenerPoints;

      for (let i = 0; i < jsonData.fasteners.length; i++) {
        this.addToDXFPoints(dxfFastenerPoints, jsonData.fasteners[i]);

        if (jsonData.fasteners[i].components.head && jsonData.fasteners[i].components.head.type > 8) {
          this.drawClamp(jsonData.fasteners[i]);
        } else {
          let meshArray = this.drawFastener(jsonData.fasteners[i]); // meshArray.forEach(obj => this.addToDimChainCollisionDetection(obj));

          for (let a = 0; a < meshArray.length; a++) {
            this.setTime(meshArray, i, a);
          }
        }
      }
    }

    addToDXFPoints(dxfFastenerPoints, fastenerData) {
      if (fastenerData.components.axis && fastenerData.properties.axis && fastenerData.properties.axis.line && fastenerData.properties.axis.point) {
        let axisLength = fastenerData.components.axis.length;
        dxfFastenerPoints[fastenerData.id] = {
          axis: [],
          addon: []
        };
        fastenerData.insertionPoints.forEach(insertionPoint => {
          let directionVec = this.utils.initVector3(insertionPoint.directionVector1);
          let startPoint = this.utils.initVector3(insertionPoint.insertionPoint);
          let endPoint = startPoint.clone().add(directionVec.multiplyScalar(axisLength));
          let dxfData = {
            pointPair: [startPoint, endPoint],
            properties: {
              line: fastenerData.properties.axis.line,
              point: fastenerData.properties.axis.point
            }
          };
          dxfFastenerPoints[fastenerData.id].axis.push(dxfData);
        });
      }
    }

    addToDimChainCollisionDetection(obj) {
      obj.updateMatrix();
      obj.traverse(child => {
        if (child.type === "Mesh" && !(child.geometry.faceVertexUvs && child.geometry.faceVertexUvs[0].length === 0)) {
          let intersectionObject = child.clone();
          intersectionObject.applyMatrix4(obj.matrix);
          let intersectionObjects = this.GraphicsThree3D.getThreeSideFaceObjects(intersectionObject);
          this.GraphicsThree3D.DimensionalChainIntersectionDetection.meshArr.push(intersectionObjects);
        }
      });
    }

    drawFastener(fastener) {
      let meshArray = [];

      if (fastener.components.axis) {
        let axisData = fastener.components.axis;
        let shankData = fastener.components.shank;
        let headData = fastener.components.head;
        let threadsData = fastener.components.threads;
        let tipData = fastener.components.tip;
        let fastenerProps = fastener.properties; // Axis

        let axisGeo, axisPointGeo;
        let axisMat, axisPointMat;
        let inversedDirection = false;

        if (axisData.length < 0) {
          inversedDirection = true;
          axisData.length *= -1; // maybe change code not to modify json?
        }

        if (fastenerProps.axis) {
          if (axisData.length > 0) {
            let lineProps = fastenerProps.axis.line;
            if (lineProps.thickness && lineProps.type > 0) axisGeo = this.getAxisGeo(axisData, fastenerProps.axis);
            axisMat = this.GraphicsThree3D.getMaterialFromColor(lineProps.color);
          }

          let pointProps = fastenerProps.axis.point;
          if (pointProps.size) axisPointGeo = this.getAxisPointGeo(pointProps);
          axisPointMat = this.GraphicsThree3D.getMaterialFromColor(pointProps.color);
        }

        let headSideLineProps = fastenerProps.fastener.headSide.line;
        let fastenerMaterial = this.getMaterial(fastener.properties, "fastener");
        let lineMaterial = this.getLineMaterial(headSideLineProps);
        let headSideMeshes = [];
        let tipSideMeshes = []; // Heads

        let headGeo = this.getFastenerHeadGeo(headData);
        let head = this.drawFastenerHead(headData, headGeo, fastenerMaterial);
        if (head) headSideMeshes.push(head);
        let drawBottomFrameHead = true;

        if (shankData && head) {
          if (headData.type == 1 && (shankData.type == 1 || shankData.type == 2 && shankData.size >= headData.bottomDiameter.value)) drawBottomFrameHead = false;
        }

        let headWireframes;
        if (headSideLineProps && head) headWireframes = this.makeHeadWireframe(headGeo, headSideLineProps, drawBottomFrameHead);

        if (threadsData) {
          let threadMaxPoint = threadsData[threadsData.length - 1].insertionDistance + threadsData[threadsData.length - 1].length;
          if (axisData.length == 0 || threadMaxPoint > axisData.length) axisData.length = threadMaxPoint;
        }

        let segmentsLeft = [[0, axisData.length]];

        if (head && headData && (headData.type == 1 || headData.type == 2 || headData.type == 6)) {
          segmentsLeft[0][0] = headData.height.value;
        }

        if (tipData && tipData.type && tipData.height && (fastenerMaterial || fastenerProps.fastener.tipSide)) {
          segmentsLeft[0][1] -= tipData.height;
        }

        let threadSegments = [];
        if (threadsData) threadsData.forEach(thread => {
          let minPoint = thread.insertionDistance;
          let maxPoint = thread.insertionDistance + thread.length;

          if (minPoint < segmentsLeft[0][0]) {
            minPoint = segmentsLeft[0][0];
          }

          if (maxPoint > segmentsLeft[0][1]) {
            maxPoint = segmentsLeft[0][1];
          }

          let threadParams = {
            coreDiameter: thread.coreDiameter,
            distance: thread.distance,
            height: thread.height,
            pitch: thread.pitch,
            threadDiameter: thread.threadDiameter
          };
          threadSegments.push([minPoint, maxPoint, threadParams]);
        });
        threadSegments.forEach(threadSegment => {
          let minPoint = threadSegment[0];
          let maxPoint = threadSegment[1];
          segmentsLeft.forEach(segment => {
            if (minPoint > segment[0] && minPoint < segment[1] && maxPoint > segment[0] && maxPoint < segment[1]) {
              segmentsLeft.push([maxPoint, segment[1]]);
              segment[1] = minPoint;
            } else {
              if (minPoint > segment[0] && minPoint < segment[1]) segment[1] = minPoint;
              if (maxPoint > segment[0] && maxPoint < segment[1]) segment[0] = maxPoint;
            }
          });
        });
        let tipDiameter;

        if (threadSegments.length > 0 && segmentsLeft.length > 0) {
          let lastThreadMax = threadSegments[threadSegments.length - 1][1];
          let lastShankMax = segmentsLeft[segmentsLeft.length - 1][1];
          let threadDiameter = threadSegments[threadSegments.length - 1][2].coreDiameter;
          let shankSize = shankData ? shankData.size : 0;
          if (lastThreadMax > lastShankMax) tipDiameter = threadDiameter;else if (!shankSize) tipDiameter = threadDiameter;else tipDiameter = shankSize;
        } else if (threadSegments.length > 0) {
          tipDiameter = threadSegments[threadSegments.length - 1][2].coreDiameter;
        } else if (segmentsLeft.length > 0) {
          tipDiameter = shankData.size;
        }

        if (tipDiameter == 0) {
          tipDiameter = headData.bottomDiameter.value;
        }

        let tipSideProps = fastenerProps.fastener.tipSide;
        let tipSideMaterial;

        if (shankData && tipSideProps && shankData.tipSideLength) {
          tipSideMaterial = this.getMaterial(fastenerProps, "tipSide");
          let tipSideStartPoint = axisData.length - shankData.tipSideLength;
          segmentsLeft.forEach((segment, index) => {
            let minPoint = segment[0];
            let maxPoint = segment[1];

            if (tipSideStartPoint > minPoint && tipSideStartPoint < maxPoint) {
              segmentsLeft.splice(index + 1, 0, [tipSideStartPoint, maxPoint, true]);
              segment[1] = tipSideStartPoint;
              segment[2] = false;
            } else if (tipSideStartPoint < minPoint && tipSideStartPoint < maxPoint) {
              segment[2] = true;
            } else {
              segment[2] = false;
            }
          });
        }

        let shankGeo = this.getFastenerShankGeo(segmentsLeft, fastener.components.shank, fastener);
        let tipGeo = this.getFastenerTipGeo(fastener.components.tip, tipDiameter, fastener, axisData.length);
        let isFastenerWithPitch = false;
        let isFastenerWithoutPitch = false;
        let cylGeo, spiralGeo, withoutPitchGeo, withoutPitchCylGeo, cylWireframes, coreWireframes;
        let wireframeWithoutPitchPts, wireframeWithPitchPts;
        let intersectionThreadMeshes = [];

        if (threadsData) {
          threadsData.forEach(thread => {
            if (thread.pitch && !thread.distance) isFastenerWithPitch = true;else if (!thread.pitch && thread.distance) isFastenerWithoutPitch = true;
          });
          intersectionThreadMeshes.push(...this.getThreadIntersectionBoxes(threadSegments));

          if (isFastenerWithPitch) {
            [cylGeo, spiralGeo, wireframeWithPitchPts, cylWireframes] = this.getSpiralAndCylGeo(threadSegments, fastenerProps);
          }

          if (isFastenerWithoutPitch) {
            [withoutPitchGeo, withoutPitchCylGeo, wireframeWithoutPitchPts, coreWireframes] = this.getThreadWithoutPitchGeo(threadSegments, fastenerProps);
          }
        } // Axis


        let axis;
        if (fastenerProps.axis) axis = this.drawAxis(axisGeo, axisPointGeo, axisMat, axisPointMat, fastener.properties.axis); // Threads

        let threads = [];

        if (threadsData) {
          if (isFastenerWithPitch) {
            let [cylinder, spiral] = this.drawFastenerThread_0_WithPitch(cylGeo, spiralGeo, fastenerMaterial, wireframeWithPitchPts, headSideLineProps, lineMaterial);
            if (cylinder) headSideMeshes.push(cylinder);
            threads.push(spiral);
          }

          if (isFastenerWithoutPitch) {
            let [thread, core] = this.drawFastenerThread_1_WithoutPitch(withoutPitchGeo, withoutPitchCylGeo, fastenerMaterial, wireframeWithoutPitchPts, headSideLineProps);
            threads.push(thread);
            if (core) headSideMeshes.push(core);
          }
        } // Tip


        let drawTopFrameShank = true;
        let drawBotFrameShank = true;
        let tipWireframes;
        let tip = this.drawFastenerTip(tipGeo, fastenerMaterial, tipSideMaterial);

        if (tip) {
          let drawTopTip = true;

          if (shankData && segmentsLeft[segmentsLeft.length - 1][1] == axisData.length - tipData.height) {
            if (shankData.type == 1 && (tipData.type == 1 || tipData.type == 2)) {
              drawTopTip = false;
              drawBotFrameShank = false;
            } else if (shankData.type == 2 && (tipData.type == 3 || tipData.type == 4 || tipData.type == 5 || tipData.type == 6)) {
              drawTopTip = false;
              drawBotFrameShank = false;
            }
          } else if (threadsData && threadSegments && threadSegments[threadSegments.length - 1][1] == axisData.length - tipData.height) {
            if (tipData.type == 1 || tipData.type == 2) drawTopTip = false;
          }

          tipWireframes = this.makeTipWireframe(tipData, tipGeo, tipSideProps && shankData.tipSideLength ? tipSideProps.line : headSideLineProps, drawTopTip);
          if (tip.isTipSide) tipSideMeshes.push(tip);else headSideMeshes.push(tip);
        }

        if (shankData && head) {
          let diameterToCompare = headData.bottomDiameter.value !== 0 ? headData.bottomDiameter.value : headData.topDiameter.value;
          if (headData.wrenchSize) diameterToCompare = headData.wrenchSize;

          if (shankData.type == 1 && diameterToCompare >= shankData.size) {
            drawTopFrameShank = false;
          }
        } // Shank


        let [shanks, shankWireframes] = this.drawFastenerShank(fastener.components.shank, fastener.properties, shankGeo, drawTopFrameShank, drawBotFrameShank);
        shanks.headSide.forEach(shank => {
          if (shank) headSideMeshes.push(shank);
        });
        shanks.tipSide.forEach(shank => {
          if (shank) tipSideMeshes.push(shank);
        });
        let headSideMesh = headSideMeshes.splice(0, 1)[0];
        let tipSideMesh = tipSideMeshes.splice(0, 1)[0];
        headSideMeshes.forEach(mesh => {
          if (mesh) {
            let firstCSG = this.CSG.fromMesh(headSideMesh);
            let secondCSG = this.CSG.fromMesh(mesh);
            let unionCSG = firstCSG.union(secondCSG);
            let unionMesh = this.CSG.toMesh(unionCSG, new this.THREE.Matrix4());
            headSideMesh = unionMesh;
          }
        });
        tipSideMeshes.forEach(mesh => {
          if (mesh) {
            let firstCSG = this.CSG.fromMesh(tipSideMesh);
            let secondCSG = this.CSG.fromMesh(mesh);
            let unionCSG = firstCSG.union(secondCSG);
            let unionMesh = this.CSG.toMesh(unionCSG, new this.THREE.Matrix4());
            tipSideMesh = unionMesh;
          }
        });
        if (headSideMesh && fastenerMaterial) headSideMesh.material = fastenerMaterial;
        if (tipSideMesh && tipSideMaterial) tipSideMesh.material = tipSideMaterial;
        if (headSideMesh) headSideMesh.material.side = this.THREE.FrontSide;
        if (tipSideMesh) tipSideMesh.material.side = this.THREE.FrontSide;
        let backSideHead, backSideTip;

        if (headSideMesh) {
          let geo = headSideMesh.geometry.clone();
          let mat = headSideMesh.material.clone();
          mat.side = this.THREE.BackSide;
          backSideHead = new this.THREE.Mesh(geo, mat);
        }

        if (tipSideMesh) {
          let geo = tipSideMesh.geometry.clone();
          let mat = tipSideMesh.material.clone();
          mat.side = this.THREE.BackSide;
          backSideTip = new this.THREE.Mesh(geo, mat);
        }

        for (let b = 0; b < fastener.insertionPoints.length; b++) {
          let intersectionPoint = fastener.insertionPoints[b];
          let insertionPoint = intersectionPoint.insertionPoint;
          let insertionVector = new this.THREE.Vector3(insertionPoint.x, insertionPoint.y, insertionPoint.z);
          let rotationVector = this.utils.normalizeVector(intersectionPoint.directionVector1);
          let rotationVectorTwo = this.utils.normalizeVector(intersectionPoint.directionVector2);
          let objectFastener = new this.THREE.Object3D();
          let clonedHeadSide, clonedTipSide;

          if (headSideMesh) {
            clonedHeadSide = headSideMesh.clone();
            objectFastener.add(clonedHeadSide);
          }

          if (tipSideMesh) {
            clonedTipSide = tipSideMesh.clone();
            objectFastener.add(clonedTipSide);
          }

          if (axis) objectFastener.add(axis.clone());
          threads.forEach(thread => {
            if (thread) objectFastener.add(thread.clone());
          });

          if (backSideHead) {
            backSideHead = backSideHead.clone();
            objectFastener.add(backSideHead);
          }

          if (backSideTip) {
            backSideTip = backSideTip.clone();
            objectFastener.add(backSideTip);
          } // AddOns


          let [addOns, addOnDxfPointsData] = this.generateAddons(fastener.components.addOns, fastenerProps.addOn);
          addOns.forEach(addOn => {
            if (addOn) {
              addOn.renderOrder = -2;
              addOn.isAddOn = true;
              let backSide = this.drawBacksideMesh(addOn);
              backSide.renderOrder = -3;
              addOn.add(backSide);
              objectFastener.add(addOn);
            }
          }); ///

          intersectionThreadMeshes.forEach(mesh => objectFastener.add(mesh.clone())); ///

          if (headWireframes) headWireframes.forEach(wireframeArr => wireframeArr.forEach(mesh => {
            objectFastener.add(mesh.clone());
          }));
          if (shankWireframes) shankWireframes.forEach(wireframe => wireframe.forEach(segment => {
            objectFastener.add(segment.clone());
          }));
          if (tipWireframes) tipWireframes.forEach(wireframeArr => wireframeArr.forEach(wireframe => {
            objectFastener.add(wireframe.clone());
          }));
          if (cylWireframes) cylWireframes.forEach(wireframeArr => wireframeArr.forEach(wireframe => wireframe.forEach(line => objectFastener.add(line.clone()))));
          if (coreWireframes) coreWireframes.forEach(wireframeArr => wireframeArr.forEach(wireframe => wireframe.forEach(line => objectFastener.add(line.clone()))));
          let directionVector = new this.THREE.Vector3(rotationVector.x, rotationVector.y, rotationVector.z);
          let directionVectorTwo = new this.THREE.Vector3(rotationVectorTwo.x, rotationVectorTwo.y, rotationVectorTwo.z);

          if (inversedDirection) {
            directionVector.negate();
            directionVectorTwo.negate();
          }

          if (clonedTipSide) {
            let objects = {
              clonedTipSide,
              backSideTip,
              clonedHeadSide,
              backSideHead
            };
            let fastenerProps = {
              axisData,
              shankData,
              directionVector,
              insertionVector
            };
            this.removeHeadTipConnection(objects, fastenerProps);
          }

          objectFastener.children.forEach(child => {
            if (!child.isAddOn) child.renderOrder = -4;
          });
          if (backSideHead) backSideHead.renderOrder = -5;
          if (backSideTip) backSideTip.renderOrder = -5;
          this.rotateObject(objectFastener, directionVector, directionVectorTwo);
          objectFastener.position.set(insertionPoint.x, insertionPoint.y, insertionPoint.z);
          meshArray.push(objectFastener);
          this.GraphicsThree3D.addObjectToVisControl(objectFastener, "fastener"); // Might need better solution

          objectFastener.updateMatrix();
          addOnDxfPointsData.forEach(ptData => {
            if (ptData) {
              ptData.points.forEach(pts => pts.forEach(pt => pt.applyMatrix4(objectFastener.matrix)));
              this.GraphicsThree3D.dxfGenerator.fastenerPoints[fastener.id].addon.push(ptData);
            }
          });
        }
      }

      return meshArray;
    }

    generateAddons(addOnsDataArr, addOnProps) {
      let addOns = [];
      let addOnDxfPointsData = [];

      if (addOnsDataArr) {
        addOnsDataArr.forEach(addOn => {
          if (addOn.type == 1) {
            let [mesh, dxfData] = this.drawPolygonNut(addOn, addOnProps);
            addOns.push(mesh);
            addOnDxfPointsData.push(dxfData);
          } else if (addOn.type == 2) {
            let [mesh, dxfData] = this.drawCircleWasher(addOn, addOnProps);
            addOns.push(mesh);
            addOnDxfPointsData.push(dxfData);
          } else if (addOn.type == 3) {
            let [mesh, dxfData] = this.drawRectangleWasher(addOn, addOnProps);
            addOns.push(mesh);
            addOnDxfPointsData.push(dxfData);
          } else if (addOn.type == 4) {
            let [mesh, dxfData] = this.drawAngleWasherTypeA(addOn, addOnProps);
            addOns.push(mesh);
            addOnDxfPointsData.push(dxfData);
          } else if (addOn.type == 5) {
            let [mesh, dxfData] = this.drawAngleWasherTypeB(addOn, addOnProps);
            addOns.push(mesh);
            addOnDxfPointsData.push(dxfData);
          } else if (addOn.type == 6) {
            let [mesh, dxfData] = this.drawFtConnector(addOnProps);
            addOns.push(mesh);
            addOnDxfPointsData.push(dxfData);
          } else console.log("AddOn type undefined");
        });
      }

      return [addOns, addOnDxfPointsData];
    }

    removeHeadTipConnection(objects, fastenerProps) {
      let axisData = fastenerProps.axisData;
      let shankData = fastenerProps.shankData;
      let directionVector = fastenerProps.directionVector;
      let insertionVector = fastenerProps.insertionVector;
      let clonedTipSide = objects.clonedTipSide;
      let backSideTip = objects.backSideTip;
      let clonedHeadSide = objects.clonedHeadSide;
      let backSideHead = objects.backSideHead;
      let shankLength = axisData.length;
      let tipOffset = shankData.tipSideLength;
      let planeVector = directionVector.clone().normalize();
      let positionVector = insertionVector.clone().projectOnVector(planeVector);
      let positionDistance = -(positionVector.length() + shankLength - tipOffset + 0.0001);
      let clippingPlane = new this.THREE.Plane(planeVector, positionDistance);
      clonedTipSide.material.clippingPlanes = [clippingPlane];
      backSideTip.material.clippingPlanes = [clippingPlane];

      if (clonedHeadSide) {
        let shankLength = axisData.length;
        let tipOffset = shankData.tipSideLength;
        let planeVector = directionVector.clone().negate().normalize();
        let positionVector = insertionVector.projectOnVector(planeVector);
        let positionDistance = positionVector.length() + shankLength - tipOffset - 0.0001;
        let clippingPlane = new this.THREE.Plane(planeVector, positionDistance);
        clonedHeadSide.material.clippingPlanes = [clippingPlane];
        backSideHead.material.clippingPlanes = [clippingPlane];
      }
    }

    drawDoublesideFrame(geo, lineProps, drawTop = true, drawBot = true, drawEdges = false) {
      let partOne = [];
      let partTwo = [];
      let wireframes = [];
      let geoVertices = geo.vertices;
      let firstPart = geoVertices[0].y;
      geoVertices.forEach(vertex => {
        if (vertex.y == firstPart) partOne.push(vertex);else partTwo.push(vertex);
      });

      if (geo.type == "CylinderGeometry") {
        partOne.splice(partOne.length - 1, 1);
        partTwo.splice(partTwo.length - 1, 1);
      }

      if (drawTop && partOne.length > 0) {
        let wireframeOne = this.drawLineFromPoints(partOne, lineProps, true);
        wireframes.push([wireframeOne]);
      }

      if (drawBot && partTwo.length > 0) {
        let wireframeTwo = this.drawLineFromPoints(partTwo, lineProps, true);
        wireframes.push([wireframeTwo]);
      }

      if (drawEdges) {
        let edgesLineArr = this.drawEdgesFrame(partOne, partTwo, lineProps);
        wireframes.push(edgesLineArr);
      }

      return wireframes;
    }

    drawEdgesFrame(partOne, partTwo, lineProps) {
      // partOne must be array, partTwo can be vector
      let edgesLineArr = [];

      if (partOne.length > 0) {
        if (partOne.length == partTwo.length) {
          for (let i = 0; i < partOne.length; i++) {
            let line = this.drawLineFromPoints([partOne[i], partTwo[i]], lineProps);
            edgesLineArr.push(line);
          }
        } else {// TODO: vector as point
        }
      }

      return edgesLineArr;
    }

    getThreadIntersectionBoxes(threadSegments) {
      let boxArr = [];
      threadSegments.forEach(segmentData => {
        let boxHeight = segmentData[1] - segmentData[0];
        let boxWidth = segmentData[2].threadDiameter;
        let boxGeo = new this.THREE.BoxGeometry(boxWidth, boxHeight, boxWidth);
        boxGeo.translate(0, -boxHeight / 2 - segmentData[0], 0);
        let boxMat = new this.THREE.MeshBasicMaterial();
        let box = new this.THREE.Mesh(boxGeo, boxMat);
        box.visible = false;
        boxArr.push(box);
      });
      return boxArr;
    }

    drawFastenerHead(headData, headGeo, fastenerMaterial) {
      let fastenerHead;

      if (headData) {
        if (headData.type == 1 || headData.type == 2) {
          fastenerHead = this.drawFastenerHead_1_Countersunk(headGeo, fastenerMaterial, headData);
        } else if (headData.type == 3) {
          fastenerHead = this.drawFastenerHead_3_Lentil(headGeo, fastenerMaterial, headData);
        } else if (headData.type == 4) {
          fastenerHead = this.drawFastenerHead_4_DiscFlat(headGeo, fastenerMaterial, headData);
        } else if (headData.type == 5) {
          fastenerHead = this.drawFastenerHead_5_DiscBottomFlat(headGeo, fastenerMaterial, headData);
        } else if (headData.type == 6) {
          fastenerHead = this.drawFastenerHead_6_DiscTopFlat(headGeo, fastenerMaterial, headData);
        } else if (headData.type == 7) {
          fastenerHead = this.drawFastenerHead_7_PolygonWithoutWasher(headGeo, fastenerMaterial, headData);
        } else if (headData.type == 8) {
          fastenerHead = this.drawFastenerHead_8_PolygonWithWasher(headGeo, fastenerMaterial, headData);
        } else {
          console.log("Head type undefined");
        }
      } else {
        fastenerHead = null;
      }

      return fastenerHead;
    }

    drawBacksideMesh(mesh) {
      let geo = mesh.geometry.clone();
      let mat = mesh.material.clone();
      mat.side = this.THREE.BackSide;
      let backsideMesh = new this.THREE.Mesh(geo, mat);
      return backsideMesh;
    }

    getAddonDxfData_PolygonWireframe(addonMesh, addonWireframes, addOnProps) {
      let ptsArr = [];
      addonWireframes.forEach(wireframe => wireframe.forEach(line => {
        let pts = this.utils.getVerticesFromBufferGeometry(line.geometry);
        if (line.type === "LineLoop") pts.push(pts[0].clone());
        pts.forEach(pt => pt.applyMatrix4(addonMesh.matrix));
        ptsArr.push(pts);
      }));
      return {
        points: ptsArr,
        properties: addOnProps.line
      };
    }

    getAddonDxfData_CircleWasher(addonMesh, addonWireframes, addOnProps) {
      let ptsArr = [];
      let sidePoints = [];
      addonWireframes.forEach(wireframe => wireframe.forEach(line => {
        let pts = this.utils.getVerticesFromBufferGeometry(line.geometry);

        if (pts.length > 0) {
          pts.push(pts[0].clone());
          pts.forEach(pt => pt.applyMatrix4(addonMesh.matrix));
          ptsArr.push(pts);
        }
      }));

      if (ptsArr.length === 2) {
        ptsArr[0].forEach((point, idx) => sidePoints.push([point.clone(), ptsArr[1][idx].clone()]));
        ptsArr.push(...sidePoints);
      }

      return {
        points: ptsArr,
        properties: addOnProps.line
      };
    }

    drawPolygonNut(addOnData, addOnProps) {
      let mesh, dxfData;

      if (addOnData.height > 0 && addOnData.numberOfEdges > 2 && addOnProps && addOnData.wrenchSize > 0) {
        let insertionDistance = addOnData.insertionDistance;
        let height = addOnData.height;
        let wrenchSize = addOnData.wrenchSize / 2;
        let numberOfEdges = addOnData.numberOfEdges;
        let angle = (numberOfEdges - 2) * 180 / numberOfEdges / 2;
        angle *= Math.PI / 180;
        let radius = wrenchSize / Math.sin(angle);
        let geometry = new this.THREE.CylinderGeometry(radius, radius, height, numberOfEdges);
        geometry.rotateY(Math.PI / 6); //temp

        geometry.translate(0, -height / 2, 0);
        geometry.isPolygon = true;
        let volumeColor = addOnProps.volume.color;
        let volumeMaterial = this.GraphicsThree3D.getMaterialFromColor(volumeColor);
        mesh = new this.THREE.Mesh(geometry, volumeMaterial); ///

        let lineProps = addOnProps.line;
        let addonWireframes = this.makeHeadWireframe(geometry, lineProps);
        addonWireframes.forEach(wireframe => wireframe.forEach(line => mesh.add(line))); ///

        mesh.position.set(0, -insertionDistance, 0);
        mesh.updateMatrix();
        dxfData = this.getAddonDxfData_PolygonWireframe(mesh, addonWireframes, addOnProps);
      }

      return [mesh, dxfData];
    }

    drawCircleWasher(addOnData, addOnProps) {
      let mesh, dxfData;

      if (addOnData.height > 0 && addOnProps) {
        let insertionDistance = addOnData.insertionDistance;
        let height = addOnData.height;
        let topRadius = addOnData.topDiameter / 2;
        let botRadius = addOnData.bottomDiameter / 2;

        if (topRadius > 0 || botRadius > 0) {
          let geometry = new this.THREE.CylinderGeometry(topRadius, botRadius, height, 32);
          geometry.translate(0, -height / 2, 0);
          geometry.isAddOn = true;
          let volumeColor = addOnProps.volume.color;
          let volumeMaterial = this.GraphicsThree3D.getMaterialFromColor(volumeColor);
          volumeMaterial.side = this.THREE.FrontSide;
          mesh = new this.THREE.Mesh(geometry, volumeMaterial); ///

          let lineProps = addOnProps.line;
          let addonWireframes = this.makeHeadWireframe(geometry, lineProps);
          addonWireframes.forEach(wireframe => wireframe.forEach(line => mesh.add(line))); ///

          mesh.position.set(0, -insertionDistance, 0);
          mesh.updateMatrix();
          dxfData = this.getAddonDxfData_CircleWasher(mesh, addonWireframes, addOnProps);
        }
      }

      return [mesh, dxfData];
    }

    drawRectangleWasher(addOnData, addOnProps) {
      let mesh, dxfData;

      if (addOnData.width > 0 && addOnData.length > 0 && addOnData.height > 0 && addOnProps) {
        let insertionDistance = addOnData.insertionDistance + 0.001;
        let width = addOnData.width;
        let length = addOnData.length;
        let height = addOnData.height;
        let geometry = new this.THREE.BoxGeometry(length, height, width);
        geometry.translate(0, -height / 2, 0);
        let volumeColor = addOnProps.volume.color;
        let lineProps = addOnProps.line;
        let volumeMaterial = this.GraphicsThree3D.getMaterialFromColor(volumeColor);
        volumeMaterial.side = this.THREE.FrontSide;
        mesh = new this.THREE.Mesh(geometry, volumeMaterial); ///

        let partOne = [];
        let partTwo = [];
        let geoVertices = geometry.vertices;
        let firstPart = geoVertices[0].y;
        geoVertices.forEach(vertex => {
          if (vertex.y == firstPart) partOne.push(vertex);else partTwo.push(vertex);
        });
        let wireframeOne = this.drawLineFromPoints(partOne, lineProps, true);
        let wireframeTwo = this.drawLineFromPoints(partTwo, lineProps, true);
        mesh.add(wireframeOne);
        mesh.add(wireframeTwo);
        let edgesLineArr = [];

        for (let i = 0; i < partOne.length; i++) {
          let newLine = this.drawLineFromPoints([partOne[i], partTwo[i]], lineProps);
          edgesLineArr.push(newLine);
        }

        edgesLineArr.forEach(line => mesh.add(line)); ///

        mesh.position.set(0, -insertionDistance, 0);
        mesh.updateMatrix();
        let ptsArr = [];
        let partOneClone = partOne.map(pt => pt.clone().applyMatrix4(mesh.matrix));
        let partTwoClone = partTwo.map(pt => pt.clone().applyMatrix4(mesh.matrix));
        let edgePoints = partOneClone.reduce((ptsArr, pt, i) => {
          ptsArr.push([pt.clone(), partTwoClone[i].clone()]);
          return ptsArr;
        }, []);
        partOneClone.push(partOneClone[0].clone());
        partTwoClone.push(partTwoClone[0].clone());
        ptsArr.push(partOneClone, partTwoClone, ...edgePoints);
        dxfData = {
          points: ptsArr,
          properties: addOnProps.line
        };
      }

      return [mesh, dxfData];
    }

    drawAngleWasherTypeA(addOnData, addOnProps) {
      let mesh, dxfData;

      if (addOnProps && addOnData.length && addOnData.width && addOnData.height && addOnData.normalLength && addOnData.orthogonalLength) {
        let insertionDistance = addOnData.insertionDistance + 0.001;
        let orthogonalLength = addOnData.orthogonalLength;
        let height = addOnData.height;
        let length = addOnData.length;
        let width = addOnData.width;
        let BT = Math.round(Math.sqrt(orthogonalLength / 2 * (orthogonalLength / 2) / 2) * 100) / 100;
        let A = {
          x: -BT,
          y: BT - height
        };
        let B = {
          x: -BT,
          y: -BT
        };
        let C = {
          x: BT,
          y: BT
        };
        let D = {
          x: length - BT,
          y: BT - height
        };
        let pts = [];
        pts.push(new this.THREE.Vector2(A.x, A.y));
        pts.push(new this.THREE.Vector2(B.x, B.y));
        pts.push(new this.THREE.Vector2(C.x, C.y));
        pts.push(new this.THREE.Vector2(D.x, D.y));
        let shape = new this.THREE.Shape(pts);
        let extrudeSettings = {
          depth: width,
          bevelEnabled: false
        };
        let geometry = new this.THREE.ExtrudeGeometry(shape, extrudeSettings);
        let volumeColor = addOnProps.volume.color;
        let lineProps = addOnProps.line;
        let volumeMaterial = this.GraphicsThree3D.getMaterialFromColor(volumeColor);
        mesh = new this.THREE.Mesh(geometry, volumeMaterial); ///

        let pointsArr = pts.map(point => new this.THREE.Vector3(point.x, point.y, width));
        let polygonWirefame = this.makePolygonWireframe(pointsArr, width, lineProps);
        polygonWirefame.forEach(wireframe => wireframe.forEach(line => mesh.add(line))); ///

        mesh.rotation.set(0, -Math.PI / 2, -Math.PI / 4);
        mesh.position.set(width / 2, -insertionDistance, 0);
        mesh.updateMatrix();
        dxfData = this.getAddonDxfData_PolygonWireframe(mesh, polygonWirefame, addOnProps);
      }

      return [mesh, dxfData];
    }

    drawAngleWasherTypeB(addOnData, addOnProps) {
      let mesh, dxfData;

      if (addOnData.height && addOnData.width && addOnData.length && addOnData.normalLength && addOnData.orthogonalLength) {
        let height = addOnData.height;
        let length = addOnData.length;
        let width = addOnData.width;
        let ellipsoidgeometry = new this.THREE.Geometry();
        let latitudeBands = 32,
            longitudeBands = 32,
            a = length / 2,
            b = width / 2,
            c = height,
            size = 1;

        for (let latNumber = 0; latNumber <= latitudeBands; latNumber++) {
          let theta = latNumber * Math.PI / latitudeBands;
          let sinTheta = Math.sin(theta);
          let cosTheta = Math.cos(theta);

          for (let longNumber = 0; longNumber <= longitudeBands; longNumber++) {
            let phi = longNumber * 2 * Math.PI / longitudeBands;
            let sinPhi = Math.sin(phi);
            let cosPhi = Math.cos(phi);
            let x = a * cosPhi * cosTheta;
            let z = b * cosTheta * sinPhi;
            let y = c * sinTheta;
            ellipsoidgeometry.vertices.push(new this.THREE.Vector3(x * size, y * size, z * size));
          }
        }

        for (let latNumber = 0; latNumber < latitudeBands; latNumber++) {
          for (let longNumber = 0; longNumber < longitudeBands; longNumber++) {
            let first = latNumber * (longitudeBands + 1) + longNumber;
            let second = first + longitudeBands + 1;
            ellipsoidgeometry.faces.push(new this.THREE.Face3(first, second, first + 1));
            ellipsoidgeometry.faces.push(new this.THREE.Face3(second, second + 1, first + 1));
          }
        }

        ellipsoidgeometry.computeFaceNormals();
        let orthogonalLength = addOnData.orthogonalLength;
        let normalLength = addOnData.normalLength;
        let normalHeight = Math.sqrt(Math.pow(normalLength, 2) / 2);
        let heightProportion = normalHeight / (height / 2);
        let offsetX = Math.sqrt(Math.pow(orthogonalLength / 2, 2) / 2);
        ellipsoidgeometry.translate(length / 2 - offsetX, -(height / 2) * heightProportion, 0);
        ellipsoidgeometry.rotateZ(-Math.PI / 4);
        ellipsoidgeometry.rotateY(-Math.PI / 2);
        let volumeColor = addOnProps.volume.color;
        let volumeMaterial = this.GraphicsThree3D.getMaterialFromColor(volumeColor);
        mesh = new this.THREE.Mesh(ellipsoidgeometry, volumeMaterial);
        let botCircleGeo = new this.THREE.CircleGeometry(b, 32);
        botCircleGeo.applyMatrix4(new this.THREE.Matrix4().makeScale(1, a / b, 1));
        botCircleGeo.rotateY(Math.PI / 2);
        botCircleGeo.rotateZ(Math.PI / 2);
        botCircleGeo.translate(length / 2 - offsetX, -(height / 2) * heightProportion, 0);
        botCircleGeo.rotateZ(-Math.PI / 4);
        botCircleGeo.rotateY(-Math.PI / 2);
        let botCircle = new this.THREE.Mesh(botCircleGeo, volumeMaterial);
        mesh.add(botCircle);

        if (addOnProps.line) {
          let wireframePoints = this.getEllipsePointsXZ(a, b, 0, {
            x: 0,
            y: 0,
            z: 0
          });
          let wireframe = this.drawLineFromPoints(wireframePoints, addOnProps.line, true);
          wireframe.geometry.rotateZ(Math.PI / 2);
          wireframe.geometry.translate(length / 2 - offsetX, -(height / 2) * heightProportion, 0);
          wireframe.geometry.rotateZ(-Math.PI / 4);
          wireframe.geometry.rotateY(-Math.PI / 2);
          mesh.add(wireframe);
        }

        mesh.updateMatrix();
        let ptsArr = [];
        this.WireframeGenerator.getWireframeLinePairs(ellipsoidgeometry, Math.PI / 360).forEach(([_, ptPairs]) => ptPairs.forEach(pair => {
          ptsArr.push(pair.map(pt => pt.clone().applyMatrix4(mesh.matrix)));
        }));
        dxfData = {
          points: ptsArr,
          properties: addOnProps.line
        };
      }

      return [mesh, dxfData];
    }

    drawFtConnector(addOnProps) {
      // TODO: CLEAN MESS!!!
      let csg = this.GraphicsThree3D.CSG;
      const colorAxis = addOnProps.volume.color;
      const lineProps = addOnProps.line;
      let addonMaterial = this.GraphicsThree3D.getMaterialFromColor(colorAxis);
      addonMaterial.side = this.THREE.FrontSide; // top cyl

      let topCylRadius = 1.25;
      let topCylHeight = 8;
      let topCylPosition = new this.THREE.Vector3(0, 4.2, 0); // bot cyl

      let botCylRadius = 0.75;
      let botCylHeight = 10;
      let botCylPosition = new this.THREE.Vector3(0, -5.8, 0); // box one

      let boxOneWidth = 3;
      let boxOneHeight = 0.2;
      let boxOneDepth = 4;
      let boxOnePosition = new this.THREE.Vector3(0, 0.1, 0); // box two

      let boxTwoWidth = 5;
      let boxTwoHeight = 0.6;
      let boxTwoDepth = 6;
      let boxTwoPosition = new this.THREE.Vector3(0, -0.3, 0); // box three

      let boxThreeWidth = 3;
      let boxThreeHeight = 0.2;
      let boxThreeDepth = 4;
      let boxThreePosition = new this.THREE.Vector3(0, -0.7, 0); // box four

      let boxFourWidth = 3;
      let boxFourHeight = 0.2;
      let boxFourDepth = 4;
      let boxFourPosition = new this.THREE.Vector3(-2.747, -1.431, 0);
      let boxFourRotationZ = 30 * Math.PI / 180; // box five

      let boxFiveWidth = 11;
      let boxFiveHeight = 0.2;
      let boxFiveDepth = 4;
      let boxFivePosition = new this.THREE.Vector3(-1.258, -6.808, 0);
      let boxFiveRotationZ = -60 * Math.PI / 180; // eraser box top

      let eraserBoxTopWidth = 7;
      let eraserBoxTopHeight = 7;
      let eraserBoxTopDepth = 7;
      let eraserBoxTopPosition = new this.THREE.Vector3(2.611, 7.314, 0);
      let eraserBoxTopRotationZ = 30 * Math.PI / 180; // eraser box bot

      let eraserBoxBotWidth = 4;
      let eraserBoxBotHeight = 4;
      let eraserBoxBotDepth = 4;
      let eraserBoxBotPosition = new this.THREE.Vector3(-1.416, -10.393, 0);
      let eraserBoxBotRotationZ = -60 * Math.PI / 180; // eraser cyl top

      let eraserCylTopRadius = 0.75;
      let eraserCylTopHeight = 12;
      let eraserCylTopPosition = new this.THREE.Vector3(0, 5.9, 0); // meshes

      let topCylGeo = new this.THREE.CylinderGeometry(topCylRadius, topCylRadius, topCylHeight, 32);
      let topCyl = new this.THREE.Mesh(topCylGeo);
      topCyl.position.copy(topCylPosition);
      topCyl.updateMatrix();
      let botCylGeo = new this.THREE.CylinderGeometry(botCylRadius, botCylRadius, botCylHeight, 32);
      let botCyl = new this.THREE.Mesh(botCylGeo);
      botCyl.position.copy(botCylPosition);
      botCyl.updateMatrix();
      let boxOneGeo = new this.THREE.BoxGeometry(boxOneWidth, boxOneHeight, boxOneDepth);
      let boxOne = new this.THREE.Mesh(boxOneGeo);
      boxOne.position.copy(boxOnePosition);
      boxOne.updateMatrix();
      let boxTwoGeo = new this.THREE.BoxGeometry(boxTwoWidth, boxTwoHeight, boxTwoDepth);
      let boxTwo = new this.THREE.Mesh(boxTwoGeo);
      boxTwo.position.copy(boxTwoPosition);
      boxTwo.updateMatrix();
      let boxThreeGeo = new this.THREE.BoxGeometry(boxThreeWidth, boxThreeHeight, boxThreeDepth);
      let boxThree = new this.THREE.Mesh(boxThreeGeo);
      boxThree.position.copy(boxThreePosition);
      boxThree.updateMatrix();
      boxFourWidth = 3.4;
      boxFourPosition = new this.THREE.Vector3(-2.747, -1.4, 0);
      let boxFourGeo = new this.THREE.BoxGeometry(boxFourWidth, boxFourHeight, boxFourDepth);
      let boxFour = new this.THREE.Mesh(boxFourGeo);
      boxFour.rotation.z = boxFourRotationZ;
      boxFour.position.copy(boxFourPosition);
      boxFour.updateMatrix();
      boxFivePosition = new this.THREE.Vector3(-1.408, -6.92, 0);
      let boxFiveGeo = new this.THREE.BoxGeometry(boxFiveWidth, boxFiveHeight, boxFiveDepth);
      let boxFive = new this.THREE.Mesh(boxFiveGeo);
      boxFive.rotation.z = boxFiveRotationZ;
      boxFive.position.copy(boxFivePosition);
      boxFive.updateMatrix(); // erasers

      let eraserBoxTopGeo = new this.THREE.BoxGeometry(eraserBoxTopWidth, eraserBoxTopHeight, eraserBoxTopDepth);
      let eraserBoxTop = new this.THREE.Mesh(eraserBoxTopGeo);
      eraserBoxTop.rotation.z = eraserBoxTopRotationZ;
      eraserBoxTop.position.copy(eraserBoxTopPosition);
      eraserBoxTop.updateMatrix();
      eraserBoxBotPosition = new this.THREE.Vector3(-1.5, -10.6, 0);
      let eraserBoxBotGeo = new this.THREE.BoxGeometry(eraserBoxBotWidth, eraserBoxBotHeight, eraserBoxBotDepth);
      let eraserBoxBot = new this.THREE.Mesh(eraserBoxBotGeo);
      eraserBoxBot.rotation.z = eraserBoxBotRotationZ;
      eraserBoxBot.position.copy(eraserBoxBotPosition);
      eraserBoxBot.updateMatrix();
      let eraserCylTopGeo = new this.THREE.CylinderGeometry(eraserCylTopRadius, eraserCylTopRadius, eraserCylTopHeight, 32);
      let eraserCylTop = new this.THREE.Mesh(eraserCylTopGeo);
      eraserCylTop.position.copy(eraserCylTopPosition);
      eraserCylTop.updateMatrix(); // wireframes

      let boxOneVertices = boxOneGeo.clone().applyMatrix4(boxOne.matrix).vertices;
      boxOneVertices = [boxOneVertices[0], boxOneVertices[2], boxOneVertices[7], boxOneVertices[5]];
      let boxTwoVertices = boxTwoGeo.clone().applyMatrix4(boxTwo.matrix).vertices;
      boxTwoVertices = [boxTwoVertices[0], boxTwoVertices[2], boxTwoVertices[7], boxTwoVertices[5]];
      let boxThreeVertices = boxThreeGeo.clone().applyMatrix4(boxThree.matrix).vertices;
      let boxFourVertices = boxFourGeo.clone().applyMatrix4(boxFour.matrix).vertices;
      let polyVertices = [boxThreeVertices[0], boxThreeVertices[2], new this.THREE.Vector3(-1.51, -0.8, 2.002), new this.THREE.Vector3(-3.997, -2.2365, 2.002), new this.THREE.Vector3(1.432, -11.634, 2.002), new this.THREE.Vector3(1.254, -11.736, 2.002), boxFourVertices[5], new this.THREE.Vector3(-1.565, -0.6, 2.002)]; // let botCylVertices = botCylGeo.clone().applyMatrix4(botCyl.matrix).vertices.splice(0, 32);
      // let topCylVertices = topCylGeo.clone().applyMatrix4(topCyl.matrix).vertices.splice(32, 32);
      // let topCutMiddleVertices = this.getEllipsePointsXZ(1.5, 0.75, 30*Math.PI/180, {x: 0, y: 4.85, z: 0});

      let topCutOuterVertices = this.getEllipsePointsXZ(2.5, 1.25, 30 * Math.PI / 180, {
        x: 0,
        y: 4.85,
        z: 0
      });
      let botCutVertices = this.getEllipsePointsXZ(1.5, 0.75, 30 * Math.PI / 180, {
        x: 0,
        y: -9.15,
        z: 0
      });
      let botCutWireframes = this.drawLineFromPoints(botCutVertices, lineProps, true);
      let topCutOuterWireframes = this.drawLineFromPoints(topCutOuterVertices, lineProps, true); // let topCutMiddleWireframes = this.drawLineFromPoints(topCutMiddleVertices, lineProps, true);
      // let topCylBotWireframes = this.drawLineFromPoints(topCylVertices, lineProps, true);
      // let botCylTopWireframes = this.drawLineFromPoints(botCylVertices, lineProps, true);

      let boxOneWireframes = this.makePolygonWireframe(boxOneVertices, boxOneDepth, lineProps);
      let boxTwoWireframes = this.makePolygonWireframe(boxTwoVertices, boxTwoDepth, lineProps);
      let botPolyWireframes = this.makePolygonWireframe(polyVertices, boxOneDepth, lineProps); // boolean operations
      // TODO: Optimize!!!

      let eraserBoxTopCSG = csg.fromMesh(eraserBoxTop);
      let topCylCSG = csg.fromMesh(topCyl);
      topCylCSG = topCylCSG.subtract(eraserBoxTopCSG);
      topCyl = csg.toMesh(topCylCSG, new this.THREE.Matrix4());
      let eraserBoxBotCSG = csg.fromMesh(eraserBoxBot);
      let botCylCSG = csg.fromMesh(botCyl);
      botCylCSG = botCylCSG.subtract(eraserBoxBotCSG);
      botCyl = csg.toMesh(botCylCSG, new this.THREE.Matrix4());
      let eraserCylTopCSG = csg.fromMesh(eraserCylTop);
      topCylCSG = csg.fromMesh(topCyl);
      let boxOneCSG = csg.fromMesh(boxOne);
      let boxTwoCSG = csg.fromMesh(boxTwo);
      topCylCSG = topCylCSG.subtract(eraserCylTopCSG);
      boxOneCSG = boxOneCSG.subtract(eraserCylTopCSG);
      boxTwoCSG = boxTwoCSG.subtract(eraserCylTopCSG);
      topCyl = csg.toMesh(topCylCSG, new this.THREE.Matrix4());
      boxOne = csg.toMesh(boxOneCSG, new this.THREE.Matrix4());
      boxTwo = csg.toMesh(boxTwoCSG, new this.THREE.Matrix4());
      topCylCSG = csg.fromMesh(topCyl);
      botCylCSG = csg.fromMesh(botCyl);
      boxOneCSG = csg.fromMesh(boxOne);
      boxTwoCSG = csg.fromMesh(boxTwo);
      let boxThreeCSG = csg.fromMesh(boxThree);
      let boxFourCSG = csg.fromMesh(boxFour);
      let boxFiveCSG = csg.fromMesh(boxFive);
      let unionCSG = topCylCSG.union(boxOneCSG);
      unionCSG = unionCSG.union(boxTwoCSG);
      unionCSG = unionCSG.union(boxThreeCSG);
      unionCSG = unionCSG.union(botCylCSG);
      unionCSG = unionCSG.union(boxFourCSG);
      unionCSG = unionCSG.union(boxFiveCSG);
      let addon = csg.toMesh(unionCSG, new this.THREE.Matrix4());
      boxOneWireframes.forEach(wireframe => wireframe.forEach(line => addon.add(line)));
      boxTwoWireframes.forEach(wireframe => wireframe.forEach(line => addon.add(line)));
      botPolyWireframes.forEach(wireframe => wireframe.forEach(line => addon.add(line))); // addon.add(botCylTopWireframes);
      // addon.add(topCylBotWireframes);
      // addon.add(topCutMiddleWireframes);

      addon.add(topCutOuterWireframes);
      addon.add(botCutWireframes);
      addon.rotation.y = -Math.PI / 2;
      addon.material = addonMaterial;
      addon.updateMatrix();
      let ptsArr = [];
      let ptPairs_TopCyl = this.WireframeGenerator.getRegularWireframePointPairs(topCyl, Math.PI / 360);
      let ptPairs_BotCyl = this.WireframeGenerator.getRegularWireframePointPairs(botCyl, Math.PI / 360);
      ptPairs_TopCyl.forEach(pair => ptsArr.push(pair.map(pt => pt.clone().applyMatrix4(addon.matrix))));
      ptPairs_BotCyl.forEach(pair => ptsArr.push(pair.map(pt => pt.clone().applyMatrix4(addon.matrix))));
      [boxOneWireframes, boxTwoWireframes, botPolyWireframes].forEach(wireframe => wireframe.forEach(lineArr => lineArr.forEach(line => {
        let pts = this.utils.getVerticesFromBufferGeometry(line.geometry);
        if (line.type === "LineLoop") pts.push(pts[0].clone());
        pts.forEach(pt => pt.applyMatrix4(addon.matrix));
        ptsArr.push(pts);
      })));
      let dxfData = {
        points: ptsArr,
        properties: addOnProps.line
      };
      return [addon, dxfData];
    }

    makePolygonWireframe(pointsArr, depth, lineProps) {
      let wireframes = [];
      let partOne = pointsArr;
      let partTwo = pointsArr.map(point => point.clone().sub(new this.THREE.Vector3(0, 0, depth)));
      let wireframeOne = this.drawLineFromPoints(partOne, lineProps, true);
      wireframes.push([wireframeOne]);
      let wireframeTwo = this.drawLineFromPoints(partTwo, lineProps, true);
      wireframes.push([wireframeTwo]);
      let edgesLineArr = [];

      for (let i = 0; i < partOne.length; i++) {
        let newLine = this.drawLineFromPoints([partOne[i], partTwo[i]], lineProps);
        edgesLineArr.push(newLine);
      }

      wireframes.push(edgesLineArr);
      return wireframes;
    }

    drawAxis(axisGeo, axisPointGeo, axisMat, axisPointMat) {
      let axis = new this.THREE.Object3D();
      let axisMesh, axisPointMesh;
      if (axisGeo) axisMesh = new this.THREE.Mesh(axisGeo, axisMat);
      if (axisPointGeo) axisPointMesh = new this.THREE.Mesh(axisPointGeo, axisPointMat);
      if (axisGeo) axisMesh.renderOrder = -6;
      if (axisPointGeo) axisPointMesh.renderOrder = -6;
      if (axisMesh) axis.add(axisMesh);
      if (axisPointMesh) axis.add(axisPointMesh);
      return axis;
    }

    getMaterial(properties, target) {
      let jsonColor;

      if (target == "axis") {
        jsonColor = properties.fastener.axis.line.color;
      } else if (target == "tipSide") {
        jsonColor = properties.fastener.tipSide.volume.color;
      } else {
        jsonColor = properties.fastener.headSide.volume.color;
      }

      let volumeColor = "rgb(" + jsonColor.red + "," + jsonColor.green + "," + jsonColor.blue + ")";
      let transparency = jsonColor.alpha / 255;
      let material = new this.THREE.MeshBasicMaterial({
        color: volumeColor,
        opacity: transparency,
        transparent: true,
        side: this.THREE.DoubleSide
      });
      return material;
    } // radiuses are for 2D


    makeElipsoidGeo(radiusX, radiusY, height) {
      let geometry = new this.THREE.Geometry();
      let latitudeBands = 32,
          longitudeBands = 32,
          a = radiusX,
          b = radiusY,
          c = height,
          size = 1;

      for (let latNumber = 0; latNumber <= latitudeBands; latNumber++) {
        let theta = latNumber * Math.PI / latitudeBands;
        let sinTheta = Math.sin(theta);
        let cosTheta = Math.cos(theta);

        for (let longNumber = 0; longNumber <= longitudeBands; longNumber++) {
          let phi = longNumber * 2 * Math.PI / longitudeBands;
          let sinPhi = Math.sin(phi);
          let cosPhi = Math.cos(phi);
          let x = a * cosPhi * cosTheta;
          let z = b * cosTheta * sinPhi;
          let y = c * sinTheta;
          geometry.vertices.push(new this.THREE.Vector3(x * size, y * size, z * size));
        }
      }

      for (let latNumber = 0; latNumber < latitudeBands; latNumber++) {
        for (let longNumber = 0; longNumber < longitudeBands; longNumber++) {
          let first = latNumber * (longitudeBands + 1) + longNumber;
          let second = first + longitudeBands + 1;
          geometry.faces.push(new this.THREE.Face3(first, second, first + 1));
          geometry.faces.push(new this.THREE.Face3(second, second + 1, first + 1));
        }
      }

      geometry.computeFaceNormals();
      return geometry;
    } // Geometries


    getAxisGeo(axisData, axisProps) {
      const PRECISION = 8;
      let axisLength = axisData.length;
      let axisWidth = axisProps.line.thickness / 2;
      let axisGeo = new this.THREE.CylinderBufferGeometry(axisWidth, axisWidth, axisLength, PRECISION);
      axisGeo.translate(0, -axisLength / 2, 0);
      return axisGeo;
    }

    getAxisPointGeo(axisProps) {
      const PRECISION = 8;
      let pointRadius = axisProps.size / 2;
      let pointGeo = new this.THREE.SphereBufferGeometry(pointRadius, PRECISION, PRECISION);
      return pointGeo;
    }
    /**
     * Head Types
     * 1: Countersunk
     * 2: Cylinder
     * 3: Lentil
     * 4: Disk Flat
     * 5: Disk Bottom Flat
     * 6: Disk Top Flat
     * 7: polygonWithoutWasher
     * 8: polygonWithWasher
     */


    getFastenerHeadGeoCountersunk(topRadius, bottomRadius, height, radialSegments) {
      let headGeo = new this.THREE.CylinderGeometry(topRadius, bottomRadius, height, radialSegments);
      headGeo.translate(0, -height / 2, 0);
      return headGeo;
    }

    getFastenerHeadGeoCylinder(topRadius, height, radialSegments) {
      let headGeo = new this.THREE.CylinderGeometry(topRadius, topRadius, height, radialSegments);
      headGeo.translate(0, -height / 2, 0);
      return headGeo;
    }

    getFastenerHeadGeoLentil(topRadius, height, heightFactor, radialSegments) {
      let topHeight = height * heightFactor;
      let botHeight = height - topHeight; // let headGeoTop = this.makeElipsoidGeo(topRadius, topRadius, topHeight);

      let headGeoTop = new this.THREE.SphereGeometry(topRadius, 24, 12);
      headGeoTop.applyMatrix4(new this.THREE.Matrix4().makeScale(1, topHeight / topRadius, 1));
      let headGeoBot = new this.THREE.CylinderGeometry(topRadius, topRadius, botHeight, radialSegments);
      headGeoTop.translate(0, botHeight, 0);
      headGeoBot.translate(0, botHeight / 2, 0);
      return [headGeoTop, headGeoBot];
    }

    getFastenerHeadGeoDiskFlat(topRadius, height, radialSegments) {
      let headGeo = new this.THREE.CylinderGeometry(topRadius, topRadius, height, radialSegments);
      headGeo.translate(0, height / 2, 0);
      return headGeo;
    }

    getFastenerHeadGeoDiskBottomFlat(topRadius, bottomRadius, topDiameterFactor, topDiameter, height, heightFactor, radialSegments) {
      let topHeight = height * heightFactor;
      let botHeight = height - topHeight;
      let headGeoTop = new this.THREE.CylinderGeometry(topRadius, (topDiameter + 2 * topDiameterFactor * topDiameter) / 2, topHeight, radialSegments);
      let headGeoBot = new this.THREE.CylinderGeometry(bottomRadius, bottomRadius, botHeight, radialSegments);
      headGeoTop.isTypeFiveTop = true;
      headGeoBot.isTypeSixBottom = true;
      headGeoTop.translate(0, botHeight + topHeight / 2, 0);
      headGeoBot.translate(0, botHeight / 2, 0);
      return [headGeoTop, headGeoBot];
    }

    getFastenerHeadGeoPolyWithoutWasher(wrenchSize, numOfEdges, height) {
      wrenchSize /= 2;
      let angle = (numOfEdges - 2) * 180 / numOfEdges / 2;
      angle *= Math.PI / 180;
      let radius = wrenchSize / Math.sin(angle);
      let headGeo = new this.THREE.CylinderGeometry(radius, radius, height, numOfEdges);
      headGeo.rotateY(angle / 2);
      headGeo.translate(0, height / 2, 0);
      headGeo.isPolygon = true;
      return headGeo;
    }

    getFastenerHeadGeoPolyWithWasher(wrenchSize, numOfEdges, height, heightFactor, topRadius) {
      wrenchSize /= 2;
      let angle = (numOfEdges - 2) * 180 / numOfEdges / 2;
      angle *= Math.PI / 180;
      let radius = wrenchSize / Math.sin(angle);
      let botHeight = height * heightFactor;
      let topHeight = height - botHeight;
      let headGeoTop = new this.THREE.CylinderGeometry(radius, radius, topHeight, numOfEdges);
      headGeoTop.rotateY(angle / 2);
      headGeoTop.translate(0, topHeight / 2 + botHeight, 0);
      headGeoTop.isPolygon = true;
      let headGeoBot = new this.THREE.CylinderGeometry(topRadius, topRadius, botHeight, 32);
      headGeoBot.translate(0, botHeight / 2, 0);
      return [headGeoTop, headGeoBot];
    }

    makeHeadWireframe(geometry, lineProps, drawBottomFrameHead = true) {
      let wireframes = [];

      if (geometry) {
        if (geometry.length > 0) {
          geometry.forEach(geo => {
            let newWireframes = this.makeHeadWireframe(geo, lineProps, drawBottomFrameHead);
            wireframes.push(...newWireframes);
          });
        } else {
          if (geometry.type == "CylinderGeometry") {
            let drawTopFrame = true;
            let drawBotFrame = drawBottomFrameHead;
            if (geometry.isTypeSixBottom) drawTopFrame = false;
            if (geometry.isAddon) drawBotFrame = true;
            if (geometry.isTypeSixTop) drawBotFrame = false;
            if (geometry.isTypeFiveBottom) drawTopFrame = false;
            if (geometry.isTypeFiveTop) drawBotFrame = false;
            let drawEdges = geometry.isPolygon ? true : false;
            let topBotWireframes = this.drawDoublesideFrame(geometry, lineProps, drawTopFrame, drawBotFrame, drawEdges);
            wireframes.push(...topBotWireframes);
          }
        }
      }

      return wireframes;
    }

    getFastenerHeadGeo(headData) {
      if (headData) {
        let RADIAL_SEGMENTS = 32;
        let headType = headData.type;
        let topDiameter = headData.topDiameter.value;
        let topDiameterFactor = headData.topDiameter.factor;
        let bottomDiameter = headData.bottomDiameter.value;
        let bottomDiameterFactor = headData.bottomDiameter.factor;
        let topRadius = topDiameter / 2;
        let bottomRadius = bottomDiameter / 2;
        let height = headData.height.value;
        let heightFactor = headData.height.factor;
        let wrenchSize = headData.wrenchSize;
        let numOfEdges = headData.numberOfEdges;
        let headGeo;
        let headGeoTop, headGeoBot;

        if (headType == 1) {
          if (topRadius == 0) topRadius = 0.001;
          if (bottomRadius == 0) bottomRadius = 0.001;
          headGeo = this.getFastenerHeadGeoCountersunk(topRadius, bottomRadius, height, RADIAL_SEGMENTS);
        } else if (headType == 2) {
          headGeo = this.getFastenerHeadGeoCylinder(topRadius, height, RADIAL_SEGMENTS);
        } else if (headType == 3) {
          [headGeoTop, headGeoBot] = this.getFastenerHeadGeoLentil(topRadius, height, heightFactor, RADIAL_SEGMENTS);
          headGeo = [headGeoTop, headGeoBot];
        } else if (headType == 4) {
          headGeo = this.getFastenerHeadGeoDiskFlat(topRadius, height, RADIAL_SEGMENTS);
        } else if (headType == 5) {
          [headGeoTop, headGeoBot] = this.getFastenerHeadGeoDiskBottomFlat(topRadius, bottomRadius, topDiameterFactor, topDiameter, height, heightFactor, RADIAL_SEGMENTS);
          headGeo = [headGeoTop, headGeoBot];
        } else if (headType == 6) {
          let topHeight = height * (1 - heightFactor);
          let botHeight = height * heightFactor;
          headGeoTop = new this.THREE.CylinderGeometry(topRadius, topRadius, topHeight, 32);
          headGeoTop.isTypeSixTop = true;
          headGeoBot = new this.THREE.CylinderGeometry((bottomDiameter + 2 * bottomDiameterFactor * bottomDiameter) / 2, bottomRadius, botHeight, RADIAL_SEGMENTS);
          headGeoBot.isTypeSixBottom = true;
          headGeoTop.translate(0, -topHeight / 2, 0);
          headGeoBot.translate(0, -topHeight - botHeight / 2, 0);
          headGeo = [headGeoTop, headGeoBot];
        } else if (headType == 7) {
          headGeo = this.getFastenerHeadGeoPolyWithoutWasher(wrenchSize, numOfEdges, height);
        } else if (headType == 8) {
          [headGeoTop, headGeoBot] = this.getFastenerHeadGeoPolyWithWasher(wrenchSize, numOfEdges, height, heightFactor, topRadius);
          headGeo = [headGeoTop, headGeoBot];
        }

        return headGeo;
      }
    }
    /**
     * Shank Types
     * 1: Circle
     * 2: Square
     */


    getFastenerShankGeo(segments, shankData, fastener) {
      let geometries = [];

      if (shankData) {
        let diameter = shankData.size;
        segments.forEach(segment => {
          let length = segment[1] - segment[0];
          let newGeo;
          if (shankData.type == 1) newGeo = new this.THREE.CylinderGeometry(diameter / 2, diameter / 2, length, 32);else if (shankData.type == 2) newGeo = new this.THREE.BoxGeometry(diameter, length, diameter);else console.log("Shank type is undefined");

          if (newGeo) {
            if (segment[2]) newGeo.isTipSide = true;
            newGeo.translate(0, -length / 2 - segment[0], 0);
            geometries.push(newGeo);
          }
        });
      } else {
        geometries = null;
      }

      return geometries;
    }

    makeTipWireframe(tipData, geometry, lineProps, drawTopTip = true, drawBotTip = true, drawEdgesTip = false) {
      // TODO: Refactor/Clean code.
      let wireframes = [];

      if (tipData.type == 1) {
        let geoVertices = [...geometry.vertices];
        geoVertices.splice(geoVertices.length - 2, 2);

        if (drawTopTip) {
          let wireframe = this.drawLineFromPoints(geoVertices, lineProps, true);
          wireframes.push([wireframe]);
        }
      } else if (tipData.type == 2) {
        if (geometry.parameters.radiusBottom) {
          wireframes = this.drawDoublesideFrame(geometry, lineProps, drawTopTip);
        } else {
          let geoVertices = [...geometry.vertices];
          geoVertices.splice(geoVertices.length - 2, 2);
          let wireframe = this.drawLineFromPoints(geoVertices, lineProps, true);
          wireframes.push([wireframe]);
        }
      } else if (tipData.type == 3) {
        let depth = geometry.parameters.options.depth;
        let partOne = [...geometry.vertices].splice(0, 3);
        let partTwo = [];
        partOne.forEach(vertex => partTwo.push(vertex.clone()));
        partTwo.forEach(vertex => vertex.setComponent(2, vertex.z + depth));
        let wireframesOne = this.drawLineFromPoints(partOne, lineProps, drawTopTip ? true : false);
        wireframes.push([wireframesOne]);
        let wireframesTwo = this.drawLineFromPoints(partTwo, lineProps, drawTopTip ? true : false);
        wireframes.push([wireframesTwo]);

        if (drawTopTip) {
          let edgesLineArr = [];

          for (let i = 0; i < partOne.length; i++) {
            let newLine = this.drawLineFromPoints([partOne[i], partTwo[i]], lineProps);
            edgesLineArr.push(newLine);
          }

          wireframes.push(edgesLineArr);
        } else {
          let edgesLineArr = [];
          let newLine = this.drawLineFromPoints([partOne[1], partTwo[1]], lineProps);
          edgesLineArr.push(newLine);
          wireframes.push(edgesLineArr);
        }
      } else if (tipData.type == 4) {
        let depth = geometry.parameters.options.depth;
        let partOne = [];

        if (geometry.vertices[3].z > 0) {
          partOne = [...geometry.vertices].splice(0, 3);
        } else {
          partOne = [...geometry.vertices].splice(0, 4);
        }

        let partTwo = [];
        partOne.forEach(vertex => partTwo.push(vertex.clone()));
        partTwo.forEach(vertex => vertex.setComponent(2, vertex.z + depth));
        let wireframesOne = this.drawLineFromPoints(partOne, lineProps, drawTopTip ? true : false);
        wireframes.push([wireframesOne]);
        let wireframesTwo = this.drawLineFromPoints(partTwo, lineProps, drawTopTip ? true : false);
        wireframes.push([wireframesTwo]);

        if (drawTopTip) {
          let edgesLineArr = [];

          for (let i = 0; i < partOne.length; i++) {
            let newLine = this.drawLineFromPoints([partOne[i], partTwo[i]], lineProps);
            edgesLineArr.push(newLine);
          }

          wireframes.push(edgesLineArr);
        } else {
          let edgesLineArr = [];

          for (let i = 1; i < partOne.length - 1; i++) {
            let newLine = this.drawLineFromPoints([partOne[i], partTwo[i]], lineProps);
            edgesLineArr.push(newLine);
          }

          wireframes.push(edgesLineArr);
        }
      } else if (tipData.type == 5) {
        let geoVertices = [...geometry.vertices].splice(0, 4);

        if (drawTopTip) {
          let wireframe = this.drawLineFromPoints(geoVertices, lineProps, true);
          wireframes.push([wireframe]);
        }

        let pointVertex = geometry.vertices[4];
        let pointArr = [];
        geoVertices.forEach(vertex => {
          let newLine = this.drawLineFromPoints([pointVertex, vertex], lineProps);
          pointArr.push(newLine);
        });
        wireframes.push(pointArr);
      } else if (tipData.type == 6) {
        if (geometry.parameters.radiusBottom) {
          wireframes = this.drawDoublesideFrame(geometry, lineProps, drawTopTip, true, true);
        } else {
          let geoVertices = [...geometry.vertices];
          geoVertices.splice(geoVertices.length - 2, 2);
          let pointVertex = geometry.vertices[4];
          let pointArr = [];
          geoVertices.forEach(vertex => {
            let newLine = this.drawLineFromPoints([pointVertex, vertex], lineProps);
            pointArr.push(newLine);
          });
          wireframes.push(pointArr);

          if (drawTopTip) {
            let wireframe = this.drawLineFromPoints(geoVertices, lineProps, true);
            wireframes.push([wireframe]);
          }
        }
      }

      return wireframes;
    }
    /**
     * Tip Types
     * 1: Circle Pointed
     * 2: Circle Blunt
     * 3: Square Half Pointed
     * 4: Square Half Blunt
     * 5: Square Full Pointed
     * 6: Square Full Blunt
     */


    getFastenerTipGeo(tipData, tipDiameter, fastener, axisLength) {
      let tipGeo;
      let height = tipData ? tipData.height : 0;
      let tipRadius = tipDiameter / 2;

      if (height > 0) {
        if (tipData.type == 1) {
          tipGeo = new this.THREE.CylinderGeometry(tipRadius, 0, height, 32);
        } else if (tipData.type == 2) {
          let bottomRadius = tipRadius - height;
          if (bottomRadius < 0) bottomRadius = 0;
          tipGeo = new this.THREE.CylinderGeometry(tipRadius, bottomRadius, height, 32);
        } else if (tipData.type == 3) {
          let shape = new this.THREE.Shape();
          shape.moveTo(0, -height / 2);
          shape.lineTo(tipRadius, height / 2);
          shape.lineTo(-tipRadius, height / 2);
          shape.lineTo(0, -height / 2);
          let extrudeSettings = {
            steps: 32,
            depth: 2 * tipRadius,
            bevelEnabled: false
          };
          tipGeo = new this.THREE.ExtrudeGeometry(shape, extrudeSettings);
          tipGeo.translate(0, 0, -fastener.components.shank.size / 2);
        } else if (tipData.type == 4) {
          let bottomRadius = (tipDiameter - 2 * height) / 2;
          if (bottomRadius < 0) bottomRadius = 0;
          let shape = new this.THREE.Shape();
          shape.moveTo(bottomRadius, -height / 2);
          shape.lineTo(tipRadius, height / 2);
          shape.lineTo(-tipRadius, height / 2);
          shape.lineTo(-bottomRadius, -height / 2);
          shape.lineTo(bottomRadius, -height / 2);
          let extrudeSettings = {
            steps: 32,
            depth: 2 * tipRadius,
            bevelEnabled: false
          };
          tipGeo = new this.THREE.ExtrudeGeometry(shape, extrudeSettings);
          tipGeo.translate(0, 0, -fastener.components.shank.size / 2);
        } else if (tipData.type == 5) {
          tipRadius = Math.sqrt(2 * Math.pow(tipDiameter, 2)) / 2;
          tipGeo = new this.THREE.CylinderGeometry(tipRadius, 0, height, 4);
          tipGeo.rotateY(Math.PI / 4);
        } else if (tipData.type == 6) {
          let topRadius = Math.sqrt(2 * Math.pow(tipRadius, 2));
          let correctedRadius = tipRadius - height > 0 ? tipRadius - height : 0;
          let bottomRadius = Math.sqrt(2 * Math.pow(correctedRadius, 2));
          tipGeo = new this.THREE.CylinderGeometry(topRadius, bottomRadius, height, 4);
          tipGeo.rotateY(Math.PI / 4);
        } else {
          tipGeo = null;
        }
      } else {
        tipGeo = null;
      }

      if (tipGeo) {
        tipGeo.computeBoundingBox();
        tipGeo.center();
        tipGeo.translate(0, height / 2 - axisLength, 0);
      }

      return tipGeo;
    }
    /**
     * Thread Types
     * 1: with pitch
     * 2: without pitch
     */


    getSpiralAndCylGeo(threadSegments, fastenerProps) {
      let cylGeometries = [];
      let spiralGeometries = [];
      let wireframePoints = [];
      let cylWireframes = [];
      threadSegments.forEach(segment => {
        let threadData = segment[2];

        if (threadData.pitch > 0) {
          let threadDiameter = threadData.threadDiameter;
          let coreDiameter = threadData.coreDiameter;
          let pitch = threadData.pitch;
          let height = threadData.height;
          let length = segment[1] - segment[0];

          if (threadDiameter && length > 0 && threadDiameter > coreDiameter) {
            let spiral = new Spiral(coreDiameter / 2, length, pitch, (threadDiameter - coreDiameter) / 2, height, new this.THREE.MeshBasicMaterial(), this);
            let cylGeo = spiral.getCylGeo();
            let [spiralGeo, newWireframePoints] = spiral.getSpiralGeo();
            wireframePoints.push(newWireframePoints);
            cylGeo.translate(0, -segment[0], 0);
            spiralGeo.translate(0, -segment[0], 0); ///
            // let lineProps = fastenerProps.fastener.headSide.line;
            // let cylWireframe = this.makeHeadWireframe(cylGeo, lineProps);
            // cylWireframes.push(cylWireframe);
            ///

            cylGeometries.push(cylGeo);
            spiralGeometries.push(spiralGeo);
          }
        }
      });
      let firstCyl, firstSpiral;

      if (cylGeometries.length > 0 && spiralGeometries.length > 0) {
        firstCyl = cylGeometries.splice(0, 1)[0];
        cylGeometries.forEach(geo => firstCyl.merge(geo));
        firstSpiral = spiralGeometries.splice(0, 1)[0];
        spiralGeometries.forEach(geo => firstSpiral.merge(geo));
      }

      return [firstCyl, firstSpiral, wireframePoints, cylWireframes];
    }

    getThreadWithoutPitchGeo(threadSegments, fastenerProps) {
      let geometries = [];
      let wireframePoints = [];
      let coreGeometries = [];
      let coreWireframes = [];
      threadSegments.forEach(segment => {
        let insertionDistance = segment[0];
        let length = segment[1] - segment[0];
        let threadsData = segment[2];
        let coreRadius = threadsData.coreDiameter / 2;
        let threadRadius = threadsData.threadDiameter / 2;
        let height = threadsData.height;
        let distance = threadsData.distance;
        let midGeoHeight = distance - height;
        let threadPartHeight = height / 2; // top and bot geometry height

        let pitch = threadsData.pitch;
        if (threadPartHeight == 0) threadPartHeight = 0.001;
        if (midGeoHeight == 0) midGeoHeight = 0.001;

        if (pitch == 0 && length > 0 && (threadPartHeight > 0 || midGeoHeight > 0) && threadRadius > 0 && coreRadius >= 0 && threadRadius > coreRadius) {
          if (coreRadius == 0) coreRadius = 0.01;
          if (threadRadius == 0) threadRadius = 0.01;
          let coreGeo = new this.THREE.CylinderGeometry(coreRadius, coreRadius, length, 32);
          coreGeo.translate(0, -length / 2 - insertionDistance, 0); ///
          // let lineProps = fastenerProps.fastener.headSide.line;
          // let coreWireframe = this.makeHeadWireframe(coreGeo, lineProps);
          // coreWireframes.push(coreWireframe);
          ///

          coreGeometries.push(coreGeo);
          let wireframePointsOuter = [];
          let wireframePointsInner = [];
          let startAngle = 0;
          let endAngle = 2 * Math.PI;
          let step = endAngle / 32;

          while (startAngle < endAngle) {
            let widthCoeff = Math.cos(startAngle);
            let depthCoeff = Math.sin(startAngle);
            let outerPoint = new this.THREE.Vector3(threadRadius * widthCoeff, 0, threadRadius * depthCoeff);
            let innerPoint = new this.THREE.Vector3(coreRadius * widthCoeff, 0, coreRadius * depthCoeff);
            startAngle += step;
            wireframePointsOuter.push(outerPoint);
            wireframePointsInner.push(innerPoint);
          }
          /*
            turns
            0: topGeo
            1: botGeo
            2: midGeo
          */


          let turn = 0;
          let distanceToLoop = segment[0] + length;

          while (insertionDistance < distanceToLoop) {
            if (turn == 0) {
              if (threadPartHeight > 0) {
                let topGeo = new this.THREE.CylinderGeometry(coreRadius, threadRadius, threadPartHeight, 32, 1, true);
                topGeo.translate(0, -threadPartHeight / 2 - insertionDistance, 0); ///

                let wireframeNewPoints = [...wireframePointsInner].map(point => point = point.clone().setComponent(1, -insertionDistance));
                wireframePoints.push(wireframeNewPoints);
                let wireframeNewPointsOuter = [...wireframePointsOuter].map(point => point = point.clone().setComponent(1, -insertionDistance - threadPartHeight));
                wireframePoints.push(wireframeNewPointsOuter); ///

                insertionDistance += threadPartHeight;
                geometries.push(topGeo);
              }
            } else if (turn == 1) {
              if (threadPartHeight > 0) {
                let botGeo = new this.THREE.CylinderGeometry(threadRadius, coreRadius, threadPartHeight, 32, 1, true);
                botGeo.translate(0, -threadPartHeight / 2 - insertionDistance, 0); ///

                let wireframeNewPoints = [...wireframePointsInner].map(point => point = point.clone().setComponent(1, -insertionDistance - threadPartHeight));
                wireframePoints.push(wireframeNewPoints); ///

                insertionDistance += threadPartHeight;
                geometries.push(botGeo);
              }
            } else {
              if (midGeoHeight > 0) {
                // let midGeo = new this.THREE.CylinderGeometry(coreRadius, coreRadius, midGeoHeight, 32, 1, true);
                // midGeo.translate(0, -midGeoHeight/2 - insertionDistance, 0);
                // geometries.push(midGeo);
                insertionDistance += midGeoHeight;
              }
            }

            turn++;
            if (turn > 2) turn = 0;
          }
        }
      });
      let geometry = geometries.splice(0, 1)[0];
      geometries.forEach(geo => geometry.merge(geo));
      let coreGeometry = coreGeometries.splice(0, 1)[0];
      coreGeometries.forEach(geo => coreGeometry.merge(geo));
      return [geometry, coreGeometry, wireframePoints, coreWireframes];
    }

    drawFastenerHead_1_Countersunk(headGeo, fastenerMaterial, headData) {
      let head;

      if (headData.height.value > 0 && (headData.topDiameter.value > 0 || headData.bottomDiameter.value > 0)) {
        head = new this.THREE.Mesh(headGeo, fastenerMaterial);
      } else if (headData.type == 2 && headData.topDiameter.value > 0 && headData.height.value > 0) {
        head = new this.THREE.Mesh(headGeo, fastenerMaterial);
      } else {
        head = null;
      }

      return head;
    }

    drawFastenerHead_4_DiscFlat(geometry, material, headData) {
      let cylinder;

      if (headData.topDiameter.value > 0 && headData.height.value > 0) {
        cylinder = new this.THREE.Mesh(geometry, material);
      } else {
        cylinder = null;
      }

      return cylinder;
    }

    drawFastenerHead_7_PolygonWithoutWasher(geometry, material, headData) {
      let hexagonMesh;

      if (headData.height.value > 0 && headData.wrenchSize > 0 && headData.numberOfEdges > 2) {
        hexagonMesh = new this.THREE.Mesh(geometry, material);
      } else {
        hexagonMesh = null;
      }

      return hexagonMesh;
    }

    drawFastenerHead_8_PolygonWithWasher(geometry, material, headData) {
      let polyWithWasher;

      if (headData.topDiameter.value > 0 && headData.height.value > 0 && headData.height.factor > 0 && headData.height.factor < 1 && headData.wrenchSize > 0 && headData.wrenchSize < headData.topDiameter.value && headData.numberOfEdges > 2) {
        let [hexExtr, cylGeo] = geometry;
        let hexagonMesh = new this.THREE.Mesh(hexExtr, material);
        let cylinder = new this.THREE.Mesh(cylGeo, material);
        let hexagonCSG = this.CSG.fromMesh(hexagonMesh);
        let cylinderMesh = this.CSG.fromMesh(cylinder);
        let unionCSG = hexagonCSG.union(cylinderMesh);
        polyWithWasher = this.CSG.toMesh(unionCSG, new this.THREE.Matrix4());
        polyWithWasher.material = material;
      } else {
        polyWithWasher = null;
      }

      return polyWithWasher;
    }

    drawFastenerHead_5_DiscBottomFlat(geometry, material, headData) {
      let discBottomFlat;

      if (headData.topDiameter.value > 0 && headData.topDiameter.factor > 0 && 2 * (headData.topDiameter.factor * headData.topDiameter.value) + headData.topDiameter.value < headData.bottomDiameter.value && headData.bottomDiameter.value > 0 && headData.height.value > 0 && headData.height.factor > 0 && headData.height.factor < 1) {
        let [geometry1, geometry2] = geometry;
        let cylinderMesh = new this.THREE.Mesh(geometry1, material);
        let cylinder = new this.THREE.Mesh(geometry2, material);
        let csgOne = this.CSG.fromMesh(cylinderMesh);
        let csgTwo = this.CSG.fromMesh(cylinder);
        let unionCSG = csgOne.union(csgTwo);
        discBottomFlat = this.CSG.toMesh(unionCSG, new this.THREE.Matrix4());
        discBottomFlat.material = material;
      } else {
        discBottomFlat = null;
      }

      return discBottomFlat;
    }

    drawFastenerHead_6_DiscTopFlat(geometry, material, headData) {
      let diskTopFlat;

      if (headData.topDiameter.value > 0 && headData.bottomDiameter.value > 0 && headData.bottomDiameter.factor > 0 && 2 * (headData.bottomDiameter.factor * headData.bottomDiameter.value) + headData.bottomDiameter.value < headData.topDiameter.value && headData.height.value > 0 && headData.height.factor > 0 && headData.height.factor < 1) {
        let [geometry1, geometry2] = geometry;
        let cylinder = new this.THREE.Mesh(geometry1, material);
        let cylinderMesh = new this.THREE.Mesh(geometry2, material);
        let csgOne = this.CSG.fromMesh(cylinder);
        let csgTwo = this.CSG.fromMesh(cylinderMesh);
        let unionCSG = csgOne.union(csgTwo);
        diskTopFlat = this.CSG.toMesh(unionCSG, new this.THREE.Matrix4());
        diskTopFlat.material = material;
      } else {
        diskTopFlat = null;
      }

      return diskTopFlat;
    }

    drawFastenerHead_3_Lentil(geometry, material, headData) {
      let lentilHead;

      if (headData.topDiameter.value > 0 && headData.height.value > 0 && headData.height.factor > 0 && headData.height.factor < 1) {
        let [ellipsoidGeo, cylinderGeo] = geometry;
        let eraserGeo = new this.THREE.BoxGeometry(headData.topDiameter.value, headData.height.value, headData.topDiameter.value);
        eraserGeo.translate(0, -headData.height.value / 2, 0);
        let eraserMesh = new this.THREE.Mesh(eraserGeo);
        var ellipsoidmesh = new this.THREE.Mesh(ellipsoidGeo);
        var cylinder = new this.THREE.Mesh(cylinderGeo);
        let eraserCSG = this.CSG.fromMesh(eraserMesh);
        let ellipseCSG = this.CSG.fromMesh(ellipsoidmesh).subtract(eraserCSG);
        let cylinderCSG = this.CSG.fromMesh(cylinder);
        let lentilHeadCSG = ellipseCSG.union(cylinderCSG);
        lentilHead = this.CSG.toMesh(lentilHeadCSG, new this.THREE.Matrix4());
        lentilHead.material = material;
      } else {
        lentilHead = null;
      }

      return lentilHead;
    }

    drawFastenerShank(shank, properties, shankGeo, drawTopFrameShank = true, drawBotFrameShank = true) {
      let shanks = {
        headSide: [],
        tipSide: []
      };
      let shankWireframes = [];
      let tipSideProps = properties.fastener.tipSide;
      let linePropsHeadSide = properties.fastener.headSide.line;
      let lineProps = tipSideProps && shank.tipSideLength ? tipSideProps.line : linePropsHeadSide;
      let drawEdges = shank && shank.type === 2 ? true : false;

      if (shankGeo && shank.size > 0) {
        shankGeo.forEach(geo => {
          if (geo.isTipSide) drawTopFrameShank = false;
          let newWireframe = this.drawDoublesideFrame(geo, lineProps, drawTopFrameShank, drawBotFrameShank, drawEdges);
          shankWireframes.push(...newWireframe);
          let shankMesh = new this.THREE.Mesh(geo);
          if (geo.isTipSide) shanks.tipSide.push(shankMesh);else shanks.headSide.push(shankMesh);
        });
      }

      return [shanks, shankWireframes];
    } // TODO: add wireframe


    drawFastenerThread_0_WithPitch(cylGeo, spiralGeo, fastenerMaterial, wireframePoints, lineProps, lineMaterial) {
      let spiralMesh, cylMesh;

      if (cylGeo && spiralGeo) {
        spiralMesh = new this.THREE.Mesh(spiralGeo, fastenerMaterial.clone());
        spiralMesh.material.side = this.THREE.DoubleSide;
        cylMesh = new this.THREE.Mesh(cylGeo, fastenerMaterial);
        wireframePoints.forEach(pointArr => {
          pointArr.forEach(points => {
            let wireframe = this.drawLineFromPoints(points, lineProps);
            spiralMesh.add(wireframe);
          });
          let endPointsOne = [pointArr[0][0], pointArr[1][0], pointArr[2][0]];
          let endWireframeOne = this.drawLineFromPoints(endPointsOne, lineProps);
          spiralMesh.add(endWireframeOne);
          let endPointsTwo = [pointArr[0][pointArr[0].length - 1], pointArr[1][pointArr[0].length - 1], pointArr[2][pointArr[0].length - 1]];
          let endWireframeTwo = this.drawLineFromPoints(endPointsTwo, lineProps);
          spiralMesh.add(endWireframeTwo);
        });
      } else {
        spiralMesh = null;
        cylMesh = null;
      }

      return [cylMesh, spiralMesh];
    } // TODO: apply wireframe


    drawFastenerThread_1_WithoutPitch(threadGeo, coreGeo, material, wireframePoints, lineProps) {
      let thread;
      if (threadGeo) thread = new this.THREE.Mesh(threadGeo, material.clone());else thread = null;
      let core;
      if (coreGeo) core = new this.THREE.Mesh(coreGeo);else core = null;

      if (thread) {
        thread.material.side = this.THREE.DoubleSide;
        wireframePoints.forEach(points => {
          let wireframe = this.drawLineFromPoints(points, lineProps, true);
          thread.add(wireframe);
        });
      }

      return [thread, core];
    }

    drawFastenerTip(tipGeo, fastenerMaterial, tipSideMaterial) {
      let cylinder;

      if (tipGeo && fastenerMaterial) {
        cylinder = new this.THREE.Mesh(tipGeo);
        if (tipSideMaterial) cylinder.isTipSide = true;
      } else {
        cylinder = null;
      }

      return cylinder;
    }

    drawLineFromPoints(points, props, isClosed = false) {
      if (props.color.alpha == 0) {
        let line = new this.THREE.Mesh(); // TODO: might need better solution

        return line;
      }

      let colorValues = {
        red: props.color.red,
        green: props.color.green,
        blue: props.color.blue
      };
      let addToColor = 20;
      colorValues.red = colorValues.red + addToColor <= 255 ? colorValues.red + addToColor : Math.min(colorValues.red + addToColor, 255);
      colorValues.green = colorValues.green + addToColor <= 255 ? colorValues.green + addToColor : Math.min(colorValues.green + addToColor, 255);
      colorValues.blue = colorValues.blue + addToColor <= 255 ? colorValues.blue + addToColor : Math.min(colorValues.blue + addToColor, 255);
      let lineColor = "rgb(" + colorValues.red + ", " + colorValues.green + ", " + colorValues.blue + ")";
      let material = new this.THREE.LineBasicMaterial({
        color: lineColor
      });
      let geometry = new this.THREE.BufferGeometry().setFromPoints(points);
      let line;
      if (isClosed) line = new this.THREE.LineLoop(geometry, material);else line = new this.THREE.Line(geometry, material);
      return line;
    }

    rotateObject(object, direction, directionTwo) {
      let normalVector = new this.THREE.Vector3().crossVectors(direction, directionTwo);
      let directionVector = new this.THREE.Vector3(directionTwo.x, directionTwo.y, directionTwo.z);
      let newUpVector = new this.THREE.Vector3().crossVectors(normalVector, directionVector);
      let dotProduct = object.up.dot(newUpVector);
      let rotationAngle = -Math.acos(dotProduct); // by default THREE.js rotates counterclockwise, so I switched sign (correct?)

      object.rotateOnAxis(normalVector, rotationAngle);
      object.up = newUpVector;
      object.lookAt(object.position.clone().add(normalVector));
      return object;
    }

    setTime(meshArray, i, a) {
      var drawnObject = new Object();
      drawnObject.id = this.GraphicsThree3D.jsonData.fasteners[i].id;
      drawnObject.type = "fastener";
      drawnObject.data = this.GraphicsThree3D.jsonData;
      drawnObject.sceneObject = meshArray[a];
      this.GraphicsThree3D.ThreeObjects.push(drawnObject);
      this.GraphicsThree3D.scene.add(drawnObject.sceneObject);
    }

    drawClamp(fastener) {
      let tipDiameter = fastener.components.threads ? fastener.components.threads[0].coreDiameter : fastener.components.shank.size;
      let tipGeo = this.getFastenerTipGeo(fastener.components.tip, tipDiameter, fastener, fastener.components.axis.length);

      for (let b = 0; b < fastener.insertionPoints.length; b++) {
        let insertionPoint = fastener.insertionPoints[b].insertionPoint;
        let rotationVector = this.utils.normalizeVector(fastener.insertionPoints[b].directionVector1);
        let rotationVectorTwo = this.utils.normalizeVector(fastener.insertionPoints[b].directionVector2);
        let headSideMeshes = [];
        let tipSideMeshes = [];
        let [head, headWireframes] = this.drawClampHeadStapleSpine(fastener.components.shank, fastener.properties);
        if (head) headSideMeshes.push(head);
        let [leftAxisBot, leftAxisTop, axisWireframes] = this.drawClampAxis(fastener.components.axis, fastener.components.shank, fastener.components.tip, fastener.properties, true);
        let [rightAxisBot, rightAxisTop, axisWireframesRight] = this.drawClampAxis(fastener.components.axis, fastener.components.shank, fastener.components.tip, fastener.properties, false);

        if (fastener.properties.fastener.tipSide) {
          if (leftAxisTop) headSideMeshes.push(leftAxisTop);
          if (rightAxisTop) headSideMeshes.push(rightAxisTop);
          if (leftAxisBot) tipSideMeshes.push(leftAxisBot);
          if (rightAxisBot) tipSideMeshes.push(rightAxisBot);
        } else {
          if (leftAxisBot) headSideMeshes.push(leftAxisBot);
          if (rightAxisBot) headSideMeshes.push(rightAxisBot);
        }

        let [tipLeft, tipWireframes] = this.drawClampTip(fastener.components.shank, fastener.properties, fastener.components.tip, true, tipGeo);
        let [tipRight, tipWireframesRight] = this.drawClampTip(fastener.components.shank, fastener.properties, fastener.components.tip, false, tipGeo);

        if (fastener.properties.fastener.tipSide) {
          if (tipLeft) tipSideMeshes.push(tipLeft);
          if (tipRight) tipSideMeshes.push(tipRight);
        } else {
          if (tipLeft) headSideMeshes.push(tipLeft);
          if (tipRight) headSideMeshes.push(tipRight);
        }

        let headSideMesh, tipSideMesh;

        if (fastener.properties.fastener.headSide) {
          let headSideMaterial = this.getMaterial(fastener.properties, "headSide");
          headSideMaterial.side = this.THREE.FrontSide;

          if (headSideMeshes.length > 0) {
            headSideMesh = headSideMeshes.splice(0, 1)[0];
            headSideMesh.updateMatrix();
            headSideMeshes.forEach(mesh => {
              mesh.updateMatrix();
              let firstCSG = this.CSG.fromMesh(headSideMesh);
              let secondCSG = this.CSG.fromMesh(mesh);
              let unionCSG = firstCSG.union(secondCSG);
              let unionMesh = this.CSG.toMesh(unionCSG, new this.THREE.Matrix4());
              headSideMesh = unionMesh;
            });
          }

          headSideMesh.material = headSideMaterial;
        }

        if (fastener.properties.fastener.tipSide) {
          let tipSideMaterial = this.getMaterial(fastener.properties, "tipSide");
          tipSideMaterial.side = this.THREE.FrontSide;

          if (tipSideMeshes.length > 0) {
            tipSideMesh = tipSideMeshes.splice(0, 1)[0];
            tipSideMesh.updateMatrix();
            tipSideMeshes.forEach(mesh => {
              mesh.updateMatrix();
              let firstCSG = this.CSG.fromMesh(tipSideMesh);
              let secondCSG = this.CSG.fromMesh(mesh);
              let unionCSG = firstCSG.union(secondCSG);
              let unionMesh = this.CSG.toMesh(unionCSG, new this.THREE.Matrix4());
              tipSideMesh = unionMesh;
            });
          }

          tipSideMesh.material = tipSideMaterial;
        }

        let fastenerClamp = new this.THREE.Object3D();

        if (headSideMesh) {
          headSideMesh.renderOrder = -4;
          fastenerClamp.add(headSideMesh);
        }

        if (tipSideMesh) {
          tipSideMesh.renderOrder = -4;
          fastenerClamp.add(tipSideMesh);
        }

        let backSideHead, backSideTip;

        if (headSideMesh) {
          let geo = headSideMesh.geometry.clone();
          let mat = headSideMesh.material.clone();
          mat.side = this.THREE.BackSide;
          backSideHead = new this.THREE.Mesh(geo, mat);
          backSideHead.renderOrder = -5;
          fastenerClamp.add(backSideHead);
        }

        if (tipSideMesh) {
          let geo = tipSideMesh.geometry.clone();
          let mat = tipSideMesh.material.clone();
          mat.side = this.THREE.BackSide;
          backSideTip = new this.THREE.Mesh(geo, mat);
          backSideTip.renderOrder = -5;
          fastenerClamp.add(backSideTip);
        }

        headWireframes.forEach(wireframe => wireframe.forEach(line => {
          fastenerClamp.add(line);
        }));
        axisWireframes.forEach(wireframe => wireframe.forEach(line => {
          fastenerClamp.add(line);
        }));
        axisWireframesRight.forEach(wireframe => wireframe.forEach(line => {
          fastenerClamp.add(line);
        }));
        tipWireframes.forEach(wireframe => wireframe.forEach(line => {
          fastenerClamp.add(line);
        }));
        tipWireframesRight.forEach(wireframe => wireframe.forEach(line => {
          fastenerClamp.add(line);
        })); ///
        // Axis

        let axisGeo, axisPointGeo;
        let axisMat, axisPointMat;
        let inversedDirection = false;

        if (fastener.components.axis.length < 0) {
          inversedDirection = true;
          fastener.components.axis.length *= -1; // maybe change code not to modify json?
        }

        if (fastener.properties.axis && fastener.components.axis.length > 0) {
          let lineProps = fastener.properties.axis.line;

          if (lineProps.thickness && lineProps.type > 0) {
            axisGeo = this.getAxisGeo(fastener.components.axis, fastener.properties.axis);
            axisMat = this.GraphicsThree3D.getMaterialFromColor(lineProps.color);
          }

          let pointProps = fastener.properties.axis.point;
          axisPointGeo = this.getAxisPointGeo(pointProps);
          axisPointMat = this.GraphicsThree3D.getMaterialFromColor(pointProps.color);
        }

        let axis;
        if (fastener.properties.axis) axis = this.drawAxis(axisGeo, axisPointGeo, axisMat, axisPointMat);
        if (axis) fastenerClamp.add(axis); ///

        let directionVector = new this.THREE.Vector3(rotationVector.x, rotationVector.y, rotationVector.z);
        let directionVectorTwo = new this.THREE.Vector3(rotationVectorTwo.x, rotationVectorTwo.y, rotationVectorTwo.z);

        if (inversedDirection) {
          directionVector.negate();
          directionVectorTwo.negate();
        }

        this.rotateObject(fastenerClamp, directionVector, directionVectorTwo);
        fastenerClamp.position.set(insertionPoint.x, insertionPoint.y, insertionPoint.z); // this.addToDimChainCollisionDetection(fastenerClamp);

        this.GraphicsThree3D.scene.add(fastenerClamp);
        this.GraphicsThree3D.addObjectToVisControl(fastenerClamp, "fastener");
      }
    }

    drawClampHeadStapleSpine(shank, propertiesData) {
      const depth = shank.size;
      const width = shank.offsetLength;
      const height = shank.size;
      let headWireframes = [];
      let lineProps = propertiesData.fastener.headSide.line;
      let material = this.getMaterial(propertiesData);

      if (shank.type == 1) {
        var geometry = new this.THREE.CylinderGeometry(depth / 2, depth / 2, width, 32);
        geometry.translate(-depth / 2, 0, 0);
      } else if (shank.type == 2) {
        var geometry = new this.THREE.BoxGeometry(depth, height, width);
        geometry.translate(0, -height / 2, 0);
      } //


      let cube; // TODO: remove duplicate code / clean code

      let cubeGeoCSG;

      if (shank.type == 1) {
        let eraserBoxSize = Math.sqrt(2 * Math.pow(depth, 2));
        let eraserBox = new this.THREE.Mesh(new this.THREE.BoxGeometry(eraserBoxSize, eraserBoxSize, eraserBoxSize));
        eraserBox.rotation.z = Math.PI / 4;
        eraserBox.position.set(-depth, width / 2, 0);
        eraserBox.updateMatrix();
        let geomesh = new this.THREE.Mesh(geometry);
        let eraserCSG = this.GraphicsThree3D.CSG.fromMesh(eraserBox);
        let geoCSG = this.GraphicsThree3D.CSG.fromMesh(geomesh);
        cubeGeoCSG = geoCSG.subtract(eraserCSG);
        eraserBox.position.set(-depth, -width / 2, 0);
        eraserBox.updateMatrix();
        eraserCSG = this.GraphicsThree3D.CSG.fromMesh(eraserBox);
        cubeGeoCSG = cubeGeoCSG.subtract(eraserCSG);
      } else if (shank.type == 2) {
        let eraserBoxSize = Math.sqrt(2 * Math.pow(depth, 2));
        let eraserBox = new this.THREE.Mesh(new this.THREE.BoxGeometry(eraserBoxSize, eraserBoxSize, eraserBoxSize));
        eraserBox.rotation.x = Math.PI / 4;
        eraserBox.position.set(0, -depth, width / 2);
        eraserBox.updateMatrix();
        let geomesh = new this.THREE.Mesh(geometry);
        let eraserCSG = this.GraphicsThree3D.CSG.fromMesh(eraserBox);
        let geoCSG = this.GraphicsThree3D.CSG.fromMesh(geomesh);
        cubeGeoCSG = geoCSG.subtract(eraserCSG);
        eraserBox.position.set(0, -depth, -width / 2);
        eraserBox.updateMatrix();
        eraserCSG = this.GraphicsThree3D.CSG.fromMesh(eraserBox);
        cubeGeoCSG = cubeGeoCSG.subtract(eraserCSG);
      }

      cube = this.GraphicsThree3D.CSG.toMesh(cubeGeoCSG, new this.THREE.Matrix4());
      cube.material = material;

      if (geometry.type == "CylinderGeometry") {
        cube.rotation.z = Math.PI / 2;
        let radiusX = Math.sqrt(2 * Math.pow(depth / 2, 2));
        let partOne = this.getEllipsePointsXZ(radiusX, depth / 2, Math.PI / 4, {
          x: -width / 2 + depth / 2,
          y: -depth / 2,
          z: 0
        });
        let partTwo = this.getEllipsePointsXZ(radiusX, depth / 2, -Math.PI / 4, {
          x: width / 2 - depth / 2,
          y: -depth / 2,
          z: 0
        });
        let wireframeOne = this.drawLineFromPoints(partOne, lineProps, true);
        headWireframes.push([wireframeOne]);
        let wireframeTwo = this.drawLineFromPoints(partTwo, lineProps, true);
        headWireframes.push([wireframeTwo]);
      } else if (geometry.type == "BoxGeometry") {
        cube.rotation.y = Math.PI / 2;
        let cubeWireframe = [];
        let pointsOne = [new this.THREE.Vector3(-width / 2, 0, -depth / 2), new this.THREE.Vector3(-width / 2, 0, depth / 2), new this.THREE.Vector3(-width / 2 + depth, -depth, depth / 2), new this.THREE.Vector3(-width / 2 + depth, -depth, -depth / 2)];
        let pointsTwo = [new this.THREE.Vector3(width / 2, 0, -depth / 2), new this.THREE.Vector3(width / 2, 0, depth / 2), new this.THREE.Vector3(width / 2 - depth, -depth, depth / 2), new this.THREE.Vector3(width / 2 - depth, -depth, -depth / 2)];

        for (let i = 0; i < pointsOne.length; i++) {
          let newLine = this.drawLineFromPoints([pointsOne[i], pointsTwo[i]], lineProps);
          cubeWireframe.push(newLine);
        }

        headWireframes.push(cubeWireframe);
        let edgePointsOne = [...pointsOne.slice(1, 3), ...pointsTwo.slice(1, 3)];
        let edgePointsTwo = [pointsOne[0], pointsOne[3], pointsTwo[0], pointsTwo[3]];
        let edgesLineArr = [];

        for (let i = 0; i < edgePointsOne.length; i++) {
          let newLine = this.drawLineFromPoints([edgePointsOne[i], edgePointsTwo[i]], lineProps);
          edgesLineArr.push(newLine);
        }

        headWireframes.push(edgesLineArr);
      }

      return [cube, headWireframes];
    }

    drawClampAxis(axis, shank, tipData, propertiesData, isLeft) {
      const depth = shank.size;
      const width = shank.size;
      let height = axis.length;
      let axisWireframes = [];
      let offsetFromCenter = isLeft ? -(shank.offsetLength / 2) : shank.offsetLength / 2;
      let positiveOrNegative = Math.sign(offsetFromCenter);

      if (tipData && tipData.type > 0) {
        height -= tipData.height;
      }

      let lineProps = propertiesData.fastener.headSide.line;
      let material = this.getMaterial(propertiesData);
      let returnMeshes = [];
      let geometry, geometryTwo;
      let tipSideLength = shank.tipSideLength;

      if (tipSideLength) {
        height -= tipSideLength + 0.0001;

        if (shank.type == 1) {
          geometryTwo = new this.THREE.CylinderGeometry(width / 2, width / 2, tipSideLength, 32);
        } else {
          geometryTwo = new this.THREE.BoxGeometry(depth, tipSideLength, width);
        }

        geometryTwo.translate(0, -axis.length + tipSideLength / 2 + tipData.height, 0);
        let cubeTwo;

        if (propertiesData.fastener.tipSide) {
          let lineProps = propertiesData.fastener.tipSide.line;
          let materialTwo = this.GraphicsThree3D.getMaterialFromColor(propertiesData.fastener.tipSide.volume.color);
          cubeTwo = new this.THREE.Mesh(geometryTwo, materialTwo);
          cubeTwo.position.x = offsetFromCenter - shank.size / 2 * positiveOrNegative;
          returnMeshes.push(cubeTwo);

          if (geometryTwo.type == "BoxGeometry") {
            let partOne = [];
            let partTwo = [];
            let geoVertices = [...geometryTwo.vertices].map(vertex => vertex.clone().add(new this.THREE.Vector3(cubeTwo.position.x, 0, 0)));
            let firstPart = geoVertices[0].y;
            geoVertices.forEach(vertex => {
              if (vertex.y == firstPart) partOne.push(vertex);else partTwo.push(vertex);
            });

            if (!propertiesData.fastener.headSide) {
              let wireframeOne = this.drawLineFromPoints(partOne, lineProps, true);
              axisWireframes.push([wireframeOne]);
            }

            if (!tipData) {
              let wireframeTwo = this.drawLineFromPoints(partTwo, lineProps, true);
              axisWireframes.push([wireframeTwo]);
            } else if (tipData.type == 3 || tipData.type == 4) {
              for (let i = 0; i <= 2; i += 2) {
                let wireframeTwo = this.drawLineFromPoints(partTwo.slice(i, i + 2), lineProps, true);
                axisWireframes.push([wireframeTwo]);
              }
            }

            let edgesLineArr = [];

            for (let i = 0; i < partOne.length; i++) {
              let newLine = this.drawLineFromPoints([partOne[i], partTwo[i]], lineProps);
              edgesLineArr.push(newLine);
            }

            axisWireframes.push(edgesLineArr);
          } else {
            let partOne = [];
            let partTwo = [];
            let geoVertices = geometryTwo.vertices;
            let firstPart = geoVertices[0].y;
            geoVertices.forEach(vertex => {
              if (vertex.y == firstPart) partOne.push(vertex);else partTwo.push(vertex);
            });
            partOne.splice(partOne.length - 1, 1);
            partTwo.splice(partTwo.length - 1, 1);

            if (!propertiesData.fastener.headSide) {
              let wireframeOne = this.drawLineFromPoints(partOne, lineProps, true);
              wireframeOne.position.x += offsetFromCenter - positiveOrNegative * width / 2;
              axisWireframes.push([wireframeOne]);
            }

            if (!tipData) {
              let wireframeTwo = this.drawLineFromPoints(partTwo, lineProps, true);
              wireframeTwo.position.x += offsetFromCenter - positiveOrNegative * width / 2;
              axisWireframes.push([wireframeTwo]);
            }
          }
        }
      }

      if (shank.type == 1) {
        geometry = new this.THREE.CylinderGeometry(width / 2, width / 2, height, 32);
      } else if (shank.type == 2) {
        geometry = new this.THREE.BoxGeometry(depth, height, width);
        let cubeWireframe = [];
        let pointsOne = [new this.THREE.Vector3(-width / 2, 0, -depth / 2), new this.THREE.Vector3(-width / 2, 0, depth / 2), new this.THREE.Vector3(width / 2, 0, depth / 2), new this.THREE.Vector3(width / 2, 0, -depth / 2)];
        let pointsTwo = [new this.THREE.Vector3(-width / 2, -height, -depth / 2), new this.THREE.Vector3(-width / 2, -height, depth / 2), new this.THREE.Vector3(width / 2, -height, depth / 2), new this.THREE.Vector3(width / 2, -height, -depth / 2)];

        if (isLeft) {
          pointsOne.map((point, index) => {
            if (index > 1) point.sub(new this.THREE.Vector3(0, width, 0));
          });
        } else {
          pointsOne.map((point, index) => {
            if (index < 2) point.sub(new this.THREE.Vector3(0, width, 0));
          });
        }

        for (let i = 0; i < pointsOne.length; i++) {
          pointsOne[i].add(new this.THREE.Vector3(offsetFromCenter - positiveOrNegative * width / 2, 0, 0));
          pointsTwo[i].add(new this.THREE.Vector3(offsetFromCenter - positiveOrNegative * width / 2, 0, 0));
          let newLine = this.drawLineFromPoints([pointsOne[i], pointsTwo[i]], lineProps);
          cubeWireframe.push(newLine);
        }

        axisWireframes.push(cubeWireframe);

        if (tipData && (tipData.type == 3 || tipData.type == 4) && !propertiesData.fastener.tipSide) {
          for (let i = 0; i <= 2; i += 2) {
            let sideWireframe = this.drawLineFromPoints(pointsTwo.slice(i, i + 2), lineProps, true);
            axisWireframes.push([sideWireframe]);
          }
        }
      }

      geometry.translate(0, -height / 2, 0); ///

      let cube;
      let eraserBoxSize = Math.sqrt(2 * Math.pow(depth, 2));
      let eraserBox = new this.THREE.Mesh(new this.THREE.BoxGeometry(eraserBoxSize, eraserBoxSize, eraserBoxSize));
      eraserBox.rotation.z = Math.PI / 4;
      eraserBox.position.set(isLeft ? depth / 2 : -depth / 2, 0, 0);
      eraserBox.updateMatrix();
      let geomesh = new this.THREE.Mesh(geometry);
      let eraserCSG = this.GraphicsThree3D.CSG.fromMesh(eraserBox);
      let geoCSG = this.GraphicsThree3D.CSG.fromMesh(geomesh);
      let cubeGeoCSG = geoCSG.subtract(eraserCSG);
      cube = this.GraphicsThree3D.CSG.toMesh(cubeGeoCSG, new this.THREE.Matrix4());
      cube.material = material; ///

      cube.position.x = offsetFromCenter - shank.size / 2 * positiveOrNegative;
      returnMeshes.push(cube);
      if (returnMeshes.length == 1) returnMeshes.push(null);
      return [...returnMeshes, axisWireframes];
    }

    drawClampTip(shank, propertiesData, tip, isLeft, tipGeo) {
      let tipSideProps = propertiesData.fastener.tipSide;
      let headSideLineProps = propertiesData.fastener.headSide.line;
      const colorAxis = tipSideProps && shank.tipSideLength ? tipSideProps.volume.color : propertiesData.fastener.headSide.volume.color;
      let addTopWireframe = true;

      if (shank.type == 1 && (tip.type == 1 || tip.type == 2)) {
        addTopWireframe = false;
      } else if (shank.type == 2 && (tip.type == 3 || tip.type == 4 || tip.type == 5 || tip.type == 6)) {
        addTopWireframe = false;
      }

      let tipWireframes = this.makeTipWireframe(tip, tipGeo, tipSideProps && shank.tipSideLength ? tipSideProps.line : headSideLineProps, addTopWireframe);
      let material = this.GraphicsThree3D.getMaterialFromColor(colorAxis);
      var cylinder = new this.THREE.Mesh(tipGeo, material);
      var offsetFromCenter = isLeft ? -(shank.offsetLength / 2) : shank.offsetLength / 2;
      var positiveOrNegative = Math.sign(offsetFromCenter);
      cylinder.position.x = offsetFromCenter - shank.size / 2 * positiveOrNegative;
      tipWireframes.forEach(wireframe => wireframe.forEach(line => line.position.add(new this.THREE.Vector3(cylinder.position.x, 0, 0))));
      return [cylinder, tipWireframes];
    }

    getLineMaterial(lineProps) {
      let lineColor = lineProps.color;
      let materialOptions = {
        color: "rgb(" + lineColor.red + ", " + lineColor.green + ", " + lineColor.blue + ")",
        transparent: true,
        opacity: lineColor.alpha / 255
      };
      let lineMaterial = new this.THREE.LineBasicMaterial(materialOptions);
      return lineMaterial;
    }

    getEllipsePointsXZ(radiusX, radiusZ, rotationZ, position) {
      let geo = new this.THREE.CircleGeometry(radiusZ, 32);
      geo.applyMatrix4(new this.THREE.Matrix4().makeScale(1, radiusX / radiusZ, 1));
      geo.rotateY(Math.PI / 2);
      geo.rotateZ(rotationZ);
      geo.translate(position.x, position.y, position.z);
      let vertices = geo.vertices;
      vertices.splice(0, 1);
      return vertices;
    }

  }

  _exports.FastenerLoader = FastenerLoader;

  class Spiral {
    constructor(spiralRadius, spiralHeight, initDist, spiralDebth, spiralRowWidth, material, parent, color = 0xffaa00) {
      this.color = color;
      this.initDist = initDist;
      this.spiralHeight = spiralHeight;
      this.spiralDebth = spiralDebth;
      this.spiralRadius = spiralRadius;
      this.spiralRowWidth = spiralRowWidth;
      this.currentHeight = -spiralHeight / 2;
      this.x = 0;
      this.y = 0;
      this.angle = 0;
      this.xPlus = 0;
      this.zPlus = 0;
      this.material = material;
      this.parent = parent; //this.geometry = geometry;
    }

    currentPosition(dist) {
      return this.currentHeight += dist;
    }

    currentAngle() {
      this.angle -= 360 / 32;
      this.x = Math.cos(Math.PI / 180 * this.angle);
      this.z = Math.sin(Math.PI / 180 * this.angle);
    }

    getCylGeo() {
      if (this.spiralHeight > 0 && this.spiralRadius == 0) this.spiralRadius += 0.0001;
      const cylinder = new this.parent.THREE.CylinderGeometry(this.spiralRadius, this.spiralRadius, this.spiralHeight, 32);
      cylinder.translate(0, -this.spiralHeight / 2, 0);
      return cylinder;
    }

    getSpiralGeo() {
      let wireframePoints = [[], [], []];
      const dist = this.initDist / 32;
      const c = this.currentPosition(dist);
      this.currentAngle();
      var geometry = new this.parent.THREE.Geometry();
      let pointOne = new this.parent.THREE.Vector3(this.spiralRadius * this.x, c, this.spiralRadius * this.z);
      let pointTwo = new this.parent.THREE.Vector3((this.spiralDebth + this.spiralRadius) * this.x, c - this.spiralRowWidth / 2, (this.spiralDebth + this.spiralRadius) * this.z);
      let pointThree = new this.parent.THREE.Vector3(this.spiralRadius * this.x, c - this.spiralRowWidth, this.spiralRadius * this.z);
      geometry.vertices.push(pointOne);
      geometry.vertices.push(pointTwo);
      geometry.vertices.push(pointThree);
      wireframePoints[0].push(pointOne);
      wireframePoints[1].push(pointTwo);
      wireframePoints[2].push(pointThree);
      var face0 = new this.parent.THREE.Face3(0, 1, 2);
      geometry.faces.push(face0);
      var l = 0;

      for (let i = 0; i < 32 * (this.spiralHeight - this.spiralRowWidth) / this.initDist; i++) {
        const c1 = this.currentPosition(dist);
        this.currentAngle();
        pointOne = new this.parent.THREE.Vector3(this.spiralRadius * this.x, c1, this.spiralRadius * this.z);
        pointTwo = new this.parent.THREE.Vector3((this.spiralDebth + this.spiralRadius) * this.x, c1 - this.spiralRowWidth / 2, (this.spiralDebth + this.spiralRadius) * this.z);
        pointThree = new this.parent.THREE.Vector3(this.spiralRadius * this.x, c1 - this.spiralRowWidth, this.spiralRadius * this.z);
        geometry.vertices.push(pointOne);
        geometry.vertices.push(pointTwo);
        geometry.vertices.push(pointThree);
        wireframePoints[0].push(pointOne);
        wireframePoints[1].push(pointTwo);
        wireframePoints[2].push(pointThree);
        var face1 = new this.parent.THREE.Face3(l + 1, l + 3, l + 4);
        var face2 = new this.parent.THREE.Face3(l + 1, l, l + 3);
        var face3 = new this.parent.THREE.Face3(l, l + 2, l + 5);
        var face4 = new this.parent.THREE.Face3(l, l + 3, l + 5);
        var face5 = new this.parent.THREE.Face3(l + 1, l + 3, l + 2);
        var face6 = new this.parent.THREE.Face3(l + 1, l + 4, l + 3);
        var face7 = new this.parent.THREE.Face3(l + 1, l + 5, l + 2);
        var face8 = new this.parent.THREE.Face3(l + 1, l + 4, l + 5);
        var face9 = new this.parent.THREE.Face3(l, l + 1, l + 2); //add the face to the geometry's faces array

        geometry.faces.push(face1);
        geometry.faces.push(face2);
        geometry.faces.push(face3);
        geometry.faces.push(face4);
        geometry.faces.push(face5);
        geometry.faces.push(face6);
        geometry.faces.push(face7);
        geometry.faces.push(face8);
        geometry.faces.push(face9); ///
        // let testFaceOne = new this.parent.THREE.Face3(l, l + 1, l + 3);
        // let testFaceTwo = new this.parent.THREE.Face3(l + 1, l + 4, l + 3);
        // let testFaceThree = new this.parent.THREE.Face3(l + 1, l + 2, l + 4);
        // let testFaceFour = new this.parent.THREE.Face3(l + 2, l + 5, l + 4);
        // geometry.faces.push(testFaceOne);
        // geometry.faces.push(testFaceTwo);
        // geometry.faces.push(testFaceThree);
        // geometry.faces.push(testFaceFour);

        l += 3;
      }

      geometry.computeFaceNormals();
      geometry.computeVertexNormals();
      geometry.translate(0, -this.spiralHeight / 2 + this.spiralRowWidth - this.initDist / 32, 0);
      wireframePoints.forEach(points => {
        points.map(point => point = point.clone().setComponent(1, -this.spiralHeight / 2 + this.spiralRowWidth - this.initDist / 32));
      });
      return [geometry, wireframePoints];
    }

  }
});