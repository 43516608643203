define("m08-2020/lib/FigureLoaders/DrawingObjectComponents/Ellipsoid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Ellipsoid = void 0;

  class Ellipsoid {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.THREE = GraphicsThree3D.THREE;
      this.utils = GraphicsThree3D.utils;
      this.CSG = GraphicsThree3D.CSG;
      this.WireframeGenerator = GraphicsThree3D.WireframeGenerator;
    }

    drawEllipsoid(ellipsoidData) {
      console.log(ellipsoidData);
      let centerPoint = this.utils.initVector3(ellipsoidData.centerPoint);
      let directionVectorOne = this.utils.initVector3(ellipsoidData.directionVector1).normalize();
      let directionVectorTwo = this.utils.initVector3(ellipsoidData.directionVector2).normalize();
      let directionVectorThree = this.utils.initVector3(ellipsoidData.directionVector3).normalize();
      let radiusOne = ellipsoidData.radius1;
      let radiusTwo = ellipsoidData.radius2;
      let radiusThree = ellipsoidData.radius3;
      let segmentHeight = ellipsoidData.segmentHeight;
      let ellipsoidMesh = this.makeEllipsoidMesh(radiusOne, radiusTwo, radiusThree);

      if (this.segmentVectorsAreValid(ellipsoidData.segmentVector1, ellipsoidData.segmentVector2)) {
        let segmentVectorOne = this.utils.initVector3(ellipsoidData.segmentVector1).normalize();
        let segmentVectorTwo = this.utils.initVector3(ellipsoidData.segmentVector2).normalize();
        let segmentsCross = this.utils.getCrossVector(segmentVectorOne, segmentVectorTwo);
        let directionsCross = this.utils.getCrossVector(directionVectorOne, directionVectorTwo);
        let segmentsCrossLength = segmentsCross.length();
        let crossVector = segmentsCrossLength !== 0 ? segmentsCross : directionsCross;
        let isOnPlane = false;

        for (let vector of [directionVectorOne, directionVectorTwo, directionVectorThree]) {
          if (Math.abs(vector.dot(crossVector)) === 1) isOnPlane = true;
          if (vector.dot(crossVector) === -1) crossVector.negate();
        }

        if (isOnPlane) {
          let segmentQuaternion = new this.THREE.Quaternion().setFromUnitVectors(crossVector, new this.THREE.Vector3(0, 0, 1));
          segmentVectorOne.applyQuaternion(segmentQuaternion);
          segmentVectorTwo.applyQuaternion(segmentQuaternion);
          let maxRadius = Math.max(radiusOne, radiusTwo, radiusThree) + 1;
          let boxSize = 2 * maxRadius;
          let pointsData = {
            centerPoint: {
              x: ellipsoidData.centerPoint.x,
              y: ellipsoidData.centerPoint.y,
              z: ellipsoidData.centerPoint.z
            },
            directionVector1: {
              x: ellipsoidData.directionVector1.x,
              y: ellipsoidData.directionVector1.y,
              z: ellipsoidData.directionVector1.z
            },
            directionVector2: {
              x: ellipsoidData.directionVector2.x,
              y: ellipsoidData.directionVector2.y,
              z: ellipsoidData.directionVector2.z
            },
            radius1: maxRadius,
            radius2: maxRadius,
            segmentHeight: 0,
            segmentVector1: {
              x: segmentVectorOne.x,
              y: segmentVectorOne.y,
              z: segmentVectorOne.z
            },
            segmentVector2: {
              x: segmentVectorTwo.x,
              y: segmentVectorTwo.y,
              z: segmentVectorTwo.z
            }
          };
          let pts = this.GraphicsThree3D.getEllipsePlanarPoints(pointsData);
          if (segmentsCrossLength === 0) pts.pop();
          let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(pts, new this.THREE.Vector3(0, 0, 1));
          pts.forEach(point => {
            point.sub(midVector);
            point.applyEuler(eulerOne);
            point.add(midVector);
          });
          let eraserPoints = pts.map(point => new this.THREE.Vector2(point.x, point.y));
          let eraserShape = new this.THREE.Shape(eraserPoints);
          let eraserGeometry = new this.THREE.ExtrudeGeometry(eraserShape, {
            steps: 1,
            bevelEnabled: false,
            depth: boxSize
          });
          eraserGeometry.translate(0, 0, -boxSize / 2);
          let eraserMesh = new this.THREE.Mesh(eraserGeometry);
          eraserMesh.lookAt(crossVector);
          eraserMesh.updateMatrix();
          let ellipsoidCSG = this.CSG.fromMesh(ellipsoidMesh);
          let eraserCSG = this.CSG.fromMesh(eraserMesh);
          ellipsoidCSG = ellipsoidCSG.intersect(eraserCSG);

          if (segmentHeight) {
            let [segOne, segTwo] = this.getEllipsoidSegments(ellipsoidCSG, segmentVectorOne, segmentVectorTwo);
            let midVector = segmentsCrossLength !== 0 ? new this.THREE.Vector3().lerpVectors(segOne, segTwo, 0.5).normalize() : new this.THREE.Vector3().crossVectors(segmentVectorOne, crossVector);
            let maxDistance = 0,
                midPoint = new this.THREE.Vector3();
            ellipsoidCSG.polygons.forEach(polygon => polygon.vertices.forEach(vertex => {
              let localVertex = new this.THREE.Vector3(vertex.pos.x, vertex.pos.y, vertex.pos.z);
              let dir = localVertex.clone().normalize().dot(midVector);
              let distance = midVector.clone().dot(localVertex);

              if (dir > 0.99) {
                if (distance > maxDistance) {
                  maxDistance = distance;
                  midPoint.copy(localVertex);
                }
              } else if (dir < -0.99) {
                if (-distance > maxDistance) {
                  maxDistance = -distance;
                  midPoint.copy(localVertex.clone().negate());
                }
              }
            }));
            let midRadius = midPoint.length();

            if (segmentsCrossLength === 0) {
              let heightDelta = midRadius - segmentHeight;
              let eraserBox = new this.THREE.Mesh(new this.THREE.BoxGeometry(boxSize, boxSize, boxSize));
              eraserBox.lookAt(midVector.clone().normalize());
              eraserBox.position.add(new this.THREE.Vector3().copy(midVector).normalize().multiplyScalar(boxSize / 2 - heightDelta));
              eraserBox.updateMatrix();
              let heightEraserCSG = this.CSG.fromMesh(eraserBox);
              ellipsoidCSG = ellipsoidCSG.subtract(heightEraserCSG);
            } else {
              if (pts.length > 32) {
                let maxHeight = midRadius + new this.THREE.Vector3().lerpVectors(segOne, segTwo, 0.5).length();

                if (segmentHeight <= maxHeight) {
                  if (segmentHeight > midRadius) {
                    let heightDelta = segmentHeight - midRadius;
                    let dir = segOne.clone().sub(segTwo).normalize();
                    let eraserBox = new this.THREE.Mesh(new this.THREE.BoxGeometry(boxSize, boxSize, boxSize));
                    let upVector = this.getMeshUpVector(eraserBox, dir);
                    let delta = this.getDifferenceUpAndMid(upVector, new this.THREE.Vector3().lerpVectors(segOne, segTwo, 0.5));
                    eraserBox.position.add(new this.THREE.Vector3().copy(upVector).multiplyScalar(heightDelta + boxSize / 2 - delta));
                    eraserBox.updateMatrix();
                    let heightEraserCSG = this.CSG.fromMesh(eraserBox);
                    ellipsoidCSG = ellipsoidCSG.subtract(heightEraserCSG);
                  } else {
                    let heightDelta = midRadius - segmentHeight;
                    let dir = segOne.clone().sub(segTwo).normalize();
                    let eraserBox = new this.THREE.Mesh(new this.THREE.BoxGeometry(boxSize, boxSize, boxSize));
                    let upVector = this.getMeshUpVector(eraserBox, dir);
                    let delta = this.getDifferenceUpAndMid(upVector, new this.THREE.Vector3().lerpVectors(segOne, segTwo, 0.5));
                    eraserBox.position.add(new this.THREE.Vector3().copy(upVector).multiplyScalar(boxSize / 2 - heightDelta + delta));
                    eraserBox.updateMatrix();
                    let heightEraserCSG = this.CSG.fromMesh(eraserBox);
                    ellipsoidCSG = ellipsoidCSG.subtract(heightEraserCSG);
                  }
                }
              } else {
                let maxHeight = midRadius;

                if (segmentHeight <= maxHeight) {
                  let midNotNormalized = new this.THREE.Vector3().lerpVectors(segOne, segTwo, 0.5);

                  if (midRadius - midNotNormalized.length() > segmentHeight) {
                    let heightDelta = midRadius - segmentHeight;
                    let dir = segOne.clone().sub(segTwo).normalize();
                    let eraserBox = new this.THREE.Mesh(new this.THREE.BoxGeometry(boxSize, boxSize, boxSize));
                    eraserBox.lookAt(dir);
                    eraserBox.position.add(new this.THREE.Vector3().copy(midVector).multiplyScalar(heightDelta - boxSize / 2));
                    eraserBox.updateMatrix();
                    let heightEraserCSG = this.CSG.fromMesh(eraserBox);
                    ellipsoidCSG = ellipsoidCSG.subtract(heightEraserCSG);
                  } else {
                    let midPlane = new this.THREE.Plane(midVector);
                    let differenceOne = Math.abs(midPlane.distanceToPoint(midPoint)) - Math.abs(midPlane.distanceToPoint(segOne));
                    let differenceTwo = Math.abs(midPlane.distanceToPoint(midPoint)) - Math.abs(midPlane.distanceToPoint(segTwo));
                    let segmentOne = Math.abs(segmentHeight - differenceOne);
                    let segmentTwo = Math.abs(segmentHeight - differenceTwo);
                    let proportionOne = segmentOne / midRadius;
                    let proportionTwo = segmentTwo / midRadius;
                    let proportion = Math.max(proportionOne, proportionTwo);
                    let vecOne = segOne.clone().normalize().multiplyScalar(segOne.length() * proportion);
                    let vecTwo = segTwo.clone().normalize().multiplyScalar(segTwo.length() * proportion);
                    vecOne = new this.THREE.Vector2(vecOne.x, vecOne.y);
                    vecTwo = new this.THREE.Vector2(vecTwo.x, vecTwo.y);
                    let vecMid = new this.THREE.Vector2(0, 0);
                    let heightEraserPoints = [vecMid, vecOne, vecTwo];
                    let heightEraserShape = new this.THREE.Shape(heightEraserPoints);
                    let heightEraserGeo = new this.THREE.ExtrudeGeometry(heightEraserShape, {
                      steps: 1,
                      bevelEnabled: false,
                      depth: boxSize
                    });
                    heightEraserGeo.translate(0, 0, -boxSize / 2);
                    let heightEraserMesh = new this.THREE.Mesh(heightEraserGeo);
                    let heightEraserCSG = this.CSG.fromMesh(heightEraserMesh);
                    ellipsoidCSG = ellipsoidCSG.subtract(heightEraserCSG);
                  }
                }
              }
            }
          }

          ellipsoidMesh = this.CSG.toMesh(ellipsoidCSG, new this.THREE.Matrix4());
        }
      }

      this.GraphicsThree3D.alignObjectWithVectors(ellipsoidMesh, centerPoint, directionVectorThree, directionVectorOne);
      ellipsoidMesh.updateMatrix();
      ellipsoidMesh.generationType = "volume"; ///

      let linePairs = [];
      this.WireframeGenerator.getRegularWireframePointPairs(ellipsoidMesh, Math.PI / 720).forEach(pair => linePairs.push([pair[0].clone().applyMatrix4(ellipsoidMesh.matrix), pair[1].clone().applyMatrix4(ellipsoidMesh.matrix)]));
      let linePairsForOpaque = [];

      if (this.segmentVectorsAreValid(ellipsoidData.segmentVector1, ellipsoidData.segmentVector2)) {
        this.WireframeGenerator.getRegularWireframePointPairs(ellipsoidMesh, 20 * Math.PI / 180).forEach(pair => linePairsForOpaque.push([pair[0].clone().applyMatrix4(ellipsoidMesh.matrix), pair[1].clone().applyMatrix4(ellipsoidMesh.matrix)]));
      }

      this.WireframeGenerator.getVolumeWireframeData(ellipsoidMesh, linePairsForOpaque, linePairs, ellipsoidData.objectType); ///

      return ellipsoidMesh;
    }

    getEllipsoidSegments(ellipsoidCSG, segmentVectorOne, segmentVectorTwo) {
      let segOne = new this.THREE.Vector3();
      let segTwo = new this.THREE.Vector3();
      let maxDistanceOne = 0,
          maxDistanceTwo = 0;
      ellipsoidCSG.polygons.forEach(polygon => polygon.vertices.forEach(vertex => {
        let localVertex = new this.THREE.Vector3(vertex.pos.x, vertex.pos.y, vertex.pos.z);
        let dirOne = localVertex.clone().normalize().dot(segmentVectorOne.clone().normalize());
        let distanceOne = segmentVectorOne.clone().dot(localVertex);

        if (dirOne > 0.9999) {
          if (distanceOne > maxDistanceOne) {
            maxDistanceOne = distanceOne;
            segOne.copy(localVertex);
          }
        }

        let dirTwo = localVertex.clone().normalize().dot(segmentVectorTwo.clone().normalize());
        let distanceTwo = segmentVectorTwo.clone().dot(localVertex);

        if (dirTwo > 0.9999) {
          if (distanceTwo > maxDistanceTwo) {
            maxDistanceTwo = distanceTwo;
            segTwo.copy(localVertex);
          }
        }
      }));
      return [segOne, segTwo];
    }

    getMeshUpVector(mesh, direction) {
      let upObject = new this.THREE.Object3D();
      upObject.position.copy(mesh.up);
      mesh.add(upObject);
      mesh.lookAt(direction);
      mesh.updateMatrixWorld();
      let upVector = upObject.getWorldPosition(new this.THREE.Vector3());
      return upVector;
    }

    getDifferenceUpAndMid(up, mid) {
      let midLength = mid.length();
      let upLength = midLength * Math.cos(up.angleTo(mid));
      let delta = midLength - upLength;
      return delta;
    }

    makeEllipsoidMesh(radiusOne, radiusTwo, radiusThree) {
      const DETAIL = 32;
      let ellipsoidGeo = new this.THREE.SphereGeometry(radiusOne, DETAIL, DETAIL).applyMatrix4(new this.THREE.Matrix4().makeScale(1, radiusTwo / radiusOne, radiusThree / radiusOne));
      let ellipsoidMesh = new this.THREE.Mesh(ellipsoidGeo);
      return ellipsoidMesh;
    }

    segmentVectorsAreValid(segmentVector1, segmentVector2) {
      return segmentVector1 && segmentVector2 && (segmentVector1.x || segmentVector1.y || segmentVector1.z) && (segmentVector2.x || segmentVector2.y || segmentVector2.z);
    }

  }

  _exports.Ellipsoid = Ellipsoid;
});