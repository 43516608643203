define("m08-2020/lib/Utils/PointInsidePolygonCheck2D", ["exports", "m08-2020/lib/Utils/LineIntersectionDetection"], function (_exports, _LineIntersectionDetection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pointInsidePolygon2D = pointInsidePolygon2D;
  _exports.Point2D = void 0;

  class Point2D {
    constructor(x, y) {
      this.x = x;
      this.y = y;
    }

  }

  _exports.Point2D = Point2D;

  function pointInsidePolygon2D(polygon, point) {
    const INFINITE_VALUE = Number.MAX_VALUE;
    const N = polygon.length;
    if (N < 3) return false;
    let extremePoint = new Point2D(INFINITE_VALUE, point.y);
    let count = 0;

    for (let i = 0; i < N - 1; i++) {
      let j = i + 1;

      if ((0, _LineIntersectionDetection.linesIntersect)(polygon[i], polygon[j], point, extremePoint)) {
        if ((0, _LineIntersectionDetection.orientation)(polygon[i], point, polygon[j]) === 0) return (0, _LineIntersectionDetection.onSegment)(polygon[i], point, polygon[j]);
        count++;
      }
    }

    return count % 2 === 1;
  }
});