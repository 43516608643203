define("m08-2020/lib/Utils/BooleanOperations", ["exports", "three", "polybooljs", "m08-2020/lib/CSGMesh"], function (_exports, THREE, _polybooljs, _CSGMesh) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.applyCSG = applyCSG;
  _exports.doCSG = doCSG;
  _exports.makeCombinedSegments2D = makeCombinedSegments2D;
  _exports.doSegmentsOperation = doSegmentsOperation;
  _exports.makePolygonsFromPosAndNegRegions = makePolygonsFromPosAndNegRegions;
  const CSG = (0, _CSGMesh.CsgFactory)(THREE);

  function applyCSG(positiveObjects, negativeObjects) {
    let positiveObject = positiveObjects.splice(0, 1)[0];
    positiveObjects.forEach(object => positiveObject = doCSG(positiveObject, object, "union"));
    negativeObjects.forEach(object => positiveObject = doCSG(positiveObject, object, "subtract"));
    return positiveObject;
  }

  function doCSG(a, b, op) {
    let bspA = CSG.fromMesh(a);
    let bspB = CSG.fromMesh(b);
    let bspC = bspA[op](bspB);
    let result = CSG.toMesh(bspC, new THREE.Matrix4());
    return result;
  }

  function makePolygonsFromPosAndNegRegions(regions) {
    let positives = regions.positiveRegions;
    let negatives = regions.negativeRegions;
    let segmentsPos = makeCombinedSegments2D(positives, "selectUnion");
    let segmentsNeg = negatives.length > 0 ? makeCombinedSegments2D(negatives, "selectUnion") : null;
    let finalSegments = segmentsNeg ? doSegmentsOperation(segmentsPos, segmentsNeg, "selectDifference") : segmentsPos;

    let finalPolygons = _polybooljs.default.polygon(finalSegments);

    return finalPolygons;
  }

  function makeCombinedSegments2D(regions, operation) {
    let segments = _polybooljs.default.segments(regions[0]);

    for (let i = 1; i < regions.length; i++) {
      let tempSegment = _polybooljs.default.segments(regions[i]);

      segments = doSegmentsOperation(segments, tempSegment, operation);
    }

    return segments;
  }

  function doSegmentsOperation(segmentOne, segmentTwo, operation) {
    let combined = _polybooljs.default.combine(segmentOne, segmentTwo);

    return _polybooljs.default[operation](combined);
  }
});