define("m08-2020/lib/ZoomController", ["exports", "three"], function (_exports, THREE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ZoomController = void 0;

  class ZoomController {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.controls = GraphicsThree3D.controls;
      this.camera = GraphicsThree3D.camera;
      this.drawingObjectWireframesSolid = []; ///

      this.controls.addEventListener("change", this.zoomDrawingObjectSolidLines()); ///
    }

    zoomDrawingObjectSolidLines() {
      return () => {
        let startingRotation = new THREE.Euler(),
            startingPosition = new THREE.Vector3();
        let scaleFactor = 1 / this.camera.zoom;
        this.drawingObjectWireframesSolid.forEach(lineData => {
          if (this.camera.zoom < 1) {
            startingRotation.copy(lineData.line.rotation);
            startingPosition.copy(lineData.line.position);
            lineData.line.position.sub(lineData.line.position);
            lineData.line.setRotationFromEuler(lineData.eulerOne);
            lineData.line.scale.set(scaleFactor, scaleFactor, 1);
            lineData.line.setRotationFromEuler(startingRotation);
            lineData.line.position.add(startingPosition);
          }
        });
      };
    }

    getDrawingObjectWireframes(object) {
      const Z_AXIS = new THREE.Vector3(0, 0, 1);
      object.children.forEach(child => {
        // TODO: Add support for buffergeometries
        if (child.isLine && child.needsZoomControl) {
          let ptOne = child.pointsStartEnd.startPoint;
          let ptTwo = child.pointsStartEnd.endPoint;
          let lineDirection = new THREE.Vector3().copy(ptTwo).sub(ptOne).normalize();
          let quaternion = new THREE.Quaternion().setFromUnitVectors(lineDirection, Z_AXIS);
          let quaternionConjugate = new THREE.Quaternion().setFromUnitVectors(Z_AXIS, lineDirection);
          let eulerOne = new THREE.Euler().setFromQuaternion(quaternion);
          let eulerTwo = new THREE.Euler().setFromQuaternion(quaternionConjugate);
          this.drawingObjectWireframesSolid.push({
            line: child,
            eulerOne,
            eulerTwo
          });
        }
      });
    }

    drawingObjectWireframes() {
      this.drawingObjectWireframesSolid = [];
    }

  }

  _exports.ZoomController = ZoomController;
});