define("m08-2020/lib/WireframeGenerator", ["exports", "three", "m08-2020/lib/Utils", "m08-2020/lib/ThreeExtensions/OutlinesGeometry", "m08-2020/lib/Utils/BooleanOperations"], function (_exports, THREE, _Utils, _OutlinesGeometry, BooleanOperations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WireframeGenerator = void 0;
  const utils = new _Utils.Utils(THREE);

  class WireframeGenerator {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.areaWireframesData = [];
      this.volumeWireframesData = {
        opaque: {
          positives: [],
          negatives: []
        },
        transparent: {
          positives: [],
          negatives: []
        }
      };
    }

    applyWireframesArea(object, objectTransparent, lineProps, objectData) {
      const RENDER_ORDER = -99;
      let pointPairs = this.getRegularWireframePointPairs(object);
      pointPairs.forEach(pair => {
        let wireframe = this.drawObjectLine(pair, lineProps);
        wireframe.renderOrder = RENDER_ORDER;

        wireframe.onBeforeRender = function (renderer) {
          renderer.clearDepth();
        };

        let wireframeClone = wireframe.clone();
        wireframeClone.isLine = true;
        if (wireframe.needsZoomControl) wireframeClone.needsZoomControl = true;
        if (wireframe.pointsStartEnd) wireframeClone.pointsStartEnd = wireframe.pointsStartEnd;
        object.add(wireframe);
        if (objectTransparent) objectTransparent.add(wireframeClone);
      });
      object.dxfPoints = [{
        id: objectData.id,
        pointPairs: pointPairs.map(pair => pair.map(pt => pt.clone())),
        lineProps
      }];
    }

    applyWireframesVolume(object, objectTransparent, lineProps, objectData) {
      let pointPairs = [];
      this.mergeVolumeWireframes("opaque");
      this.mergeVolumeWireframes("transparent");
      this.createLinesFromWireframeData("opaque", object, lineProps);
      this.createLinesFromWireframeData("transparent", objectTransparent, lineProps, pointPairs);
      this.clearWireframeState();
      object.dxfPoints = [{
        id: objectData.id,
        pointPairs,
        lineProps
      }];
    }

    createLinesFromWireframeData(type, object, lineProps, pointPairs = null) {
      const RENDER_ORDER = -99;
      const DELTA = 0.0001;
      this.volumeWireframesData[type].forEach(({
        linePoints
      }) => {
        linePoints.forEach(([pointOne, pointTwo]) => {
          if (pointOne.distanceTo(pointTwo) > DELTA) {
            let line = this.drawObjectLine([pointOne, pointTwo], lineProps);
            line.renderOrder = RENDER_ORDER;

            line.onBeforeRender = function (renderer) {
              renderer.clearDepth();
            };

            if (object) object.add(line);
            if (pointPairs) pointPairs.push([pointOne.clone(), pointTwo.clone()]);
          }
        });
      });
    } ///


    drawObjectLine(pointPair, lineProps) {
      let firstLine;

      if (canDrawLine(lineProps)) {
        if (canDrawSolidLine(lineProps)) firstLine = this.drawSolidLine(pointPair, lineProps, true);else if (canDrawDashedLine(lineProps)) firstLine = this.drawDashedLine(pointPair, lineProps);else firstLine = utils.drawConnectedLines(pointPair, lineProps);
      } else firstLine = new THREE.Mesh();

      firstLine.isLine = true;
      firstLine.pointsStartEnd = {
        startPoint: pointPair[0],
        endPoint: pointPair[1]
      }; // Helper functions -->

      function canDrawLine(lineProps) {
        return lineProps.thickness && lineProps.color.alpha && (lineProps.type || lineProps.type === undefined);
      }

      function canDrawSolidLine(lineProps) {
        return lineProps.type === 1 || lineProps.type === undefined || lineProps.factor === 0 && lineProps.type !== null && lineProps.type !== 0;
      }

      function canDrawDashedLine(lineProps) {
        return lineProps.type === 2;
      } // <-- Helper functions


      return firstLine;
    }

    drawSolidLine(points, lineProps) {
      let lineMesh;

      if (points[0].distanceTo(points[1]) > 0) {
        lineMesh = utils.drawLine(points[0], points[1], lineProps, true);
      } else {
        lineMesh = new THREE.Mesh();
      }

      lineMesh.needsZoomControl = true;
      return lineMesh;
    }

    drawDashedLine(pointPair, lineProps) {
      let localPoints = pointPair.map(pt => pt.clone().sub(pointPair[0]));
      let factor = lineProps.factor;
      let q = new THREE.Quaternion().setFromUnitVectors(localPoints[1].clone().normalize(), new THREE.Vector3(0, 0, 1));
      localPoints[1].applyQuaternion(q);
      let lineSegments = this.getDashedLineSegments(localPoints[0], localPoints[1], factor);
      let lineMesh = this.drawDashedLineFromSegments(lineSegments, lineProps);
      lineMesh.position.add(pointPair[0]);
      lineMesh.lookAt(pointPair[1]);
      lineMesh.needsZoomControl = true;
      return lineMesh;
    }

    getDashedLineSegments(pointOne, pointTwo, factor) {
      let pointPairs = [];
      let newSegment = true;
      let direction = new THREE.Vector3().subVectors(pointTwo, pointOne).normalize();
      let distance = pointOne.distanceTo(pointTwo);
      let segmentSize = factor;

      if (distance > factor) {
        while (distance > segmentSize) {
          let newPoint = new THREE.Vector3().copy(pointOne).add(direction.clone().multiplyScalar(segmentSize));
          if (newSegment) pointPairs.push([pointOne, newPoint]);
          pointOne = newPoint;
          distance -= segmentSize;
          newSegment = !newSegment;
        }
      } else pointPairs.push([pointOne, pointTwo]);

      return pointPairs;
    }

    drawDashedLineFromSegments(lineSegments, lineProps) {
      let lineMeshArray = [];
      lineSegments.forEach(pair => {
        if (pair[0].distanceTo(pair[1]) > 0) {
          let lineMesh = utils.drawLine(pair[0], pair[1], lineProps);
          lineMeshArray.push(lineMesh);
        }
      });
      let firstLine = lineMeshArray.splice(0, 1)[0];
      let lineMaterial = firstLine.material;
      firstLine.updateMatrixWorld();
      firstLine.geometry.applyMatrix4(firstLine.matrixWorld);

      for (let i = 0; i < lineMeshArray.length; i++) {
        lineMeshArray[i].updateMatrixWorld();
        lineMeshArray[i].geometry.applyMatrix4(lineMeshArray[i].matrixWorld);
        firstLine.geometry.merge(lineMeshArray[i].geometry);
      }

      firstLine = new THREE.Mesh(firstLine.geometry, lineMaterial);
      return firstLine;
    } ///


    mergeVolumeWireframes(type) {
      const DELTA = 0.0001;
      let raycaster = new THREE.Raycaster();
      let positives = this.mergeSameTypeWireframe(raycaster, this.volumeWireframesData[type], "positives");
      let negatives = this.mergeSameTypeWireframe(raycaster, this.volumeWireframesData[type], "negatives");
      if (positives.mesh) positives.mesh.material.side = THREE.DoubleSide;
      if (negatives.mesh) negatives.mesh.material.side = THREE.DoubleSide;
      this.volumeWireframesData[type] = [positives, negatives];

      for (let objectData of this.volumeWireframesData[type]) {
        let id = objectData.id;
        let mesh = objectData.mesh;
        let objectType = objectData.objectType;
        this.volumeWireframesData[type].filter(obj => id !== obj.id).map(obj => obj.mesh).forEach(objMesh => {
          let newPoints = [];
          objectData.linePoints.forEach(([pointOne, pointTwo]) => {
            pointOne.applyMatrix4(mesh.matrix);
            pointTwo.applyMatrix4(mesh.matrix);
            let firstPointIntersection = this.pointInsideMesh(raycaster, pointOne, pointTwo, objMesh);
            let secondPointIntersection = this.pointInsideMesh(raycaster, pointTwo, pointOne, objMesh);
            let firstPointData = {
              point: pointOne,
              intersection: firstPointIntersection
            };
            let secondPointData = {
              point: pointTwo,
              intersection: secondPointIntersection
            };
            let operation = objectType === 1 ? "AddPoints" : "SubtractPoints";
            let correctedPointPairs = this.getCorrectedLinePoints(firstPointData, secondPointData, operation);
            correctedPointPairs.forEach(pair => {
              if (pair[0].distanceTo(pair[1]) > DELTA) {
                newPoints.push([pair[0], pair[1]]);
              }
            });
          });
          objectData.linePoints = newPoints;
        });
      }
    }

    mergeSameTypeWireframe(raycaster, volumeWireframes, type) {
      let mergedWireframes = volumeWireframes[type];

      for (let i = 0; i < mergedWireframes.length; i++) {
        let objectData = mergedWireframes[i];
        let id = objectData.id;
        let mesh = objectData.mesh;
        mergedWireframes.filter(obj => id !== obj.id).map(obj => obj.mesh).forEach(objMesh => {
          let newPoints = [];
          objectData.linePoints.forEach(([pointOne, pointTwo]) => {
            pointOne.applyMatrix4(mesh.matrix);
            pointTwo.applyMatrix4(mesh.matrix);
            let firstPointIntersection = this.pointInsideMesh(raycaster, pointOne, pointTwo, objMesh);
            let secondPointIntersection = this.pointInsideMesh(raycaster, pointTwo, pointOne, objMesh);
            let firstPointData = {
              point: pointOne,
              intersection: firstPointIntersection
            };
            let secondPointData = {
              point: pointTwo,
              intersection: secondPointIntersection
            };
            let correctedPointPairs = this.getCorrectedLinePoints(firstPointData, secondPointData, "AddPoints");
            correctedPointPairs.forEach(pair => {
              if (pair[0].distanceTo(pair[1]) > 0.0001) {
                newPoints.push([pair[0], pair[1]]);
              }
            });
          });
          objectData.linePoints = newPoints;
        });
      }

      mergedWireframes = mergedWireframes.reduce((allPos, currentPos) => {
        allPos.id = currentPos.id;
        allPos.objectType = currentPos.objectType;
        allPos.linePoints.push(...currentPos.linePoints);
        allPos.mesh = allPos.mesh ? BooleanOperations.doCSG(allPos.mesh, currentPos.mesh, "union") : currentPos.mesh;
        return allPos;
      }, {
        linePoints: []
      });
      return mergedWireframes;
    }

    getCorrectedLinePoints(firstPtIntersect, secondPtIntersect, operationType) {
      let pointOne = firstPtIntersect.point;
      let firstPointIntersection = firstPtIntersect.intersection;
      let pointTwo = secondPtIntersect.point;
      let secondPointIntersection = secondPtIntersect.intersection;
      let linePairs = [];

      if (operationType === "AddPoints") {
        if (!firstPointIntersection.isInMesh && !secondPointIntersection.isInMesh) {
          if (firstPointIntersection.intersectionPoint && secondPointIntersection.intersectionPoint) {
            let lenPointToPoint = pointOne.distanceTo(pointTwo);
            let lenPointOneToIntersect = pointOne.distanceTo(firstPointIntersection.intersectionPoint);
            let lenPointTwoToIntersect = pointTwo.distanceTo(secondPointIntersection.intersectionPoint);

            if (lenPointToPoint > lenPointOneToIntersect && lenPointToPoint > lenPointTwoToIntersect) {
              linePairs.push([pointOne, firstPointIntersection.intersectionPoint]);
              linePairs.push([pointTwo, secondPointIntersection.intersectionPoint]);
            } else {
              linePairs.push([pointOne, pointTwo]);
            }
          } else {
            linePairs.push([pointOne, pointTwo]);
          }
        } else if (!firstPointIntersection.isInMesh && secondPointIntersection.isInMesh) {
          if (firstPointIntersection.intersectionPoint) linePairs.push([pointOne, secondPointIntersection.intersectionPoint]);
        } else if (firstPointIntersection.isInMesh && !secondPointIntersection.isInMesh) {
          if (secondPointIntersection.intersectionPoint) linePairs.push([pointTwo, firstPointIntersection.intersectionPoint]);
        }
      } else if (operationType === "SubtractPoints") {
        if (firstPointIntersection.isInMesh && secondPointIntersection.isInMesh) {
          linePairs.push([pointOne, pointTwo]);
        } // else if ( !firstPointIntersection.isInMesh && secondPointIntersection.isInMesh && firstPointIntersection.intersectionPoint )
        // {
        //   linePairs.push([ pointTwo, secondPointIntersection.intersectionPoint ]);
        // }
        // else if ( firstPointIntersection.isInMesh && !secondPointIntersection.isInMesh && secondPointIntersection.intersectionPoint )
        // {
        //   linePairs.push([ pointOne, firstPointIntersection.intersectionPoint ]);
        // }

      }

      return linePairs;
    }

    pointInsideMesh(raycaster, pointOne, pointTwo, object) {
      if (!object) {
        return {
          isInMesh: false,
          intersectionPoint: null
        };
      }

      let direction = pointTwo.clone().sub(pointOne).normalize();
      raycaster.set(pointOne, direction);
      let intersects = raycaster.intersectObject(object);

      for (let i = 0; i < intersects.length; i++) {
        for (let j = 0; j < intersects.length; j++) {
          if (i != j && Math.abs(intersects[i].distance - intersects[j].distance) < 0.1) {
            intersects[i].markedToDelete = true && !intersects[j].markedToDelete;
          }
        }
      }

      intersects = intersects.filter(intersect => !intersect.markedToDelete);
      let intersectionData = {
        isInMesh: intersects.length % 2 ? true : false,
        intersectionPoint: intersects[0] ? intersects[0].point : null
      };
      return intersectionData;
    }

    getRegularWireframePointPairs(object, threshold = Math.PI / 6) {
      let edgesGeo = new _OutlinesGeometry.OutlinesGeometry(object.geometry);
      let pointPairs = [];
      this.getWireframeLinePairs(edgesGeo, threshold).forEach(([_, pairs]) => pairs.forEach(pair => pointPairs.push(pair)));
      return pointPairs;
    }

    getWireframeLinePairs(edgesGeo, threshold = Math.PI / 6) {
      const DELTA = 0.0001;
      let facesDict = this.getFacesDict(edgesGeo.faces);
      let linePairs = [];

      for (let [edgeIndices, facePair] of Object.entries(facesDict)) {
        if (facePair.length === 2) {
          let faceOne = facePair[0];
          let faceTwo = facePair[1];

          if (faceOne.normal.angleTo(faceTwo.normal) > threshold) {
            let verticesIdx = edgeIndices.split(",").map(val => parseInt(val));
            let vectorOne = edgesGeo.vertices[verticesIdx[0]];
            let vectorTwo = edgesGeo.vertices[verticesIdx[1]];
            let direction = new THREE.Vector3().subVectors(vectorTwo, vectorOne).normalize();
            let needToAdd = false;

            for (let k = 0; k < linePairs.length; k++) {
              needToAdd = false;
              let vectorData = linePairs[k];
              let dir = vectorData[0];
              let vectorsArr = vectorData[1];
              let dirDot = dir.dot(direction);

              if (dirDot > 1 - DELTA) {
                vectorsArr.push([vectorOne, vectorTwo]);
                k = linePairs.length;
              } else if (dirDot < -1 + DELTA) {
                vectorsArr.push([vectorTwo, vectorOne]);
                k = linePairs.length;
              } else needToAdd = true;
            }

            if (linePairs.length === 0) needToAdd = true;
            if (needToAdd) linePairs.push([direction, [[vectorOne, vectorTwo]]]);
          }
        } else {
          let verticesIdx = edgeIndices.split(",").map(val => parseInt(val));
          let vectorOne = edgesGeo.vertices[verticesIdx[0]];
          let vectorTwo = edgesGeo.vertices[verticesIdx[1]];
          let direction = new THREE.Vector3().subVectors(vectorTwo, vectorOne).normalize();
          let needToAdd = false;

          for (let k = 0; k < linePairs.length; k++) {
            needToAdd = false;
            let vectorData = linePairs[k];
            let dir = vectorData[0];
            let vectorsArr = vectorData[1];
            let dirDot = dir.dot(direction);

            if (dirDot > 1 - DELTA) {
              vectorsArr.push([vectorOne, vectorTwo]);
              k = linePairs.length;
            } else if (dirDot < -1 + DELTA) {
              vectorsArr.push([vectorTwo, vectorOne]);
              k = linePairs.length;
            } else needToAdd = true;
          }

          if (linePairs.length === 0) needToAdd = true;
          if (needToAdd) linePairs.push([direction, [[vectorOne, vectorTwo]]]);
        }
      }

      this.mergeLinePairsSameDirection(linePairs);
      return linePairs;
    }

    getFacesDict(faces) {
      let facesDict = {};

      for (let face of faces) {
        if (face.splitted) continue;
        let edgeOne = `${face.a},${face.b}`;
        let edgeTwo = `${face.b},${face.c}`;
        let edgeThree = `${face.c},${face.a}`;
        let edgeOneReversed = `${face.b},${face.a}`;
        let edgeTwoReversed = `${face.c},${face.b}`;
        let edgeThreeReversed = `${face.a},${face.c}`;
        if (edgeOneReversed in facesDict) facesDict[edgeOneReversed].push(face);else if (edgeOne in facesDict) facesDict[edgeOne].push(face);else {
          facesDict[edgeOne] = [];
          facesDict[edgeOne].push(face);
        }
        if (edgeTwoReversed in facesDict) facesDict[edgeTwoReversed].push(face);else if (edgeTwo in facesDict) facesDict[edgeTwo].push(face);else {
          facesDict[edgeTwo] = [];
          facesDict[edgeTwo].push(face);
        }
        if (edgeThreeReversed in facesDict) facesDict[edgeThreeReversed].push(face);else if (edgeThree in facesDict) facesDict[edgeThree].push(face);else {
          facesDict[edgeThree] = [];
          facesDict[edgeThree].push(face);
        }
      }

      return facesDict;
    }

    mergeLinePairsSameDirection(linePairs) {
      linePairs.forEach(arrData => this.makeLinePairsUnique(arrData));
      linePairs.forEach(([direction, pairs]) => utils.mergeLinePoints(pairs, direction));
    }

    makeLinePairsUnique(arrData) {
      // [ 0: Direction, 1: Pairs array ]
      let vectorPairsArr = arrData[1];
      let uniquePairs = [];
      vectorPairsArr.forEach(pair => {
        let isUnique = true;
        uniquePairs.forEach(uniquePair => {
          if (pair[0].equals(uniquePair[0]) && pair[1].equals(uniquePair[1])) isUnique = false;
        });
        if (isUnique) uniquePairs.push(pair);
      });
      arrData[1] = uniquePairs;
    }

    getVolumeWireframeData(mesh, linePairsForOpaque, linePairsForTransparent, objectType) {
      let intersectionGeo = mesh.geometry.clone(); // For checking if line intersects faces

      intersectionGeo.applyMatrix4(mesh.matrix);
      let intersectionMesh = new THREE.Mesh(intersectionGeo, new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide
      }));

      if (objectType === 1) {
        this.volumeWireframesData.opaque.positives.push({
          id: intersectionMesh.uuid,
          linePoints: linePairsForOpaque,
          mesh: intersectionMesh,
          objectType
        });
        this.volumeWireframesData.transparent.positives.push({
          id: intersectionMesh.uuid,
          linePoints: linePairsForTransparent,
          mesh: intersectionMesh,
          objectType
        });
      } else if (objectType === 2) {
        this.volumeWireframesData.opaque.negatives.push({
          id: intersectionMesh.uuid,
          linePoints: linePairsForOpaque,
          mesh: intersectionMesh,
          objectType
        });
        this.volumeWireframesData.transparent.negatives.push({
          id: intersectionMesh.uuid,
          linePoints: linePairsForTransparent,
          mesh: intersectionMesh,
          objectType
        });
      }
    }

    clearWireframeState() {
      this.areaWireframesData = [];
      this.volumeWireframesData = {
        opaque: {
          positives: [],
          negatives: []
        },
        transparent: {
          positives: [],
          negatives: []
        }
      };
    }

  }

  _exports.WireframeGenerator = WireframeGenerator;
});